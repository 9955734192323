define("simtex-myaccount/initializers/simtex", ["exports", "@ember/debug", "simtex-myaccount/config/environment"], function (_exports, _debug, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // import Ember from 'ember';

  function initialize(application) {
    application.register('config:main', _environment.default.APP, {
      instantiate: false
    });
    application.inject('route', 'simtex', 'service:simtex');
    application.inject('route', 'appconfig', 'config:main');
    application.inject('controller', 'simtex', 'service:simtex');
    application.inject('controller', 'toast', 'service:toast');
    application.inject('controller', 'helpers', 'service:helpers');
    application.inject('controller', 'appconfig', 'config:main');
    application.inject('component', 'simtex', 'service:simtex');
    application.inject('component', 'toast', 'service:toast');
    application.inject('component', 'schema', 'service:schema');
    application.inject('component', 'helpers', 'service:helpers');
    application.inject('component', 'appconfig', 'config:main');
    (0, _debug.registerDeprecationHandler)(function (message, options, next) {
      // TODO work through deprecations by changing the below to > 3.8.0
      if (options && options.until && options.until !== '3.8.0') {
        return;
      } else {
        next(message, options);
      }
    });
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});