define("simtex-myaccount/components/password-toggle", ["exports", "jquery", "@ember/object", "@ember/utils", "@ember/debug", "@ember/component"], function (_exports, _jquery, _object, _utils, _debug, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extended from: https://github.com/nchristus/ember-cli-password-toggle
  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this.$('button').on('click', function () {
        var text = (0, _jquery.default)(this).text();
        var $input = (0, _jquery.default)(this).parent().find('input');
        if (text === 'Show') {
          (0, _jquery.default)(this).text('Hide');
          $input.attr('autocomplete', 'off');
          $input.attr('autocorrect', 'off');
          $input.attr('spellcheck', 'off');
          $input.attr('autocapitalize', 'off');
          $input.attr('type', 'text');
        } else if (text === 'Hide') {
          (0, _jquery.default)(this).text('Show');
          $input.removeAttr('autocomplete');
          $input.removeAttr('autocorrect');
          $input.removeAttr('spellcheck');
          $input.removeAttr('autocapitalize');
          $input.attr('type', 'password');
        }
      });
      if ((0, _object.get)(this, 'focus')) {
        this.$('input').focus();
      }
    },
    keyPress: function keyPress(event) {
      if (event.keyCode === 13) {
        var actionToBubble = this.get('action');
        if (typeof actionToBubble === 'string') {
          /* eslint-disable */
          (true && (0, _debug.warn)('The ember-cli-password-toggle `action` should be a closure action', false, {
            id: 'ember-cli-password-toggle.action-type'
          }));
          this.sendAction('action');
          /* eslint-enable */
        } else if (!(0, _utils.isEmpty)(actionToBubble)) {
          actionToBubble();
        }
      }
    },
    wrapperClazz: (0, _object.computed)('wrapperClass', function () {
      return 'ember-password-toggle-wrapper ' + (0, _object.get)(this, 'wrapperClass');
    }),
    buttonClazz: (0, _object.computed)('buttonClass', function () {
      return 'ember-password-toggle-btn ' + (0, _object.get)(this, 'buttonClass');
    }),
    inputClazz: (0, _object.computed)('inputClass', function () {
      return 'ember-password-toggle-input ' + (0, _object.get)(this, 'inputClass');
    })
  });
  _exports.default = _default;
});