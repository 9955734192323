define("simtex-myaccount/routes/restricted/system/zerotouchjob", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    beforeModel: function beforeModel() {
      this.simtex.set_user_nav(true);
    },
    model: function model(params) {
      this.simtex.set_user_nav_fields(params.owner);
      return this._super(params);
    },
    afterModel: function afterModel(model, transition) {
      var allowed_owner = [this.simtex.get('claims_account')];
      var requested_owner = model.owner;

      // TODO Make this more generic
      if (!(this.simtex.is_admin || allowed_owner.indexOf(requested_owner) >= 0)) {
        this.toast.error(this.simtex.getSimtexString('LIST_UNAUTHORIZED_VIEW_HANDSETS'));
        this.transitionTo('restricted.index');
      }
    }
  });
  _exports.default = _default;
});