define("simtex-myaccount/routes/restricted/subscriptions/subscription/handset", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    toast: (0, _service.inject)(),
    router: (0, _service.inject)(),
    queryParams: {
      refresh: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this.simtex.set_user_nav(true);
    },
    model: function model(params, transition) {
      var _this = this;
      var endpoint_id = parseInt(params.endpoint_id || 0) - 2100000;
      var subscription = this.modelFor('restricted.subscriptions.subscription');
      var arr_endpointaccount = subscription.endpointaccount_data.toArray();
      var ea_data = arr_endpointaccount.find(function (ea) {
        return parseInt(ea.get('endpoint.id')) === endpoint_id;
      });

      // Ensures that we reload endpoint (and endpoint_account) data after navigating from handset-table,
      // as at that point we have requested less data from API via remove_fields, and that missing data
      // won't render unless refresh.
      if (!ea_data) {
        this.toast.error('Unable to locate requested handset on this subscription');
        this.router.transitionTo('restricted.subscriptions.subscription', subscription.owner, subscription.accountid);
      }
      return this.store.findRecord('endpoint', endpoint_id, {
        reload: true
      }).then(function (endpoint) {
        return _this.store.findRecord('endpointaccount', ea_data.endpoint_accountid, {
          reload: true
        });
      });
      // return ea_data;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var current_id = this.context.endpoint.get('id');
      var last_id = this._router.currentURL ? this._router.currentURL.split('/').lastObject : current_id;
      if (last_id === current_id) {
        var hash_location = window.location.hash;
        var tabIndex = hash_location.length > 1 ? parseInt(hash_location.substring(1)) : 0;
        this.controllerFor('restricted.subscriptions.subscription.handset').set('tabIndex', tabIndex);
      } else {
        this.controllerFor('restricted.subscriptions.subscription.handset').set('tabIndex', 0);
      }
    },
    refreshModel: (0, _object.observer)('simtex.refresh_model', function () {
      this.refresh();
    })
  });
  _exports.default = _default;
});