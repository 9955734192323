define("simtex-myaccount/components/label-tips", ["exports", "@ember/component", "@ember/object", "@ember/template"], function (_exports, _component, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    componentid: null,
    label_tips_array: [{
      componentid: 'service_status',
      component_label: 'Service Status',
      tip_text: 'The current Status of the subscription you are viewing.'
    }, {
      componentid: 'service_password',
      component_label: 'SIP Password',
      tip_text: 'Password used to connect to the SIP server.'
    }, {
      componentid: 'service_label',
      component_label: 'Label / Caller ID Name',
      tip_text: 'Name to be displayed for internal calls / CallerID.'
    }, {
      componentid: 'service_internalextension',
      component_label: 'Extension Number',
      tip_text: 'Short internal extension number – must be 200-499 or 2000-4999'
    }, {
      componentid: 'dialrestriction_iddallow',
      component_label: 'IDD Allowed',
      tip_text: 'Unblocks international calls - IDD Approved must be enabled (see below)'
    }, {
      componentid: 'dialrestriction_iddbanned',
      component_label: 'IDD Banned',
      tip_text: 'Use this to ban international calls for this service even if Allowed and Approved have been enabled.'
    }, {
      componentid: 'dialrestriction_iddclientapproved',
      component_label: 'IDD Approved',
      tip_text: 'Simtex must enable this prior to unblocking international calls.'
    }, {
      componentid: 'dialrestriction_iddtier',
      component_label: 'IDD Tier',
      tip_text: 'https://www.simtex.com.au/kb/international-calling-idd-tiers/'
    }, {
      componentid: 'dialrestriction_iddclientemailsent',
      component_label: 'IDD Client Email Sent',
      tip_text: 'Displays whether the IDD request email has already been sent to the customer.'
    }, {
      componentid: 'dialrestriction_prefixblockingmode',
      component_label: 'Enforce Whitelisting',
      tip_text: 'Enables service to only call specific prefixes in the whitelist below. It can be used in conjunction with blacklist prefixes, ie whitelist of 618 and blacklist of 61. This will allow 618 numbers to be called but block all others.'
    }, {
      componentid: 'dialrestriction_prefixwhitelist_text',
      component_label: 'Whitelist Prefixes',
      tip_text: 'Prefixes that the service is allowed to call, for example 6189, 614'
    }, {
      componentid: 'dialrestriction_prefixblacklist_text',
      component_label: 'Blacklist Prefixes',
      tip_text: 'Prefixes that the service is not allowed to call.'
    }, {
      componentid: 'numberpresentation_phoneid',
      component_label: 'Default Caller ID',
      tip_text: 'The CallerID used by this service for outgoing calls. Select from other commonly used numbers or search for a specific number on your account.'
    }, {
      componentid: 'numberpresentation_originatorclid',
      component_label: 'Originator Caller ID',
      tip_text: 'Changes the CallerID for forwarded calls. If enabled, the number of the originating caller will be presented to the forwarding recipient. By default it will use the default CallerID of the account.'
    }, {
      componentid: 'numberpresentation_blockclid',
      component_label: 'Block Caller ID (Silent)',
      tip_text: 'This will set the CallerID for the service to "PRIVATE" or anonymous'
    }, {
      componentid: 'numberpresentation_calleridpassthrough',
      component_label: 'Caller ID Passthrough',
      tip_text: 'Very special Admin Function to allow service to send any CallerID. Check with escalations before applying.'
    }, {
      componentid: 'numberpresentation_calleridoverride',
      component_label: 'Caller ID Override',
      tip_text: 'Very special Admin Function to allow the setting of CallerID with a number not on the account. Check with escalations before applying.'
    }, {
      componentid: 'numberpresentation_callerid',
      component_label: 'Caller ID',
      tip_text: 'CallerID to be used for the above CallerID Override function.'
    }, {
      componentid: 'voicemail_active',
      component_label: 'Voicemail enabled',
      tip_text: 'Turns voicemail for a subscription on or off.'
    }, {
      componentid: 'voicemail_pin',
      component_label: 'Voicemail PIN',
      tip_text: 'Numeric PIN used when setting up or collecting voicemail messages.'
    }, {
      componentid: 'voicemail_keepserver',
      component_label: 'Save on server',
      tip_text: 'If enabled, voicemails will be saved to the voicemail server to allow playback of voicemails via your handset or phone.'
    }, {
      componentid: 'voicemail_sendemail',
      component_label: 'Send E-mail',
      tip_text: 'If enabled, voicemails will be e-mailed to the e-mail address below.'
    }, {
      componentid: 'voicemail_email',
      component_label: 'Send voicemails to',
      tip_text: 'E-mail address used for sending voicemail e-mail address. Please note only 1 e-mail address can be entered.'
    }, {
      componentid: 'channellimit_inboundchannels',
      component_label: 'Inbound channels',
      tip_text: 'Number of inbound channels allocated to the subscription.'
    }, {
      componentid: 'channellimit_outboundchannels',
      component_label: 'Outbound channels',
      tip_text: 'Number of outbound channels allocated to the subscription.  Unlimited outbound accounts have a ceiling limit in place to help prevent VoIP fraud - contact us to increase this limit for free.'
    }, {
      componentid: 'routing_didroute',
      component_label: 'Routing',
      tip_text: 'Changes where inbound calls are routed, ie fax, Teams.'
    }, {
      componentid: 'transport_ivr',
      component_label: 'IVR Script / AA',
      tip_text: 'Sends calls on this account to a custom IVR or AA script. Only apply if an IVR has been set up for the subscription.'
    }, {
      componentid: 'transport_senddid',
      component_label: 'Route DID@trunk',
      tip_text: 'Changes how calls are presented to your device. If enabled, your DID will be sent in the SIP INVITE rather than the account number.'
    }, {
      componentid: 'transport_directmedia',
      component_label: 'Media Bypass',
      tip_text: 'Media bypass or direct Media, try to setup media directly between RTP proxy and external device.'
    }, {
      componentid: 'transport_natenable',
      component_label: 'NAT Enable',
      tip_text: 'Enable/Disable NAT support for this account.'
    }, {
      componentid: 'transport_iceenable',
      component_label: 'ICE Enable',
      tip_text: 'Enable/Disable ICE support for this account.'
    }, {
      componentid: 'transport_qosenable',
      component_label: 'QOS Enable',
      tip_text: 'Enable/Disable QOS for this account.'
    }, {
      componentid: 'transport_qualifybypass',
      component_label: 'Disable OPTIONS',
      tip_text: 'Stop sending OPTIONS packets to this service, we will blindly send calls regardless of registration.'
    }, {
      componentid: 'transport_sendrpid',
      component_label: 'Send RPID',
      tip_text: 'Disables sending RPID headers, turn off for 3CX Trunks.'
    }, {
      componentid: 'transport_maxcontacts',
      component_label: 'Max Contacts',
      tip_text: 'Number of PJSIP contacts that can be registered against this account.'
    }, {
      componentid: 'transport_staticip',
      component_label: 'Static IP',
      tip_text: 'Bypasses SIP registration, send calls to a static IP – use with caution.'
    }, {
      componentid: 'transport_staticport',
      component_label: 'Static Port',
      tip_text: 'Bypasses SIP registration, send calls to a static IP – use with caution.'
    }, {
      componentid: 'handset_summary_friendly_name',
      component_label: 'Friendly Name',
      tip_text: 'Label field to help keep track of your devices, not used for calls.'
    }, {
      componentid: 'handset_summary_password',
      component_label: 'Handset UI Password',
      tip_text: 'Password used to connect to device web interface, username admin.'
    }, {
      componentid: 'handset_summary_zone',
      component_label: 'Handset Zone',
      tip_text: 'Changes dialplan for local calling, as well as clock timezone/DST'
    }, {
      componentid: 'callforwarding_ringtime',
      component_label: (0, _template.htmlSafe)('<clr-icon shape="clock"></clr-icon> Ring / Delay time'),
      tip_text: 'Time in seconds to call your device/trunk before busy forward, voicemail or timeout.'
    }, {
      componentid: 'handset_summary_provisioning_status',
      component_label: 'Provisioning Status',
      tip_text: 'Status of web requests to our provisioning system.'
    }, {
      componentid: 'handset_summary_last_access',
      component_label: 'Last Access Time',
      tip_text: 'The last time device accessed our provisioning server since an update was made.'
    }, {
      componentid: 'handset_summary_zerotouch_state',
      component_label: 'Zero Touch State',
      tip_text: 'Shows if the device is added, missing or bound to another providers Zero Touch.'
    }, {
      componentid: 'handset_summary_zerotouch_lastupdate',
      component_label: 'Zero Touch Last Update',
      tip_text: 'Time we last made a change or request to the Yealink or Polycom Zero Touch platform for this device.'
    }, {
      componentid: 'handset_summary_zerotouch_lastaccess',
      component_label: 'Zero Touch Last Access',
      tip_text: 'Time the phone last made contact with the Yealink or Polycom Zero Touch platform.  Note this normally occurs once after a factory reset.'
    }, {
      componentid: 'translation_phoneid',
      component_label: 'Target DID',
      tip_text: 'Target DID'
    }],
    component_label: (0, _object.computed)(function () {
      var _this = this;
      var label_tip = this.get('label_tips_array').find(function (item) {
        return item.componentid == _this.get('componentid');
      });
      if (label_tip) {
        return label_tip.component_label;
      } else {
        return 'Missing Label';
      }
    }),
    tip_text: (0, _object.computed)(function () {
      var _this2 = this;
      var label_tip = this.get('label_tips_array').find(function (item) {
        return item.componentid == _this2.get('componentid');
      });
      if (label_tip) {
        return label_tip.tip_text;
      } else {
        return 'No help tips available';
      }
    })
  });
  _exports.default = _default;
});