define("simtex-myaccount/templates/components/light-table-pagination", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if meta}}
  <div class="datagrid-foot">
      <div class="datagrid-foot-description"></div>
      <div class="pagination-description">Page {{this.page}} of {{this.meta.totalPages}}</div>
      {{#if this.pagination_is_field}}
          <span class="pagination-list">
              <label>Page:</label>
              <Input class="clr-input"
                     @value={{this.page}}
              />
              <button class="btn btn-sm" {{action this.pageActions.refresh}}>Go</button>
          </span>
      {{else}}
          <ul class="pagination-list">
          {{#each (range 1 this.meta.totalPages true) as |p|}}
              <li class={{if (eq p this.page) 'pagination-current'}}>
                  <a {{action this.pageActions.setPage p}} href="#">{{p}}</a>
              </li>
          {{/each}}
          </ul>
      {{/if}}
  </div>
  {{/if}}
  */
  {
    "id": "6zHEEQDR",
    "block": "[[[41,[33,1],[[[10,0],[14,0,\"datagrid-foot\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"datagrid-foot-description\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"pagination-description\"],[12],[1,\"Page \"],[1,[30,0,[\"page\"]]],[1,\" of \"],[1,[30,0,[\"meta\",\"totalPages\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"pagination_is_field\"]],[[[1,\"        \"],[10,1],[14,0,\"pagination-list\"],[12],[1,\"\\n            \"],[10,\"label\"],[12],[1,\"Page:\"],[13],[1,\"\\n            \"],[8,[39,2],[[24,0,\"clr-input\"]],[[\"@value\"],[[30,0,[\"page\"]]]],null],[1,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-sm\"],[4,[38,3],[[30,0],[30,0,[\"pageActions\",\"refresh\"]]],null],[12],[1,\"Go\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"ul\"],[14,0,\"pagination-list\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[28,[37,6],[1,[30,0,[\"meta\",\"totalPages\"]],true],null]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,0,[52,[28,[37,7],[[30,1],[30,0,[\"page\"]]],null],\"pagination-current\"]],[12],[1,\"\\n                \"],[11,3],[24,6,\"#\"],[4,[38,3],[[30,0],[30,0,[\"pageActions\",\"setPage\"]],[30,1]],null],[12],[1,[30,1]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[]],null]],[\"p\"],false,[\"if\",\"meta\",\"input\",\"action\",\"each\",\"-track-array\",\"range\",\"eq\"]]",
    "moduleName": "simtex-myaccount/templates/components/light-table-pagination.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});