define("simtex-myaccount/models/endpointaccount", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    endpoint_accountid: _emberData.default.attr(),
    endpoint: _emberData.default.belongsTo('endpoint', {
      async: true
    }),
    index: _emberData.default.attr(),
    baselinekey_data: (0, _attributes.fragmentArray)('endpointlinekey', {
      async: false
    }),
    account: _emberData.default.belongsTo('account', {
      async: true
    }),
    // account: DS.attr(),

    // label: removed!
    defaultcodec: _emberData.default.attr('string'),
    account_label: _emberData.default.attr('string'),
    account_internalextension: _emberData.default.attr('string'),
    account_product: _emberData.default.attr('string')
  });
  _exports.default = _default;
});