define("simtex-myaccount/templates/restricted/subscriptions/subscription/callhistory", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title 'Call History ( Subscription ' this.model.accountid ')'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top: 0;">Call History for {{this.model.accountid}}</h2>
              {{callhistory/callhistory-table
                      ownerFilterTerm=this.simtex.owner_search_term
                      account=this.model.accountid
                      cursor=null
                      caller='subscription' }}
          </div>
      </div>
  </div>
  */
  {
    "id": "mn+sd7uC",
    "block": "[[[1,[28,[35,0],[\"Call History ( Subscription \",[30,0,[\"model\",\"accountid\"]],\")\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top: 0;\"],[12],[1,\"Call History for \"],[1,[30,0,[\"model\",\"accountid\"]]],[13],[1,\"\\n            \"],[1,[28,[35,1],null,[[\"ownerFilterTerm\",\"account\",\"cursor\",\"caller\"],[[30,0,[\"simtex\",\"owner_search_term\"]],[30,0,[\"model\",\"accountid\"]],null,\"subscription\"]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"callhistory/callhistory-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/subscriptions/subscription/callhistory.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});