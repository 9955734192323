define("simtex-myaccount/routes/restricted/subscriptions/subscription/callhistory", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    simtex: (0, _service.inject)('simtex'),
    beforeModel: function beforeModel() {
      this.simtex.set_user_nav(true);
    }
  });
  _exports.default = _default;
});