define("simtex-myaccount/router", ["exports", "@ember/routing/router", "simtex-myaccount/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = _router.default.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('passwordrequest');
    this.route('passwordreset');
    this.route('authproceed');
    this.route('admin');
    this.route('restricted', {
      path: '/'
    }, function () {
      this.route('dashboard');
      this.route('accounts', function () {
        this.route('list', {
          path: '/'
        });
        this.route('edit', {
          path: '/:id/edit'
        });
      });
      this.route('ivr', {
        path: '/ivr'
      });
      this.route('domain-verification', {
        path: '/domain-verification'
      });
      this.route('subscriptions', function () {
        this.route('list', {
          path: '/:owner'
        });
        this.route('subscription', {
          path: '/:owner/:account_id'
        }, function () {
          this.route('handset', {
            path: '/handset/:endpoint_id'
          });
          this.route('callforwarding', {
            path: '/callforwarding'
          });
          this.route('ringgroups', {
            path: '/ringgroups'
          });
          this.route('dids', {
            path: '/dids'
          });
          this.route('callhistory', {
            path: '/callhistory'
          });
        });
      });
      this.route('numbers', function () {
        // this.route('entry', {path: '/:owner'});
        this.route('dids', {
          path: '/:owner/dids'
        }, function () {
          this.route('did', {
            path: '/:did'
          });
        });
        this.route('ivr', {
          path: '/:owner/ivr'
        }, function () {
          this.route('ivr', {
            path: '/:ivr'
          });
        });
        this.route('ivr', {
          path: '/:owner/domain-verification'
        }, function () {
          this.route('ivr', {
            path: '/:domain-verification'
          });
        });
        this.route('didclidstamping', {
          path: '/:owner/didclidstamping'
        });
        this.route('did', {
          path: '/:did/did'
        });
        this.route('sites', {
          path: '/:owner/sites'
        }, function () {
          this.route('site', {
            path: '/:site'
          });
        });
      });
      this.route('system', function () {
        this.route('zerotouchjob', {
          path: '/zerotouchjob'
        });
      });
      this.route('handsets', function () {
        this.route('list', {
          path: '/:owner'
        });
        this.route('mapping', {
          path: '/:owner/mapping'
        });
        this.route('zerotouchjob', {
          path: '/:owner/zerotouchjob'
        });
        // this.route('handset', {path: '/:owner/handset/:id'});
      });

      this.route('callrouting', function () {
        this.route('callforwarding', {
          path: '/:owner/callforwarding'
        });
        this.route('ringgroups', {
          path: '/:owner/ringgroups'
        });
      });
      this.route('callhistory', function () {
        this.route('index', {
          path: '/:owner'
        });
      });
    });
    this.route('logout');
  });
  var _default = Router;
  _exports.default = _default;
});