define("simtex-myaccount/templates/restricted/callhistory/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  {{page-title 'Call History (' this.simtex.owner_description ')'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top: 0;">Call History for {{this.simtex.owner_description}}</h2>
              {{callhistory/callhistory-table
                      ownerFilterTerm=this.simtex.owner_search_term
                      cursor=null
                      caller='account' }}
          </div>
      </div>
  </div>
  */
  {
    "id": "QmiT19ul",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[1,[28,[35,2],[\"Call History (\",[30,0,[\"simtex\",\"owner_description\"]],\")\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top: 0;\"],[12],[1,\"Call History for \"],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n            \"],[1,[28,[35,3],null,[[\"ownerFilterTerm\",\"cursor\",\"caller\"],[[30,0,[\"simtex\",\"owner_search_term\"]],null,\"account\"]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"page-title\",\"callhistory/callhistory-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/callhistory/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});