define("simtex-myaccount/components/subscriptions/dialrestriction-data", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object", "ember-data", "@ember/service"], function (_exports, _component, _modelSaverCommon, _object, _emberData, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Dial Restrictions',
    store: (0, _service.inject)(),
    get_prefixblacklist: null,
    get_prefixwhitelist: null,
    dialrestrictiontier_list: [],
    dialrestrictiontier_name: null,
    prefixblacklist_text: '',
    prefixwhitelist_text: '',
    disable_prefixwhitelist_field: (0, _object.computed)('model.prefixblockingmode', function () {
      return !this.model.get('prefixblockingmode');
    }),
    init: function init() {
      this._super();
      this.init_blacklist();
      this.init_whitelist();
      if (this.simtex.is_admin) {
        this.get_iddtier_list();
      }
    },
    get_iddtier_list: function get_iddtier_list() {
      var _this = this;
      var dialrestrictiontier_list = [];
      return _emberData.default.PromiseObject.create({
        promise: this.store.findAll("dialrestrictiontier").then(function (result) {
          result.forEach(function (tier) {
            dialrestrictiontier_list.push({
              display_name: tier.label,
              value: tier.dialrestrictiontierid
            });
          });
          _this.set("dialrestrictiontier_list", dialrestrictiontier_list);
        })
      });
    },
    init_blacklist: function init_blacklist() {
      this.get_prefixblacklist = Ember.Object.create({
        "prefixes": Ember.A([])
      });
      if (this.model.get('prefixblacklist')) {
        var blacklist = this.model.get('prefixblacklist').split('|');
        for (var i = 0, len = blacklist.length; i < len; i++) {
          this.get_prefixblacklist.prefixes.pushObject(blacklist[i]);
        }
      }
    },
    init_whitelist: function init_whitelist() {
      this.get_prefixwhitelist = Ember.Object.create({
        "prefixes": Ember.A([])
      });
      if (this.model.get('prefixwhitelist')) {
        var whitelist = this.model.get('prefixwhitelist').split('|');
        for (var i = 0, len = whitelist.length; i < len; i++) {
          this.get_prefixwhitelist.prefixes.pushObject(whitelist[i]);
        }
      }
    },
    actions: {
      addBlacklistPrefixLabel: function addBlacklistPrefixLabel(value) {
        if (value !== '' && value !== undefined) {
          if (!this.model.get('prefixblacklist')) this.model.set('prefixblacklist', '');
          var list = this.model.get('prefixblacklist').split('|');
          if (list.indexOf(value) === -1) {
            this.get_prefixblacklist.prefixes.pushObject(value);
            this.set('prefixblacklist_text', '');
          }
          this.model.set('prefixblacklist', this.get_prefixblacklist.prefixes.join('|'));
        }
      },
      removeBlacklistPrefixLabel: function removeBlacklistPrefixLabel(value) {
        this.get_prefixblacklist.prefixes.removeObject(value);
        this.model.set('prefixblacklist', this.get_prefixblacklist.prefixes.join('|'));
      },
      addWhitelistPrefixLabel: function addWhitelistPrefixLabel(value) {
        if (value !== '' && value !== undefined) {
          if (!this.model.get('prefixwhitelist')) this.model.set('prefixwhitelist', '');
          var list = this.model.get('prefixwhitelist').split('|');
          if (list.indexOf(value) === -1) {
            this.get_prefixwhitelist.prefixes.pushObject(value);
            this.set('prefixwhitelist_text', '');
          }
          this.model.set('prefixwhitelist', this.get_prefixwhitelist.prefixes.join('|'));
        }
      },
      removeWhitelistPrefixLabel: function removeWhitelistPrefixLabel(value) {
        this.get_prefixwhitelist.prefixes.removeObject(value);
        this.model.set('prefixwhitelist', this.get_prefixwhitelist.prefixes.join('|'));
      }
    }
  });
  _exports.default = _default;
});