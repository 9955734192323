define("simtex-myaccount/templates/components/callrouting/ringgroup-expanded-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="clr-row">
      <div class="clr-col-1">
          Members:
      </div>
  </div>
  {{#each this.row.broadcastmember_data as |member memberid|}}
      <div class="clr-row">
          <div class="clr-col-1">
          </div>
          <div class="clr-col-1" style="text-align: left;">
              {{member.account.accountid}}
          </div>
          <div class="clr-col-6" style="text-align: left;">
              {{member.account.label}}
          </div>
      </div>
  {{/each}}
  
  */
  {
    "id": "+hQNHPhT",
    "block": "[[[10,0],[14,0,\"clr-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"clr-col-1\"],[12],[1,\"\\n        Members:\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"row\",\"broadcastmember_data\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"clr-row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"clr-col-1\"],[12],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"clr-col-1\"],[14,5,\"text-align: left;\"],[12],[1,\"\\n            \"],[1,[30,1,[\"account\",\"accountid\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"clr-col-6\"],[14,5,\"text-align: left;\"],[12],[1,\"\\n            \"],[1,[30,1,[\"account\",\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"member\",\"memberid\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "simtex-myaccount/templates/components/callrouting/ringgroup-expanded-row.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});