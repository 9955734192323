define("simtex-myaccount/models/didhistory", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    didhistoryid: _emberData.default.attr(),
    phoneid: _emberData.default.attr(),
    didstate: _emberData.default.attr(),
    carrier: _emberData.default.attr(),
    historynotes: _emberData.default.attr(),
    jsondata: _emberData.default.attr(),
    created: _emberData.default.attr()
  });
  _exports.default = _default;
});