define("simtex-myaccount/templates/restricted/subscriptions/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title 'Subscriptions' }}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top:0">Subscription List for {{get-simtex-string 'LIST_ACCT_CONTEXT'}}</h2>
              <div>
                  <small>{{get-simtex-string 'LIST_DESCRIPTION_SUBSCRIPTION'}}</small>
              </div>
              {{subscriptions/subscription-table}}
          </div>
      </div>
  </div>
  */
  {
    "id": "uQAUoSaL",
    "block": "[[[1,[28,[35,0],[\"Subscriptions\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top:0\"],[12],[1,\"Subscription List for \"],[1,[28,[35,1],[\"LIST_ACCT_CONTEXT\"],null]],[13],[1,\"\\n            \"],[10,0],[12],[1,\"\\n                \"],[10,\"small\"],[12],[1,[28,[35,1],[\"LIST_DESCRIPTION_SUBSCRIPTION\"],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[1,[34,2]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"get-simtex-string\",\"subscriptions/subscription-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/subscriptions/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});