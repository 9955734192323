define("simtex-myaccount/templates/components/numbers/dids/didhistory-expanded-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.expanded_row_contents}}
  */
  {
    "id": "5+/Bd4mP",
    "block": "[[[1,[30,0,[\"expanded_row_contents\"]]]],[],false,[]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/dids/didhistory-expanded-row.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});