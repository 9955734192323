define("simtex-myaccount/templates/restricted/dashboard", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h2>Welcome back,</h2>
  <div>
      {{outlet}}
  </div>
  
  */
  {
    "id": "01RxvJPX",
    "block": "[[[10,\"h2\"],[12],[1,\"Welcome back,\"],[13],[1,\"\\n\"],[10,0],[12],[1,\"\\n    \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/dashboard.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});