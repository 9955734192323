define("simtex-myaccount/templates/components/system/zerotouchjob-table-action", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (is-equal this.row.originalzerotouchstate_label "Pending Add")}}
      {{subscriptions/subscription/handset-zerotouch-dialog endpoint=this.row.endpoint modalMode='Add' viewMode='job-list'}}
  {{else if (is-equal this.row.originalzerotouchstate_label "Pending Lookup")}}
      {{subscriptions/subscription/handset-zerotouch-dialog endpoint=this.row.endpoint modalMode='Refresh' viewMode='job-list'}}
  {{else if (is-equal this.row.originalzerotouchstate_label "Pending Remove")}}
      {{subscriptions/subscription/handset-zerotouch-dialog endpoint=this.row.endpoint modalMode='Remove' viewMode='job-list'}}
  {{/if}}
  */
  {
    "id": "JYOzszvM",
    "block": "[[[41,[28,[37,1],[[30,0,[\"row\",\"originalzerotouchstate_label\"]],\"Pending Add\"],null],[[[1,\"    \"],[1,[28,[35,2],null,[[\"endpoint\",\"modalMode\",\"viewMode\"],[[30,0,[\"row\",\"endpoint\"]],\"Add\",\"job-list\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"row\",\"originalzerotouchstate_label\"]],\"Pending Lookup\"],null],[[[1,\"    \"],[1,[28,[35,2],null,[[\"endpoint\",\"modalMode\",\"viewMode\"],[[30,0,[\"row\",\"endpoint\"]],\"Refresh\",\"job-list\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"row\",\"originalzerotouchstate_label\"]],\"Pending Remove\"],null],[[[1,\"    \"],[1,[28,[35,2],null,[[\"endpoint\",\"modalMode\",\"viewMode\"],[[30,0,[\"row\",\"endpoint\"]],\"Remove\",\"job-list\"]]]],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[],false,[\"if\",\"is-equal\",\"subscriptions/subscription/handset-zerotouch-dialog\"]]",
    "moduleName": "simtex-myaccount/templates/components/system/zerotouchjob-table-action.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});