define("simtex-myaccount/routes/restricted/subscriptions/subscription/callforwarding", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    refreshModel: (0, _object.observer)('simtex.refresh_model', function () {
      if (this.get('controller.model')) this.get('controller.model').reload();
    })
  });
  _exports.default = _default;
});