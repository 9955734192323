define("simtex-myaccount/mixins/clr-wizard-common", ["exports", "@ember/object/mixin", "@ember/array", "@ember/object", "@ember/service", "@ember/template"], function (_exports, _mixin, _array, _object, _service, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  var _default = _mixin.default.create({
    store: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    schema: (0, _service.inject)(),
    // backend model info
    model: null,
    modelName: null,
    modelId: null,
    includedModels: null,
    // wizard appearance + behaviour
    wizardAppearance: {
      wizardClass: 'modal-dialog modal-lg clr-wizard simtex-wizard'
    },
    wizardBehaviour: {
      promptOnClose: true
    },
    wizardMode: null,
    // wizard states
    wizardCancelling: false,
    wizardCompleting: false,
    wizardShowing: false,
    wizardUpdating: false,
    wizardNextBtnDisabled: false,
    // wizard contexts (page content)
    defaultWizardContext: null,
    wizardContext: null,
    EMPTY_STRING_REGEX: /^(?![\s\S])/,
    NONEMPTY_STRING_REGEX: /^(?!\s*$).+/,
    elementsToSerialize: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.initWizard();
    },
    // Returns names for validated pages
    validatedSteps: (0, _object.computed)('wizardContext.@each.complete', function () {
      var validated = [];
      if (this.wizardContext) {
        for (var i = 0, len = this.wizardContext.length; i < len; i++) {
          if (this.wizardContext[i].complete) {
            validated.push(this.wizardContext[i].name);
          }
        }
      }
      return validated;
    }),
    updateWizard: (0, _object.computed)('row.content.updateWizard', function () {
      if (this.row && this.row.content.updateWizard !== undefined) {
        this.initWizard();
      }
    }),
    afterFormTouched: (0, _object.observer)('wizardContext.@each.formTouched', function () {
      var ret = null;
      var fn = this.wizardBehaviour.afterFormTouched;
      if (fn && {}.toString.call(fn) === '[object Function]') {
        ret = fn(this);
      }
      this.set('afterFormTouchedValue', ret);
    }),
    initWizard: function initWizard() {
      // Is there a better way to do this? Seems no easy way to clone the EmberObject array for state reset.
      var defaultContext = this.defaultWizardContext;
      if (defaultContext) {
        var context = (0, _array.A)([]);
        for (var i = 0, len = defaultContext.length; i < len; i++) {
          context.pushObject(_object.default.create(_objectSpread(_objectSpread({}, defaultContext[i]), {}, {
            complete: false,
            formTouched: 0
          })));
        }
        this.set('wizardContext', context);
        var modelId = this.get('modelId');
        var modelName = this.get('modelName');
        var includedModels = this.get('includedModels') || null;
        if (modelId !== undefined) {
          var recordPromise = null;
          var schemaPromise = Ember.RSVP.Promise.cast(this.get('schema').cache[modelName]);
          if (modelId === 0) {
            recordPromise = this.get('store').createRecord(modelName);
            if (includedModels !== null) {
              for (var _i = 0, _len = includedModels.length; _i < _len; _i++) {
                var submodel = this.get('store').createRecord(includedModels[_i]);
                recordPromise.set(includedModels[_i], submodel);
              }
            }
          } else {
            recordPromise = this.get('store').findRecord(modelName, modelId);
          }
          var self = this;
          self.set('wizardUpdating', true);
          Ember.RSVP.hash({
            record: recordPromise,
            schema: schemaPromise
          }).then(function (result) {
            // Get model, set owner (for new Sites)
            var model = result.record;
            self.set('model', model);
            if (!(self.get('model').get('owner') === null)) {
              self.get('model').set('owner', self.simtex.viewing_owner);
            }
            if (!(self.get('model').get('account') === null) && self.account) {
              self.get('model').set('account', self.account);
            }
            var schema = {};
            if (result.schema) {
              schema = result.schema;
            }
            var wizardContext = self.get('wizardContext');
            for (var wizardPageId = 0, wizardPageCount = wizardContext.length; wizardPageId < wizardPageCount; wizardPageId++) {
              var wizardPage = wizardContext.objectAt(wizardPageId);
              var elementCount = wizardPage.elements ? wizardPage.elements.length : 0;
              wizardContext.objectAt(wizardPageId).incrementProperty('formTouched');
              var _loop = function _loop(elementId) {
                var element = wizardPage.elements[elementId];
                // If we haven't overridden any API schema fields,
                // we may have just provided an array of strings (valuePaths).
                // In this case turn it into an object so we can attach rawValue and schema data.
                if (typeof element === 'string') {
                  wizardPage.elements[elementId] = {
                    valuePath: element
                  };
                  element = wizardPage.elements[elementId];
                }
                var valuePath = element['valuePath'] || null;
                var propertySchema = void 0;
                if (valuePath.indexOf('.') > 0) {
                  var parent = element.valuePath.split('.')[0];
                  var children = element.valuePath.split('.')[1];
                  if (parent in schema) {
                    if ('children' in schema[parent]) {
                      propertySchema = schema[parent]['children'][children];
                    }
                  }
                } else {
                  propertySchema = schema[valuePath] || {};
                }

                // Determine rawValue.
                var rawValue = null;
                if (model && valuePath) {
                  if (element['modelValuePath']) valuePath = element['modelValuePath'];
                  var modelRawValue = model.get(valuePath);
                  rawValue = modelRawValue === null || modelRawValue === undefined ? null : modelRawValue;
                }

                // Set default for boolean types.
                if (propertySchema.type === 'boolean') {
                  if (rawValue === null) rawValue = false;
                }

                // Allow override of min/max's for integer types.
                if (propertySchema.type === 'integer') {
                  if (element.min === undefined) wizardPage.elements.set("".concat(elementId, ".min"), propertySchema.min_value);
                  if (element.max === undefined) wizardPage.elements.set("".concat(elementId, ".max"), propertySchema.max_value);
                }
                var visibleRequires = element['visibleRequires'] || null;
                var hideElement = element['hideElement'] || false;
                var visibleMinLength = element['visibleMinLength'] || 0;

                // If visibleRequires non-null, check for current value of the model field it references.
                // The current element may need to be hidden.
                var visibleRequiresEl = Array.isArray(visibleRequires) && visibleRequires[0]['element'] !== 'undefined' ? visibleRequires[0]['element'] : visibleRequires;
                var visibleRequiresVals = Array.isArray(visibleRequires) && visibleRequires[0]['values'] !== 'undefined' ? visibleRequires[0]['values'] : null;
                if (visibleRequiresEl) {
                  var valueLength = 0;
                  hideElement = true;
                  if (visibleRequiresVals == null) {
                    if (model) {
                      valueLength = (model.get(visibleRequiresEl) || '').trim().length;
                    }
                    hideElement = model === null || valueLength <= visibleMinLength;
                  } else {
                    if (model) {
                      visibleRequiresVals.forEach(function (val, idx) {
                        if ((model.get(visibleRequiresEl) || '').trim() == val) {
                          hideElement = false;
                        }
                      });
                    }
                  }
                }
                var elementClass = element['class'] || '';
                var computedBy = element['computedBy'] || null;
                var disabled = element['disabled'] || propertySchema.readonly || false;
                var pattern = element['pattern'] || null;
                var helpText = propertySchema.help_text || element['helpText'] || '';
                var type = element['type'] || propertySchema.type || 'string';
                var choices = element['choices'] || propertySchema.choices || null;
                if (element['disabledChoices']) {
                  if (choices) {
                    element['disabledChoices'].forEach(function (disabled_choice) {
                      var choiceIdx = choices.findIndex(function (item) {
                        return item.value == disabled_choice;
                      });
                      if (choiceIdx !== -1) {
                        choices.splice(choiceIdx, 1);
                      }
                    });
                  }
                }
                var required = element['required'] != null ? element['required'] : propertySchema['required'] || false;

                // Store the raw value and schema (if available) against the element
                wizardPage.elements.set("".concat(elementId, ".rawValue"), rawValue);
                wizardPage.elements.set("".concat(elementId, ".schema"), propertySchema);
                wizardPage.elements.set("".concat(elementId, ".required"), required);
                wizardPage.elements.set("".concat(elementId, ".type"), type);
                wizardPage.elements.set("".concat(elementId, ".choices"), choices);
                wizardPage.elements.set("".concat(elementId, ".help_text"), helpText);
                wizardPage.elements.set("".concat(elementId, ".invalid"), false);
                wizardPage.elements.set("".concat(elementId, ".invalidText"), null);
                wizardPage.elements.set("".concat(elementId, ".visibleRequires"), visibleRequires);
                wizardPage.elements.set("".concat(elementId, ".visibleMinLength"), visibleMinLength);
                wizardPage.elements.set("".concat(elementId, ".hide"), hideElement);
                wizardPage.elements.set("".concat(elementId, ".class"), elementClass);
                wizardPage.elements.set("".concat(elementId, ".computedBy"), computedBy);
                wizardPage.elements.set("".concat(elementId, ".disabled"), disabled);
                wizardPage.elements.set("".concat(elementId, ".pattern"), pattern);
                wizardPage.elements.set("".concat(elementId, ".isLoading"), false);
              };
              for (var elementId = 0; elementId < elementCount; elementId++) {
                _loop(elementId);
              }
            }
            self.set('wizardUpdating', false);
          }, function (err) {
            // self.toast.error('Problem initialising wizard');
          });
        }
      }
    },
    rollbackChanges: function rollbackChanges() {
      // Roll back changes to the model.
      if (this.get('model') !== null) {
        this.parentView.send('rollback', this.get('model'));
      }
    },
    saveChanges: function saveChanges() {
      // Save changes to the model.
      var model = this.get('model');
      if (model !== null) {
        var refresh_table = this.get('modelId') === 0;
        this.parentView.send('save_nested', model, refresh_table);
      }
    },
    completeWizard: function completeWizard() {
      var self = this;
      this.toggleProperty('wizardCompleting');
      Ember.run.later(function () {
        self.serializeElements();
        self.saveChanges();
        self.toggleProperty('wizardCompleting');
        self.toggleProperty('wizardShowing');
        self.initWizard();
      }, 500);
    },
    serializeElements: function serializeElements() {
      var model = this.get('model');
      var elements_to_serialize = this.get('elementsToSerialize') || null;
      if (elements_to_serialize !== null) {
        elements_to_serialize.forEach(function (element) {
          var json_obj = new Object();
          element['jsonProperties'].forEach(function (property) {
            if (model.get(property['valuePath']) !== null) {
              json_obj[property['propertyName']] = model.get(property['valuePath']);
            }
          });
          model.set(element['name'], JSON.stringify(json_obj));
        });
      }
    },
    validateInput: function validateInput(element, direction, wizardStep) {
      var invalidated = false;
      var invalidText = null;
      var wizardPageId = -1,
        elementId = -1;
      if (element) {
        wizardPageId = this.get('wizardContext').findIndex(function (w) {
          return w.name === wizardStep.name;
        });
        if (wizardPageId >= 0) {
          elementId = this.get('wizardContext').objectAt(wizardPageId).elements.findIndex(function (e) {
            return e.valuePath === element.valuePath;
          });
          if (direction === 'out') {
            var val = this.getValueFromElement(element.rawValue);

            // Check required
            if (element.required && ((val || '').toString().trim() === '' || (val || '').toString().toLowerCase() === 'invalid input')) {
              invalidText = 'This field is required';
              invalidated = true;
            }

            //check pattern
            if (element.pattern != null) {
              var match = false;
              element.pattern.forEach(function (pattern) {
                if (RegExp(pattern).test(val)) {
                  match = true;
                }
              });
              if (!match) {
                invalidText = 'The format of this field is invalid. Please refer to the help text and update the input.';
                invalidated = true;
              }
            }

            // Enforce casing requirements
            if (element.class) {
              var tmpVal = (val || '').toString();
              switch (element.class) {
                case 'text-transform-uppercase':
                  tmpVal = tmpVal.toUpperCase();
                  break;
                case 'text-transform-lowercase':
                  tmpVal = tmpVal.toLowerCase();
                  break;
                case 'text-transform-capitalise':
                  tmpVal = tmpVal.toLowerCase().split(' ').map(function (s) {
                    return s.charAt(0).toUpperCase() + s.substring(1);
                  }).join(' ');
                  break;
              }
              if (tmpVal.length > 0) {
                val = tmpVal;
              }
              this.get('wizardContext').objectAt(wizardPageId).set("elements.".concat(elementId, ".rawValue"), val);
            }

            // Update model
            if (this.get('model').get(element.valuePath) !== val) {
              if (element.type === 'choice' && val === '') {
                val = null;
              }
              if (element.valuePath.indexOf('.') > 0) {
                var parent = element.valuePath.split('.')[0];
                var child = element.valuePath.split('.')[1];
                this.get('model').get(parent).set(child, val);
              } else {
                this.get('model').set(element.valuePath, val);
              }
            }

            // Lastly, increment formTouched property for this page. `afterFormTouched` observer watches this.
            this.get('wizardContext').objectAt(wizardPageId).incrementProperty('formTouched');
          }
        }
      }
      if (wizardPageId >= 0 && elementId >= 0) {
        this.get('wizardContext').objectAt(wizardPageId).set("elements.".concat(elementId, ".invalid"), invalidated);
        this.get('wizardContext').objectAt(wizardPageId).set("elements.".concat(elementId, ".invalidText"), invalidText);
      }
      if (direction === 'out') {
        this.send('wizardElementChange', element.rawValue, element);
      }
      return !invalidated;
    },
    getValueFromElement: function getValueFromElement(element) {
      var ret = '';
      if (element !== undefined) {
        ret = element;
        if (element && element.firstObject !== undefined) ret = element.firstObject;
        if (element && element.firstObject !== undefined && element.firstObject.value !== undefined) ret = element.firstObject.value;
      }
      return ret;
    },
    testIsRequired: function testIsRequired(iterElement) {
      var _this = this;
      var wizardContext = this.get('wizardContext');
      var updateRequired = false;
      var required = true;
      var elementRequires = iterElement.mandatoryRequires || null;
      if (elementRequires !== null && !Array.isArray(elementRequires)) {
        // Matches on any string by default
        elementRequires = [{
          element: elementRequires,
          requireAny: [this.NONEMPTY_STRING_REGEX]
        }];
      }
      if (Array.isArray(elementRequires)) {
        var _loop2 = function _loop2(i, len) {
          var self = _this;
          self.wizardPageId = -1;
          self.wizardElementId = -1;
          self.requireElement = elementRequires[i];
          self.matchedWizardElement = null;
          wizardContext.forEach(function (wizardPage, wizardPageIdx) {
            wizardPage.elements.forEach(function (wizardElement, wizardElementIdx) {
              if (wizardElement.valuePath === self.requireElement.element) {
                self.wizardPageId = wizardPageIdx;
                self.wizardElementId = wizardElementIdx;
                self.matchedWizardElement = wizardElement;
              }
            });
          });
          if (self.wizardPageId >= 0 && self.wizardElementId >= 0) {
            updateRequired = true;
            if (self.matchedWizardElement !== null && self.matchedWizardElement.type === 'boolean') {
              required = Ember.$('#' + elementRequires[i].element).is(':checked');
            } else {
              // Convert nulls to match on empty string.
              var requireAnyArray = elementRequires[i].requireAny;
              requireAnyArray = !requireAnyArray ? [_this.EMPTY_STRING_REGEX] : requireAnyArray;

              // Parse array-type values
              var currValue = _this.getValueFromElement(wizardContext[self.wizardPageId].elements[self.wizardElementId].rawValue);
              currValue = (currValue || '').toString().trim();
              var anyRequired = false;
              for (var r = 0, _len2 = requireAnyArray.length; r < _len2; r++) {
                requireAnyArray[r] = requireAnyArray[r] === null ? _this.EMPTY_STRING_REGEX : requireAnyArray[r];
                anyRequired = anyRequired || RegExp(requireAnyArray[r]).test(currValue);
              }
              required = required && anyRequired;
            }
          }
        };
        for (var i = 0, len = elementRequires.length; i < len; i++) {
          _loop2(i, len);
        }
      }
      return [updateRequired, required];
    },
    actions: {
      clickOverlay: function clickOverlay() {
        // Do nothing, force dialog buttons to be used
      },
      cancellingWizard: function cancellingWizard() {
        this.toggleProperty('wizardCancelling');
        if (!this.wizardBehaviour.promptOnClose) {
          this.toggleProperty('wizardShowing');
          this.rollbackChanges();
          this.initWizard();
        }
      },
      cancelWizard: function cancelWizard() {
        this.toggleProperty('wizardCancelling');
        this.toggleProperty('wizardShowing');
        this.rollbackChanges();
        this.initWizard();
      },
      toggleWizard: function toggleWizard() {
        this.toggleProperty('wizardShowing');
      },
      changeStep: function changeStep(wizardRef, direction, resolve) {
        var _this2 = this;
        var currentStepNode = wizardRef.steps.find(function (stepNode) {
          return stepNode.isActive;
        });

        // Perform validation on step
        if (this.get('wizardContext')) {
          if (direction === 'previous') {
            // Unvalidate this step
            this.get('wizardContext').find(function (step) {
              return step.name === currentStepNode.name;
            }).set('complete', false);
          }
          if (direction === 'next' || direction === 'finish') {
            // Validate items in this step
            var self = this;
            self.validated = true;
            self.currentStepNode = currentStepNode;
            this.get('wizardContext').find(function (step) {
              return step.name === currentStepNode.name;
            }).elements.forEach(function (element) {
              // AND together all validation results
              self.validated &= _this2.validateInput(element, 'out', currentStepNode);
            });

            // Validation passed, update it to complete
            if (self.validated) {
              this.get('wizardContext').find(function (step) {
                return step.name === currentStepNode.name;
              }).set('complete', true);
            } else {
              return;
            }
          }
        }
        if (direction === 'finish') this.completeWizard();
        if (resolve) resolve();
      },
      focusInput: function focusInput(element, direction, wizardStep) {
        return this.validateInput(element, direction, wizardStep);
      },
      wizardElementChange: function wizardElementChange(el, context) {
        var _this3 = this;
        var self = this;
        self.el = el;
        self.context = context;
        self.updateRequired = [];
        self.elementRequired = [];
        this.get('wizardContext').forEach(function (page, pageIdx) {
          self.page = page;
          self.pageIdx = pageIdx;
          page.elements.forEach(function (iterElement, iterElementIdx) {
            // If visibleRequires defined for an element, its visibility is dependent on the element
            // specified by iterElement.visibleRequires having a non-empty value.
            // E.g. Building Number (1) visibility is dependent on Building Type.
            var currValue = self.getValueFromElement(self.el);
            currValue = (currValue || '').toString().trim();
            var currElement = self.context.id || self.context.valuePath;

            // Enforce visibility requirements
            var valueLength = currValue.length;
            var visibleRequiresEl = Array.isArray(iterElement.visibleRequires) && iterElement.visibleRequires[0]['element'] !== 'undefined' ? iterElement.visibleRequires[0]['element'] : iterElement.visibleRequires;
            var visibleRequiresVals = Array.isArray(iterElement.visibleRequires) && iterElement.visibleRequires[0]['values'] !== 'undefined' ? iterElement.visibleRequires[0]['values'] : null;
            if (visibleRequiresEl === currElement) {
              var hideElement = true;
              if (visibleRequiresVals == null) {
                hideElement = valueLength <= iterElement.visibleMinLength; // visibleMinLength defaults to 0
              } else {
                visibleRequiresVals.forEach(function (val, idx) {
                  if (currValue == val) {
                    hideElement = false;
                  }
                });
              }
              self.get('wizardContext').objectAt(self.pageIdx).set("elements.".concat(iterElementIdx, ".hide"), hideElement);
            }

            // Enforce mandatory element requirements
            var testIterElementRequired = self.testIsRequired(iterElement);
            if (testIterElementRequired[0]) {
              self.get('wizardContext').objectAt(self.pageIdx).set("elements.".concat(iterElementIdx, ".required"), testIterElementRequired[1]);
              // Reset element invalidity to false if no longer required
              if (!testIterElementRequired[1]) self.get('wizardContext').objectAt(self.pageIdx).set("elements.".concat(iterElementIdx, ".invalid"), false);
            }

            // Enforce mapping requirements
            if (iterElement.mappingRequires === currElement) {
              if (iterElement.sourceMap === currValue) {
                self.get('wizardContext').objectAt(self.pageIdx).set("elements.".concat(iterElementIdx, ".rawValue"), iterElement.targetMap);
              }
            }

            //Compute Values
            if (iterElement.computedBy !== null && iterElement.computedBy !== undefined && currValue !== null) {
              if (iterElement.computedBy['element'] === currElement && self.context.invalid != true && (iterElement.computedBy['eventType'] == null || iterElement.computedBy['eventType'] == event.type)) {
                var ret = null;
                var fn = iterElement.computedBy['computeFunction'];
                var wizard = _this3;
                var targetPageIdx = self.pageIdx;
                var targetElementIdx = iterElementIdx;
                var propertyToUpdate = iterElement.computedBy['propertyToUpdate'];
                var elementVal = currValue;
                if (fn && {}.toString.call(fn) === '[object Function]') {
                  var _ret = fn(wizard, elementVal);
                  self.get('wizardContext').objectAt(targetPageIdx).set("elements.".concat(targetElementIdx, ".isLoading"), true);
                  _ret.then(function (result) {
                    propertyToUpdate.forEach(function (property) {
                      if (property in result) {
                        self.get('wizardContext').objectAt(targetPageIdx).set("elements.".concat(targetElementIdx, ".").concat(property), result[property]);
                      }
                    });
                    self.get('wizardContext').objectAt(targetPageIdx).set("elements.".concat(targetElementIdx, ".isLoading"), false);
                  });
                }
              }
            }
          });
        });
      }
    }
  });
  _exports.default = _default;
});