define("simtex-myaccount/templates/components/password-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.wrapperClazz}}>
    <button class={{this.buttonClazz}} type="button" tabindex="-1" data-click="pw-btn" data-pw="show">Show</button>
    <Input @value={{this.password}}
           @type="password"
           id={{this.inputId}}
           class={{this.inputClazz}}
           tabindex={{this.tabindex}}
           maxlength={{this.maxlength}}
           autocomplete={{this.autocomplete}}
           placeholder={{this.placeholder}}
           disabled={{this.disabled}}
    />
  </div>
  */
  {
    "id": "+6pbWGMV",
    "block": "[[[10,0],[15,0,[30,0,[\"wrapperClazz\"]]],[12],[1,\"\\n  \"],[10,\"button\"],[15,0,[30,0,[\"buttonClazz\"]]],[14,\"tabindex\",\"-1\"],[14,\"data-click\",\"pw-btn\"],[14,\"data-pw\",\"show\"],[14,4,\"button\"],[12],[1,\"Show\"],[13],[1,\"\\n  \"],[8,[39,0],[[16,1,[30,0,[\"inputId\"]]],[16,0,[30,0,[\"inputClazz\"]]],[16,\"tabindex\",[30,0,[\"tabindex\"]]],[16,\"maxlength\",[30,0,[\"maxlength\"]]],[16,\"autocomplete\",[30,0,[\"autocomplete\"]]],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[16,\"disabled\",[30,0,[\"disabled\"]]]],[[\"@value\",\"@type\"],[[30,0,[\"password\"]],\"password\"]],null],[1,\"\\n\"],[13]],[],false,[\"input\"]]",
    "moduleName": "simtex-myaccount/templates/components/password-toggle.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});