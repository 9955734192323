define("simtex-myaccount/models/musiconhold", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    audioslotid: _emberData.default.attr(),
    audioslottypeid_label: _emberData.default.attr('string'),
    audioslotstatusid_label: _emberData.default.attr('string'),
    aflabel: _emberData.default.attr('string'),
    audioslotlabel: _emberData.default.attr('string'),
    datetime: _emberData.default.attr('string'),
    audiofileid: _emberData.default.attr(),
    audioslotuuid: _emberData.default.attr(),
    audioslottypeid: _emberData.default.attr(),
    audioslotstatusid: _emberData.default.attr()
  }); // export default DS.Model.extend({
  //     musiconholdid: DS.attr(),
  //     owner: DS.attr(),
  //     label: DS.attr('string'),
  //     directory: DS.attr('string'),
  //     applications: DS.attr('string'),
  //     mode: DS.attr('string'),
  //     digit: DS.attr('string'),
  //     sort: DS.attr('string'),
  //     format: DS.attr('string'),
  // });
  _exports.default = _default;
});