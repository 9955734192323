define("simtex-myaccount/components/card-data-footer", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common"], function (_exports, _component, _modelSaverCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    formTouched: true,
    savingDisable: false,
    customAction: '',
    actions: {
      customSavingAction: function customSavingAction() {
        switch (this.customAction) {
          case "getZTJobStatusLoop":
            this.parentView.getZTJobStatusLoop(0);
            break;
          default:
            return;
        }
      }
    }
  });
  _exports.default = _default;
});