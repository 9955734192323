define("simtex-myaccount/components/subscriptions/subscription-service-details", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object"], function (_exports, _component, _modelSaverCommon, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _component.default.extend(_modelSaverCommon.default, (_obj = {
    numberListExpanded: false,
    defaultNumberQtyShown: 3,
    toggleNumbers: function toggleNumbers() {
      this.set('numberListExpanded', !this.numberListExpanded);
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleNumbers", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleNumbers"), _obj)), _obj));
  _exports.default = _default;
});