define("simtex-myaccount/components/numbers/dids/did-clr-wizard", ["exports", "@ember/component", "@ember/array", "@ember/object", "simtex-myaccount/mixins/clr-wizard-common", "simtex-myaccount/mixins/model-saver-common", "@ember/string", "@ember/template", "ember-data"], function (_exports, _component, _array, _object, _clrWizardCommon, _modelSaverCommon, _string, _template, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_clrWizardCommon.default, _modelSaverCommon.default, {
    modelName: 'did',
    modelId: 0,
    includedModels: ['didhistoryjson'],
    object_name: 'Add New Numbers',
    removeSiteShowing: false,
    allocatedDIDs: null,
    modalWorking: false,
    wizardAppearance: null,
    wizardBehaviour: {
      promptOnClose: true,
      afterFormTouched: null,
      afterFormTouchedValue: null,
      afterFormTouchedPosition: null
    },
    wizardAppearanceNew: {
      wizardOpenBtnIcon: 'plus',
      wizardOpenBtnText: 'Add Numbers',
      wizardTitle: 'Add New Numbers',
      wizardOpenBtnClass: ' btn-link'
    },
    elementsToSerialize: [{
      name: 'jsondata',
      jsonProperties: [{
        propertyName: 'PortCategory',
        valuePath: 'didhistoryjson.portcategory'
      }, {
        propertyName: 'SimtexBatchId',
        valuePath: 'didhistoryjson.simtexbatchid'
      }, {
        propertyName: 'CarrierRef',
        valuePath: 'didhistoryjson.carrierref'
      }, {
        propertyName: 'CarrierActionedDate',
        valuePath: 'didhistoryjson.carrieractioneddate'
      }, {
        propertyName: 'DateSentToCarrier',
        valuePath: 'didhistoryjson.datesenttocarrier'
      }, {
        propertyName: 'PortedInDate',
        valuePath: 'didhistoryjson.portedindate'
      }, {
        propertyName: 'CustomerRef',
        valuePath: 'didhistoryjson.customerref'
      }]
    }],
    init: function init() {
      this._super.apply(this, arguments);
      var wizardMode = (0, _string.capitalize)(this.get('wizardMode') || this.get('column.wizardMode') || 'new');
      this.set('wizardAppearance', this.get("wizardAppearance".concat(wizardMode)));
      this.set('defaultWizardContext', (0, _array.A)([_object.default.create({
        name: 'Type and Location',
        elements: [{
          valuePath: 'didstate',
          disabledChoices: [1, 2, 4, 5, 6, 8, 9, 10, 11, 12, 14, 15]
        }, 'country', 'location']
      }), _object.default.create({
        name: 'Numbers',
        elements: [{
          label: 'Block',
          valuePath: 'blockchoice',
          choices: [{
            value: '1',
            display_name: '1'
          }, {
            value: '10',
            display_name: '10'
          }, {
            value: '100',
            display_name: '100'
          }, {
            value: 'Any',
            display_name: 'Any'
          }],
          type: 'choice',
          required: true
        }, {
          label: 'Starting Number',
          valuePath: 'startingnumber',
          required: true,
          helpText: "Full e.164 number without + eg: 61894883300. This needs to be a 9 to 12 digit number. " + "If you have chosen a specific block size, you will only need to enter one starting number." + "If you want to add any number of DIDs, please enter a list of DIDs separated by comma.",
          computedBy: {
            element: 'startingnumber',
            propertyToUpdate: ['help_text', 'invalid', 'invalidText'],
            eventType: 'blur',
            computeFunction: function computeFunction(wizard, computedByValue) {
              return new Promise(function (resolve, reject) {
                var blocksize = wizard.get('model.blockchoice');
                var did_arr = [];
                var starting_number = computedByValue;
                var availabilityArray = [];
                var help_text = "";
                var invalid = false;
                if (!blocksize) {
                  invalid = true;
                  help_text = help_text + "Please select a block size";
                  resolve({
                    'invalid': invalid,
                    'invalidText': (0, _template.htmlSafe)(help_text)
                  });
                }
                if (starting_number == "") {
                  invalid = true;
                  help_text = help_text + "Invalid input";
                  resolve({
                    'invalid': invalid,
                    'invalidText': (0, _template.htmlSafe)(help_text)
                  });
                }
                if (blocksize == 'Any') {
                  did_arr = starting_number.replace(' ', '').split(',');
                } else {
                  if (starting_number.indexOf(',') !== -1) {
                    invalid = true;
                    help_text = help_text + "Invalid input. You can only enter one starting number " + "if a numeric block size (" + blocksize + ") is chosen.";
                    resolve({
                      'invalid': invalid,
                      'invalidText': (0, _template.htmlSafe)(help_text)
                    });
                  }
                  for (var i = 0; i < parseInt(blocksize); i++) {
                    did_arr.push(parseInt(starting_number.replace(" ", "")) + i);
                  }
                }
                did_arr.forEach(function (did) {
                  if (did.toString() !== "") {
                    did = did.toString().replace(/[^0-9\.]+/g, '');
                    var patterns = [/^(611300)([0-9]{6})$/, /^(611800)([0-9]{6})$/, /^(61)([0-9]{9})$/, /^(6113)([0-9]{4})$/, /^(64)([0-9]{8})$/];
                    var match = false;
                    patterns.forEach(function (pattern) {
                      if (RegExp(pattern).test(did)) {
                        match = true;
                      }
                    });
                    if (!match) {
                      invalid = true;
                      help_text = help_text + "Your block contains invalid numbers. " + " Each number should be a full e.164 number (9 to 12 digits) without + eg: 61894883300.";
                      resolve({
                        'invalid': invalid,
                        'invalidText': (0, _template.htmlSafe)(help_text)
                      });
                    }

                    //now checking the availability
                    availabilityArray.push(_emberData.default.PromiseObject.create({
                      promise: wizard.store.query('did', {
                        search: did.toString()
                      })
                    }).then(function (result) {
                      if (result.length == 0) {
                        return {
                          number: did,
                          available: true
                        };
                      } else {
                        return {
                          number: did,
                          available: false
                        };
                      }
                    }));
                  }
                });
                wizard.set("wizardNextBtnDisabled", invalid);
                //Passed all validation
                if (!invalid) {
                  Promise.all(availabilityArray).then(function (result) {
                    var numbers_available = true;
                    result.forEach(function (did_result, idx) {
                      if (did_result['available'] == false) {
                        numbers_available = false;
                      }
                    });
                    if (!numbers_available) {
                      help_text = "Your block contains some existing numbers.";
                    } else {
                      help_text = "<span style='color: green;'>All numbers in the block are available.</span>";
                    }
                    invalid = !numbers_available;
                    wizard.set("wizardNextBtnDisabled", invalid);
                    resolve({
                      'help_text': (0, _template.htmlSafe)(help_text),
                      'invalid': invalid,
                      'invalidText': (0, _template.htmlSafe)(help_text)
                    });
                  });
                }
              });
            }
          }
        }]
      }), _object.default.create({
        name: 'Number Provisioning',
        elements: ['carrier', {
          valuePath: 'didhistoryjson.portcategory',
          visibleRequires: [{
            element: 'didstate',
            values: [7]
          }],
          mandatoryRequires: [{
            element: 'didstate',
            requireAny: [/^(7)/]
          }]
        }, {
          valuePath: 'didhistoryjson.carrierref',
          visibleRequires: [{
            element: 'didstate',
            values: [3, 7]
          }],
          mandatoryRequires: [{
            element: 'didstate',
            requireAny: [/^(3|7)/]
          }]
        }, {
          valuePath: 'didhistoryjson.simtexbatchid',
          visibleRequires: [{
            element: 'didstate',
            values: [7]
          }],
          mandatoryRequires: [{
            element: 'didstate',
            requireAny: [/^(7)/]
          }]
        }, {
          valuePath: 'didhistoryjson.carrieractioneddate',
          visibleRequires: [{
            element: 'didstate',
            values: [3]
          }],
          mandatoryRequires: [{
            element: 'didstate',
            requireAny: [/^(3)/]
          }]
        }, {
          valuePath: 'didhistoryjson.datesenttocarrier',
          visibleRequires: [{
            element: 'didstate',
            values: [7]
          }],
          mandatoryRequires: [{
            element: 'didstate',
            requireAny: [/^(7)/]
          }]
        }, {
          valuePath: 'didhistoryjson.portedindate',
          visibleRequires: [{
            element: 'didstate',
            values: [7]
          }],
          mandatoryRequires: [{
            element: 'didstate',
            requireAny: [/^(7)/]
          }]
        }, 'historynotes']
      }), _object.default.create({
        name: 'Allocation',
        elements: [{
          valuePath: 'owner',
          mandatoryRequires: [{
            element: 'didstate',
            requireAny: [/^(7|13)/]
          }],
          computedBy: {
            element: 'owner',
            propertyToUpdate: ['help_text'],
            eventType: 'blur',
            computeFunction: function computeFunction(wizard, computedByValue) {
              return new Promise(function (resolve, reject) {
                var records = _emberData.default.PromiseObject.create({
                  promise: wizard.store.query('owner', {
                    smile_search: computedByValue
                  }).then(function (owners) {
                    return owners;
                  })
                });
                records.then(function (owners) {
                  var help_text = "";
                  if (owners.length == 0) {
                    help_text = "Cannot find owner.";
                  } else {
                    help_text = "<table class='table table-compact'>";
                    owners.forEach(function (owner) {
                      var curr_owner = owner.owner;
                      var company_name = wizard.simtex.getSmileProperty(owner.smile_data.Properties.Object.String, 'name');
                      help_text = help_text + '<tr><td>' + curr_owner + '</td><td>' + company_name + '</td></tr>';
                    });
                    help_text = help_text + '</table>';
                  }
                  resolve({
                    'help_text': (0, _template.htmlSafe)(help_text)
                  });
                });
              });
            }
          }
        }, {
          valuePath: 'account',
          modelValuePath: 'account.accountid'
        }, {
          valuePath: 'didhistoryjson.customerref',
          mandatoryRequires: [{
            element: 'didstate',
            requireAny: [/^(7|13)/]
          }]
        }]
      })]));
    }
  });
  _exports.default = _default;
});