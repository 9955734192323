define("simtex-myaccount/components/system/zerotouchjob-table", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/template", "@ember/service", "@ember/object"], function (_exports, _component, _tableCommon, _template, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _component.default.extend(_tableCommon.default, (_obj = {
    helpers: (0, _service.inject)(),
    schema: (0, _service.inject)(),
    modelName: 'zerotouchjob',
    page_size: 10,
    sort: 'zerotouchjobid',
    dir: 'desc',
    start_gte: null,
    start_lt: null,
    filter_zerotouchjobstatus: null,
    filter_zerotouchjobstatus_list: null,
    filter_originalzerotouchstate: null,
    filter_originalzerotouchstate_list: null,
    filter_updatedzerotouchstate: null,
    filter_updatedzerotouchstate_list: null,
    filterList: ['owner', 'start_gte', 'start_lt', 'zerotouchjobstatus_label', 'originalzerotouchstate_label', 'updatedzerotouchstate_label'],
    init: function init() {
      this._super.apply(this, arguments);
      this.send('setPage', 1);
      var zerotouchjobstatus_list = [this.schema.enumZeroTouchJobStatus.SUCCEEDED, this.schema.enumZeroTouchJobStatus.FAILED, this.schema.enumZeroTouchJobStatus.PENDING, this.schema.enumZeroTouchJobStatus.PROCESSING];
      this.set('filter_zerotouchjobstatus_list', zerotouchjobstatus_list.map(function (enumItem) {
        return {
          label: enumItem.valueOf(),
          value: enumItem.valueOf()
        };
      }));
      this.set('filter_zerotouchjobstatus', this.filter_zerotouchjobstatus_list.map(function (item) {
        return item.value;
      }));
      var originalzerotouchstate_list = [this.schema.enumZeroTouchState.PENDING_ADD, this.schema.enumZeroTouchState.PENDING_LOOKUP, this.schema.enumZeroTouchState.PENDING_REMOVE];
      var updatedzerotouchstate_list = [this.schema.enumZeroTouchState.IDLE, this.schema.enumZeroTouchState.OK, this.schema.enumZeroTouchState.MISSING, this.schema.enumZeroTouchState.MISMATCHED_BINDING, this.schema.enumZeroTouchState.BOUND_ANOTHER_PROVIDER, this.schema.enumZeroTouchState.GENERAL_ERROR];
      this.set('filter_originalzerotouchstate_list', originalzerotouchstate_list.map(function (enumItem) {
        return {
          label: enumItem.valueOf(),
          value: enumItem.valueOf()
        };
      }));
      this.set('filter_originalzerotouchstate', this.filter_originalzerotouchstate_list.map(function (item) {
        return item.value;
      }));
      this.set('filter_updatedzerotouchstate_list', updatedzerotouchstate_list.map(function (enumItem) {
        return {
          label: enumItem.valueOf(),
          value: enumItem.valueOf()
        };
      }));
      this.set('filter_updatedzerotouchstate', this.filter_updatedzerotouchstate_list.map(function (item) {
        return item.value;
      }));
    },
    columns: (0, _object.computed)(function () {
      var simtex = this.simtex;
      return [{
        label: 'Job ID',
        sortable: true,
        valuePath: 'zerotouchjobid'
      }, {
        label: 'Acct Number',
        sortable: true,
        valuePath: 'owner'
      }, {
        label: 'Acct Name',
        valuePath: 'smile_data.Properties.Object.String',
        sortable: true,
        format: function format(valueArray) {
          return this.simtex.getSmileProperty(valueArray, 'name');
        }
      }, {
        label: 'Handset',
        sortable: true,
        valuePath: 'endpoint.macaddress',
        cellComponent: 'handsets/handset-light-table-cell'
      }, {
        label: 'Job Status',
        sortable: true,
        valuePath: 'zerotouchjobstatus_label',
        format: function format() {
          var job_status_class = "";
          var job_status = this.row.get('zerotouchjobstatus_label');
          if (job_status == this.schema.enumZeroTouchJobStatus.SUCCEEDED) {
            job_status_class = "label-success";
          } else if (job_status == this.schema.enumZeroTouchJobStatus.FAILED) {
            job_status_class = "label-danger";
          } else if (job_status == this.schema.enumZeroTouchJobStatus.PENDING) {
            job_status_class = "label-info";
          } else if (job_status == this.schema.enumZeroTouchJobStatus.PROCESSING) {
            job_status_class = "label-warning";
          }
          return (0, _template.htmlSafe)("<span class='label " + job_status_class + "'>" + job_status + "</span>");
        }
      }, {
        label: 'Original Zero Touch State',
        sortable: true,
        valuePath: 'originalzerotouchstate_label'
      }, {
        label: 'Updated Zero Touch State',
        sortable: true,
        valuePath: 'updatedzerotouchstate_label',
        format: function format() {
          var zerotouchstate_class = "";
          var zerotouchstate = this.row.get('updatedzerotouchstate_label');
          if (zerotouchstate == this.schema.enumZeroTouchState.OK) {
            zerotouchstate_class = "label-success";
          } else if (zerotouchstate == this.schema.enumZeroTouchState.GENERAL_ERROR || zerotouchstate == this.schema.enumZeroTouchState.BOUND_ANOTHER_PROVIDER || zerotouchstate == this.schema.enumZeroTouchState.MISMATCHED_BINDING || zerotouchstate == this.schema.enumZeroTouchState.MISSING) {
            zerotouchstate_class = "label-danger";
          } else if (zerotouchstate == this.schema.enumZeroTouchState.IDLE) {
            zerotouchstate_class = "label-info";
          } else if (zerotouchstate == this.schema.enumZeroTouchState.REMOVED) {
            zerotouchstate_class = "label-warning";
          } else {
            return (0, _template.htmlSafe)("<span>State not updated yet. <br>Please refresh the table after few seconds.</span>");
          }
          return (0, _template.htmlSafe)("<span class='label " + zerotouchstate_class + "'>" + zerotouchstate + "</span>");
        }
      }, {
        label: 'Create Date and Time',
        sortable: true,
        valuePath: 'createddatetime',
        format: function format() {
          var created_datetime = this.row.get('createddatetime');
          return moment(created_datetime).format('DD-MM-YYYY hh:mm:ss');
        }
      }, {
        label: 'Action',
        sortable: false,
        cellComponent: 'system/zerotouchjob-table-action'
      }];
    }),
    applyFilters: function applyFilters() {
      this.set('zerotouchjobstatus_label', this.get('filter_zerotouchjobstatus').join());
      this.set('originalzerotouchstate_label', this.get('filter_originalzerotouchstate').join());
      this.set('updatedzerotouchstate_label', this.get('filter_updatedzerotouchstate').join());
      this.send('refresh');
    }
  }, (_applyDecoratedDescriptor(_obj, "applyFilters", [_object.action], Object.getOwnPropertyDescriptor(_obj, "applyFilters"), _obj)), _obj));
  _exports.default = _default;
});