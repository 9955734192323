define("simtex-myaccount/components/accounts/account-table", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/object", "@ember/template"], function (_exports, _component, _tableCommon, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, {
    modelName: 'owner',
    sort: 'owner',
    alwaysFetch: false,
    // Set to false to prevent searching for empty string
    init: function init() {
      this._super.apply(this, arguments);
      if (this.simtex.get('get_claims_account') !== null) {
        this.set('alwaysFetch', true);
      }
      this.send('setPage', 1, this.get('alwaysFetch'));
    },
    columns: (0, _object.computed)(function () {
      return [{
        label: 'Actions',
        width: '150px',
        sortable: false,
        valuePath: 'smile_data',
        // account-table-actions will not render buttons if this is falsy
        cellComponent: 'accounts/account-table-actions'
      }, {
        label: 'Acct Number',
        valuePath: 'owner',
        sortable: true
      }, {
        label: 'Acct Name',
        valuePath: 'smile_data.Properties.Object.String',
        sortable: true,
        format: function format(valueArray) {
          return this.simtex.getSmileProperty(valueArray, 'name');
        }
      }, {
        label: 'Contact Name',
        valuePath: 'smile_data.Properties.Object.String',
        sortable: true,
        format: function format(valueArray) {
          var properties = this.simtex.getModelProperties(valueArray, ['contactTitle', 'contactGiven', 'contactFamily'], true, '');
          return properties.join(' ');
        }
      }, {
        label: 'Phone Number',
        valuePath: 'smile_data.Properties.Object.String',
        sortable: true,
        format: function format(valueArray) {
          var properties = this.simtex.getModelProperties(valueArray, [{
            'label': 'Work',
            'propertyName': 'workPhone'
          }, {
            'label': 'Mobile',
            'propertyName': 'mobilePhone'
          }, {
            'label': 'Fax',
            'propertyName': 'fax'
          }, {
            'label': 'Home',
            'propertyName': 'homePhone'
          }], true, '(empty)');
          return (0, _template.htmlSafe)(properties.join('<br />'));
        }
      }, {
        label: 'Email Address',
        valuePath: 'smile_data.Properties.Object.String',
        sortable: false,
        format: function format(valueArray) {
          var properties = this.simtex.getModelProperties(valueArray, [{
            'label': 'Primary',
            'propertyName': 'emailAddress'
          }, {
            'label': 'Technical',
            'propertyName': 'technicalContactEmail'
          }, {
            'label': 'Account',
            'propertyName': 'accountContactEmail'
          }], true, '(empty)');
          return (0, _template.htmlSafe)(properties.join('<br />'));
        }
      }, {
        label: 'Physical Address',
        valuePath: 'smile_data.Properties.Object.String',
        sortable: false,
        format: function format(valueArray) {
          var billingProperties = [this.simtex.getModelProperties(valueArray, ['billAddress'], true, '').join(' '), this.simtex.getModelProperties(valueArray, ['billNumber', 'billName', 'billType', 'billTypeSuffix'], true, '').join(' ').trim(), this.simtex.getModelProperties(valueArray, ['billSuburb', 'billPostCode', 'billState', 'billCountry'], true, '').join(' ').trim()].filter(function (property) {
            return property.length > 0;
          });
          var streetProperties = [this.simtex.getModelProperties(valueArray, ['streetAddress'], true, '').join(' '), this.simtex.getModelProperties(valueArray, ['streetNumber', 'streetName', 'streetType', 'streetTypeSuffix'], true, '').join(' ').trim(), this.simtex.getModelProperties(valueArray, ['streetSuburb', 'streetPostcode', 'streetState', 'streetCountry'], true, '').join(' ').trim()].filter(function (property) {
            return property.length > 0;
          });
          if (billingProperties.length === 0) billingProperties.push('(empty)');
          if (streetProperties.length === 0) streetProperties.push('(empty)');
          var ret = '<strong>Billing:</strong> ' + billingProperties.join(', ') + '<br />' + '<strong>Street:</strong> ' + streetProperties.join(', ');
          return (0, _template.htmlSafe)(ret);
        }
      }];
    }),
    actions: {
      listSubscriptions: function listSubscriptions(row) {
        this.router.transitionTo('restricted.subscriptions.list', row.content.id);
      },
      listDIDs: function listDIDs(row) {
        this.router.transitionTo('restricted.numbers.dids', row.content.id);
      },
      listHandsets: function listHandsets(row) {
        this.router.transitionTo('restricted.handsets.list', row.content.id);
      },
      editAccount: function editAccount(row) {
        this.router.transitionTo('restricted.accounts.edit', row.content.id);
      }
    }
  });
  _exports.default = _default;
});