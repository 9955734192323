define("simtex-myaccount/components/accounts/test-table", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/object", "@ember/template"], function (_exports, _component, _tableCommon, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, {
    modelName: 'countrychoices',
    sort: 'countrychoices',
    alwaysFetch: true,
    // Set to false to prevent searching for empty string
    init: function init() {
      this._super.apply(this, arguments);
      if (this.simtex.get('get_claims_account') !== null) {
        this.set('alwaysFetch', true);
      }
      this.send('setPage', 1, this.get('alwaysFetch'));
    },
    columns: (0, _object.computed)(function () {
      return [{
        label: 'Country',
        width: '150px',
        sortable: false,
        valuePath: 'country'
      }];
    })
  });
  _exports.default = _default;
});