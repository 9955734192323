define("simtex-myaccount/components/element-select", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  // https://spin.atomicobject.com/2016/02/23/ember-2-select-box/
  var _default = _component.default.extend((_obj = {
    content: [],
    prompt: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    multiple: '',
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }
    },
    change: function change() {
      // decrement indexes by 1 if we have a prompt
      var hasPrompt = !!this.get('prompt');
      var selectedIndex = this.$('select')[0].selectedIndex;
      var selectedOptions = this.$('select')[0].selectedOptions;
      var selectedIndexes = [];
      if (selectedOptions) {
        for (var i = 0, len = selectedOptions.length; i < len; i++) {
          var index = hasPrompt ? selectedOptions[i].index - 1 : selectedOptions[i].index;
          if (selectedIndexes.indexOf(index) < 0) {
            selectedIndexes.push(index);
          }
        }
      } else {
        selectedIndexes.push(selectedIndex);
      }
      var content = this.get('content');
      // let contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;
      // let _selection2 = content[contentIndex];

      var _selection = content[hasPrompt ? selectedIndex - 1 : selectedIndex];
      if (this.multiple) {
        _selection = [];
        for (var _i = 0, _len = selectedIndexes.length; _i < _len; _i++) {
          _selection.push(content[selectedIndexes[_i]]);
        }
      }
      this.sendAction('willChangeAction', _selection);
      if (this.get('optionValuePath')) {
        var _selectionValues = _selection[this.get('optionValuePath')];
        if (this.multiple) {
          _selectionValues = [];
          for (var _i2 = 0, _len2 = _selection.length; _i2 < _len2; _i2++) {
            _selectionValues.push(_selection[_i2][this.get('optionValuePath')]);
          }
        }
        this.set('selection', _selectionValues);
      } else {
        this.set('selection', _selection);
      }
      this.sendAction('didChangeAction', _selection, this);
    },
    doubleClick: function doubleClick() {
      this.sendAction('doubleClickAction', this);
    },
    contextMenu: function contextMenu() {
      this.sendAction('contextMenuAction', this);
    }
  }, (_applyDecoratedDescriptor(_obj, "change", [_object.action], Object.getOwnPropertyDescriptor(_obj, "change"), _obj), _applyDecoratedDescriptor(_obj, "doubleClick", [_object.action], Object.getOwnPropertyDescriptor(_obj, "doubleClick"), _obj)), _obj));
  _exports.default = _default;
});