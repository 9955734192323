define("simtex-myaccount/components/subscriptions/channellimit-data", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object"], function (_exports, _component, _modelSaverCommon, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Channel Limits',
    product_data: null,
    // Display Channel limits under following conditions:
    // - SuperUser: Product is SIP Trunk OR Teams Direct Route
    // - Admin: Product is SIP Trunk OR Teams Direct Route OR CloudPBX OR Primary Inbound
    // This excludes display for:
    // - EndUsers: all products
    // - SuperUser: Fax OR CloudPBX OR Primary Inbound
    // - Admin: Fax
    //
    channellimits_visible: (0, _object.computed)('product_data', 'simtex.role', function () {
      if (this.product_data) {
        if (this.simtex.is_superuser) {
          return this.product_data.get('is_sip_trunk') || this.product_data.get('is_teams_direct_route');
        } else if (this.simtex.is_admin) {
          return this.product_data.get('is_sip_trunk') || this.product_data.get('is_teams_direct_route') || this.product_data.get('is_cloud_pbx') || this.product_data.get('is_primary_inbound');
        }
      }
      return false;
    }),
    inboundchannels_editable: (0, _object.computed)('product_data', 'simtex.role', function () {
      // Only editable for Admin for CloudPBX and Primary Inbound products, if Total Channels 0
      if (!!this.product_data && this.model.get('totalchannels') === 0) {
        return this.simtex.is_admin && (this.product_data.get('is_cloud_pbx') || this.product_data.get('is_primary_inbound'));
      }
      return false;
    }),
    outboundchannels_editable: (0, _object.computed)('product_data', 'simtex.role', function () {
      // Always editable by Admin, if Total Channels 0
      return !!this.product_data && this.model.get('totalchannels') === 0;
    }),
    totalchannels_editable: (0, _object.computed)('product_data', 'simtex.role', function () {
      // Currently total channels always not editable
      return false;
    }),
    show_save: (0, _object.computed)('inboundchannels_editable', 'outboundchannels_editable', 'totalchannels_editable', function () {
      return this.inboundchannels_editable || this.outboundchannels_editable || this.totalchannels_editable;
    })
  });
  _exports.default = _default;
});