define("simtex-myaccount/services/helpers", ["exports", "@ember/service", "moment"], function (_exports, _service, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    monthYearForDate: function monthYearForDate(dt) {
      return (0, _moment.default)(dt).format('MMMM, YYYY');
    }
  });
  _exports.default = _default;
});