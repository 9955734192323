define("simtex-myaccount/templates/restricted/numbers/didclidstamping", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title 'Caller ID Over-Stamping' }}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top: 0;">Caller ID Over-Stamping - Teams</h2>
              <h4 style="margin-top: 0;">Note: this feature is only available to Teams trunks provisioned after 15 June 2023.  Please contact Simtex support if you have an older trunk and wish to use this feature.</h4>
              {{numbers/dids/did-clidstamping-buckets
                      model=this.model
                      select_primary=this.select_primary}}
          </div>
      </div>
  </div>
  
  */
  {
    "id": "p/oE+xAH",
    "block": "[[[1,[28,[35,0],[\"Caller ID Over-Stamping\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top: 0;\"],[12],[1,\"Caller ID Over-Stamping - Teams\"],[13],[1,\"\\n            \"],[10,\"h4\"],[14,5,\"margin-top: 0;\"],[12],[1,\"Note: this feature is only available to Teams trunks provisioned after 15 June 2023.  Please contact Simtex support if you have an older trunk and wish to use this feature.\"],[13],[1,\"\\n            \"],[1,[28,[35,1],null,[[\"model\",\"select_primary\"],[[30,0,[\"model\"]],[30,0,[\"select_primary\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"numbers/dids/did-clidstamping-buckets\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/numbers/didclidstamping.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});