define("simtex-myaccount/templates/components/numbers/dids/did-clr-wizard", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!defaultWizardContext, wizardAppearance, wizardBehaviour defined in /components/numbers/sites/site-clr-wizard}}
  {{clr-wizard model=this.model
               defaultWizardContext=this.defaultWizardContext
               modelId=this.modelId
               modelName=this.modelName
               includedModels=this.includedModels
               wizardAppearance=this.wizardAppearance
               wizardBehaviour=this.wizardBehaviour
               updateWizard=this.updateWizard
               wizardNextBtnDisabled=this.wizardNextBtnDisabled
               elementsToSerialize=this.elementsToSerialize
  }}
  */
  {
    "id": "bDVurqrz",
    "block": "[[[1,[28,[35,0],null,[[\"model\",\"defaultWizardContext\",\"modelId\",\"modelName\",\"includedModels\",\"wizardAppearance\",\"wizardBehaviour\",\"updateWizard\",\"wizardNextBtnDisabled\",\"elementsToSerialize\"],[[30,0,[\"model\"]],[30,0,[\"defaultWizardContext\"]],[30,0,[\"modelId\"]],[30,0,[\"modelName\"]],[30,0,[\"includedModels\"]],[30,0,[\"wizardAppearance\"]],[30,0,[\"wizardBehaviour\"]],[30,0,[\"updateWizard\"]],[30,0,[\"wizardNextBtnDisabled\"]],[30,0,[\"elementsToSerialize\"]]]]]]],[],false,[\"clr-wizard\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/dids/did-clr-wizard.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});