define("simtex-myaccount/components/step-manager", ["exports", "ember-steps/components/step-manager"], function (_exports, _stepManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stepManager.default;
    }
  });
});