define("simtex-myaccount/serializers/endpointexpansion", ["exports", "simtex-myaccount/serializers/drf", "ember-data", "@ember/service"], function (_exports, _drf, _emberData, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: "endpointexpansionid",
    attrs: {
      expansionlinekey_data: {
        embedded: 'always'
      },
      endpointexpansiontype: {
        deserialize: 'records',
        serialize: 'ids'
      }
    },
    simtex: (0, _service.inject)('simtex'),
    extractMeta: function extractMeta(store, type, payload) {
      var meta = this._super(store, type, payload);
      return this.simtex.extract_meta(meta, type, payload);
    }
  });
  _exports.default = _default;
});