define("simtex-myaccount/templates/restricted/subscriptions/subscription/dids", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title 'Numbers (' this.model.accountid ')'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top: 0rem;">DIDs for {{this.model.accountid}}</h2>
              {{numbers/dids/did-table
                      ownerFilterTerm=this.simtex.owner_search_term account=this.model.accountid}}
          </div>
      </div>
  </div>
  */
  {
    "id": "ss9taudc",
    "block": "[[[1,[28,[35,0],[\"Numbers (\",[30,0,[\"model\",\"accountid\"]],\")\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top: 0rem;\"],[12],[1,\"DIDs for \"],[1,[30,0,[\"model\",\"accountid\"]]],[13],[1,\"\\n            \"],[1,[28,[35,1],null,[[\"ownerFilterTerm\",\"account\"],[[30,0,[\"simtex\",\"owner_search_term\"]],[30,0,[\"model\",\"accountid\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"numbers/dids/did-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/subscriptions/subscription/dids.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});