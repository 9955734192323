define("simtex-myaccount/components/subscriptions/subscription/numberpresentation-dialog", ["exports", "@ember/component", "@ember/object", "@ember/service", "simtex-myaccount/mixins/table-common", "simtex-myaccount/mixins/model-saver-common", "ember-data"], function (_exports, _component, _object, _service, _tableCommon, _modelSaverCommon, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, _modelSaverCommon.default, {
    store: (0, _service.inject)(),
    simtex: (0, _service.inject)('simtex'),
    modalShowing: false,
    modelName: 'did',
    sort: 'did',
    alwaysFetch: true,
    search: null,
    filterList: ['owner_filter_term', 'search'],
    owner_filter_term: (0, _object.computed)(function () {
      return this.simtex.owner_search_term;
    }),
    remove_fields: [
    //'did_data',
    'carrier', 'carriername', 'country', 'didhistory', 'jsondata', 'lastupdated', 'endpoint', 'faxrouting', 'historynotes', 'ipndsite', 'location', 'notes', 'url'],
    init: function init() {
      this._super.apply(this, arguments);
      this.send('setPage', 1);
    },
    columns: (0, _object.computed)(function () {
      var cols = [{
        label: 'DID',
        valuePath: 'did',
        sortable: true
      }, {
        label: 'DID State',
        valuePath: 'didstatename',
        sortable: true
      }];
      return cols;
    }),
    resetModal: function resetModal() {
      this.set('search', null);
      this.send('setPage', 1, false);
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('modalShowing');
        this.resetModal();
      },
      searchForNumbers: function searchForNumbers() {
        this.send('refresh');
      },
      completeModal: function completeModal() {
        var _this = this;
        var self = this;
        var selected_number = this.get('table.selectedRows')[0];
        this.numberpresentation_model.set('phoneid', selected_number.get('phoneid'));
        this.numberpresentation_model.then(function (result) {
          result.save().then(function () {
            self.toast.success("Saved Number Presentation");
            _this.parentView.get_numberpresentation_list();
          });
        });
        this.toggleProperty('modalShowing');
        this.resetModal();
      }
    }
  });
  _exports.default = _default;
});