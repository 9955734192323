define("simtex-myaccount/models/endpointexpansiontype", ["exports", "ember-data", "@ember/object"], function (_exports, _emberData, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    endpointexpansiontypeid: _emberData.default.attr(),
    endpointexpansion: _emberData.default.hasMany('endpointexpansion'),
    endpointexpansiontypecategory: _emberData.default.attr(),
    brand: _emberData.default.attr('string'),
    model: _emberData.default.attr('string'),
    pages: _emberData.default.attr(),
    leftbuttonsperpage: _emberData.default.attr(),
    rightbuttonsperpage: _emberData.default.attr(),
    maxusesperendpoint: _emberData.default.attr(),
    formatted_brand_model: (0, _object.computed)('brand', 'model', function () {
      return [(this.get('brand') || '').trim(), (this.get('model') || '').toUpperCase().trim()].join(' ').trim();
    }),
    formatted_brand_model_lower_hyphenated: (0, _object.computed)('formatted_brand_model', function () {
      return (this.get('formatted_brand_model') || '').toLowerCase().replace(' ', '-');
    })
  });
  _exports.default = _default;
});