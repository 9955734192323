define("simtex-myaccount/components/numbers/sites/site-clr-wizard", ["exports", "@ember/component", "@ember/array", "@ember/object", "simtex-myaccount/mixins/clr-wizard-common", "simtex-myaccount/mixins/model-saver-common", "@ember/string", "@ember/template", "ember-data"], function (_exports, _component, _array, _object, _clrWizardCommon, _modelSaverCommon, _string, _template, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_clrWizardCommon.default, _modelSaverCommon.default, {
    modelName: 'ipndsite',
    modelId: 0,
    object_name: 'IPND Site',
    removeSiteShowing: false,
    allocatedDIDs: null,
    modalWorking: false,
    wizardAppearance: null,
    wizardBehaviour: {
      promptOnClose: true,
      afterFormTouched: function afterFormTouched(wizard) {
        return _emberData.default.PromiseObject.create({
          promise: wizard.model.serviceLocationSummary.then(function (combined) {
            if (combined.length === 0) combined.push('Update Service address fields to see preview.');
            var result = "<div><strong>IPND Service Location</strong>" + "<br />".concat(combined.join(',<br />'), "</div>");
            return (0, _template.htmlSafe)(result);
          })
        });
      },
      afterFormTouchedValue: null,
      afterFormTouchedPosition: 'navFooter'
    },
    wizardAppearanceNew: {
      wizardOpenBtnIcon: 'plus',
      wizardOpenBtnText: 'Add Site',
      wizardTitle: 'Create Site',
      wizardOpenBtnClass: " btn-sm btn-secondary btn-simtex-table-action "
    },
    wizardAppearanceEdit: {
      wizardOpenBtnIcon: 'edit',
      wizardOpenBtnText: 'Edit Site',
      wizardTitle: 'Edit Site',
      wizardDeleteBtnIcon: 'times',
      wizardDeleteBtnText: 'Remove',
      wizardDeleteTitle: 'Remove Site',
      wizardOpenBtnClass: " btn-sm btn-secondary btn-simtex-table-action "
    },
    disableRemove: (0, _object.computed)('modelId', 'removeSiteShowing', function () {
      return this.modelId === 0 || this.removeSiteShowing;
    }),
    allocationCount: (0, _object.computed)('modelId', 'removeSiteShowing', function () {
      if (this.modelId === 0) return _emberData.default.PromiseObject.create({
        promise: Ember.RSVP.Promise.cast(this.modelId).then(function () {
          // There are no allocations for this Site
          return 0;
        })
      });
      var self = this;
      return _emberData.default.PromiseObject.create({
        promise: this.store.query('did', {
          'search': this.simtex.viewing_owner || this.owner,
          'page_size': 200
        }).then(function (result) {
          self.allocatedDIDs = result.toArray().filter(function (did) {
            return did.ipndsite.get('id') === self.modelId.toString();
          });
          return self.allocatedDIDs.length;
        })
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modelId', this.get('row.ipndsiteid') || 0);
      var wizardMode = (0, _string.capitalize)(this.get('wizardMode') || this.get('column.wizardMode') || 'new');
      this.set('wizardAppearance', this.get("wizardAppearance".concat(wizardMode)));
      this.set('defaultWizardContext', (0, _array.A)([_object.default.create({
        name: 'Site Details',
        elements: ['label', 'listcode', 'usagecode', 'typeofservice', {
          valuePath: 'customertitle',
          class: 'text-transform-capitalise'
        }, {
          valuePath: 'customername1',
          class: 'text-transform-capitalise'
        }, {
          valuePath: 'customername2',
          class: 'text-transform-capitalise'
        }, {
          valuePath: 'longname',
          visibleRequires: 'customername2',
          visibleMinLength: 30,
          class: 'text-transform-capitalise'
        }]
      }), _object.default.create({
        name: 'Service - Building / Floor',
        content: 'Only fill out if applicable',
        elements: [{
          valuePath: 'servicebuildingtype'
        }, {
          valuePath: 'servicebuilding1stnr',
          visibleRequires: 'servicebuildingtype',
          mandatoryRequires: 'servicebuildingtype',
          // Require for all non-empty servicebuildingtype values
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicebuilding1stsuffix',
          visibleRequires: 'servicebuildingtype',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicebuilding2ndnr',
          visibleRequires: 'servicebuildingtype',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicebuilding2ndsuffix',
          visibleRequires: 'servicebuildingtype',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicebuildingfloortype',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicebuildingfloornr',
          visibleRequires: 'servicebuildingfloortype',
          mandatoryRequires: [{
            // Require for floor types of Floor and Level
            element: 'servicebuildingfloortype',
            requireAny: [/^FL$/, /^L$/]
          }],
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicebuildingfloornrsuffix',
          visibleRequires: 'servicebuildingfloortype',
          class: 'text-transform-uppercase'
        }]
      }), _object.default.create({
        name: 'Service - Street Address',
        elements: [{
          valuePath: 'servicestreethousenr1',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicestreethousenr1suffix',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicestreethousenr2',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicestreethousenr2suffix',
          visibleRequires: 'servicestreethousenr2',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'servicestreetname1',
          mandatoryRequires: [{
            element: 'servicebuildingproperty',
            requireAny: null // empty string
          }],

          class: 'text-transform-capitalise'
        }, {
          valuePath: 'servicestreetname2',
          visibleRequires: 'servicestreetname1',
          visibleMinLength: 15
        }, 'servicestreettype1', {
          valuePath: 'servicestreetsuffix1',
          class: 'text-transform-capitalise'
        }, {
          valuePath: 'servicebuildingproperty',
          mandatoryRequires: [{
            element: 'servicestreetname1',
            requireAny: null // empty string
          }],

          class: 'text-transform-capitalise'
        }, {
          valuePath: 'servicebuildinglocation',
          class: 'text-transform-capitalise'
        }, {
          valuePath: 'serviceaddresslocality',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'serviceaddressstate',
          class: 'text-transform-uppercase'
        }, {
          valuePath: 'serviceaddresspostcode',
          min: 0,
          max: 9999,
          mappingRequires: 'serviceaddressstate',
          sourceMap: 'OS',
          targetMap: '0000'
        }]
      }), _object.default.create({
        name: 'Roaming Service',
        elements: ['alternateaddressflag', {
          valuePath: 'customercontactname1',
          mandatoryRequires: 'alternateaddressflag'
        }, {
          valuePath: 'customercontactname2'
        }, {
          valuePath: 'customercontactnr',
          mandatoryRequires: 'alternateaddressflag'
        }]
      })]));
    },
    actions: {
      toggleRemoveSite: function toggleRemoveSite() {
        this.toggleProperty('removeSiteShowing');
      },
      removeSite: function removeSite() {
        var promiseArray = [];
        var allocatedDIDs = this.get('allocatedDIDs');

        // Deallocate all DIDs
        this.toggleProperty('modalWorking');
        for (var i = 0, len = allocatedDIDs.length; i < len; i++) {
          allocatedDIDs[i].set('ipndsite', null);
          promiseArray.push(_emberData.default.PromiseObject.create({
            promise: allocatedDIDs[i].save()
          }));
        }
        promiseArray.push(_emberData.default.PromiseObject.create({
          promise: this.store.findRecord('ipndsite', this.get('modelId'), {
            backgroundReload: false
          })
        }));
        var self = this;
        Promise.all(promiseArray).then(function (result) {
          // Now it is safe to delete the IPNDSite itself
          result.lastObject.destroyRecord().then(function () {
            self.toast.success('Removed IPND Site');
            self.toggleProperty('removeSiteShowing');
            self.toggleProperty('modalWorking');
            self.send('handleWizardUpdate', true);
          }, function (err) {
            self.toast.error('Error removing Site');
            self.toggleProperty('removeSiteShowing');
          });
        }, function (err) {
          self.toast.error('Error removing DID(s) from Site');
          self.toggleProperty('removeSiteShowing');
        });
      }
    }
  });
  _exports.default = _default;
});