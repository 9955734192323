define("simtex-myaccount/templates/passwordrequest", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="login-wrapper">
      {{password-request-form username=u caller=c}}
  </div>
  
  {{outlet}}
  */
  {
    "id": "xX6Xort9",
    "block": "[[[10,0],[14,0,\"login-wrapper\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"username\",\"caller\"],[[33,1],[33,2]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,4],null,null],null,null,null]],[],false,[\"password-request-form\",\"u\",\"c\",\"component\",\"-outlet\"]]",
    "moduleName": "simtex-myaccount/templates/passwordrequest.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});