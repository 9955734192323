define("simtex-myaccount/models/callrecording", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    account_id: _emberData.default.attr(),
    callrecord_inbound: _emberData.default.attr('number'),
    callrecord_outbound: _emberData.default.attr('number'),
    // prefixblockingmode: DS.attr('boolean'),
    callrecording_prefixblacklist: _emberData.default.attr('string'),
    producttype_id: _emberData.default.attr('number')
    // vmemail: DS.attr(),
    // pin: DS.attr(),
    // account_id: DS.attr()
  });
  _exports.default = _default;
});