define("simtex-myaccount/models/did", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    phoneid: _emberData.default.attr(),
    did: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    owner: _emberData.default.attr(),
    account: _emberData.default.belongsTo('account'),
    account_id: _emberData.default.attr(),
    ipndsite: _emberData.default.belongsTo('ipndsite'),
    prefix: _emberData.default.attr(),
    phonenumber: _emberData.default.attr(),
    country: _emberData.default.attr(),
    location: _emberData.default.attr(),
    endpoint: _emberData.default.attr(),
    faxrouting: _emberData.default.attr(),
    notes: _emberData.default.attr('string'),
    didhistory: _emberData.default.attr(),
    didstate: _emberData.default.attr(),
    carrier: _emberData.default.attr(),
    historynotes: _emberData.default.attr('string'),
    jsondata: _emberData.default.attr(),
    lastupdated: _emberData.default.attr('date'),
    didstatename: _emberData.default.attr(),
    didstatedescription: _emberData.default.attr(),
    carriername: _emberData.default.attr(),
    startingnumber: _emberData.default.attr(),
    blockchoice: _emberData.default.attr(),
    translatedphoneid: _emberData.default.attr(),
    translatedphone_did: _emberData.default.attr(),
    targettranslatedphone_did: _emberData.default.hasMany('simple-did', {
      async: false
    }),
    clidstampphone: _emberData.default.attr(),
    clidstampphone_did: _emberData.default.attr(),
    targetclidstampphone_did: _emberData.default.hasMany('simple-did', {
      async: false
    }),
    didhistoryjson: _emberData.default.belongsTo('didhistoryjson', {
      async: true
    })
  });
  _exports.default = _default;
});