define("simtex-myaccount/controllers/restricted/handsets/mapping", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    queryParams: ['select_primary'],
    select_primary: null
  });
  _exports.default = _default;
});