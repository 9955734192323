define("simtex-myaccount/components/numbers/dids/did-assignment-dialog", ["exports", "@ember/component", "@ember/object", "@ember/service", "ember-light-table", "ember-data", "simtex-myaccount/mixins/table-common", "@ember/template"], function (_exports, _component, _object, _service, _emberLightTable, _emberData, _tableCommon, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, {
    store: (0, _service.inject)(),
    modalShowing: false,
    alertShowing: false,
    settingNumberChange: false,
    did_owner: null,
    accountInfo: (0, _object.computed)(function () {
      return (0, _template.htmlSafe)('Please provide a Smile USN (2xxxxxxxxx)');
    }),
    country: null,
    location: null,
    search: null,
    notes: null,
    has_owner: (0, _object.computed)(function () {
      if (this.modalMode == 'Assign') {
        return false;
      } else if (this.modalMode == 'Deallocate') {
        return true;
      } else if (this.modalMode == 'Quarantine') {
        return true;
      }
    }),
    has_account: (0, _object.computed)(function () {
      if (this.modalMode == 'Assign') {
        return false;
      } else if (this.modalMode == 'Quarantine') {
        return true;
      }
    }),
    didstates: (0, _object.computed)(function () {
      if (this.modalMode == 'Assign') {
        return '2,3,15';
      } else if (this.modalMode == 'Deallocate') {
        return '1';
      }
    }),
    modalClass: (0, _object.computed)(function () {
      if (this.viewMode == 'menu') {
        return "nav-link";
      }
      if (this.modalMode == 'Assign') {
        return 'btn btn-link';
      } else if (this.modalMode == 'Deallocate') {
        return 'btn btn-link btn-link-danger';
      } else if (this.modalMode == 'Quarantine') {
        return 'btn btn-link btn-link-danger';
      }
    }),
    ownerInvalid: false,
    locationChoices: null,
    page_size: 20,
    currentScrollOffset: 0,
    modelName: 'did',
    sort: 'did',
    alwaysFetch: true,
    filterList: ['country', 'location', 'search', 'has_owner', 'page_size', 'didstates', 'has_account'],
    model: [],
    init: function init() {
      this._super.apply(this, arguments);
      var table = _emberLightTable.default.create({
        columns: this.get('columns'),
        rows: this.get('model'),
        enableSync: true
      });
      this.set('table', table);
      this.send('setPage', false);
    },
    columns: (0, _object.computed)(function () {
      var cols = [{
        label: 'DID',
        valuePath: 'did',
        sortable: true
      }, {
        label: 'Country',
        valuePath: 'country',
        sortable: true
      }, {
        label: 'Location',
        valuePath: 'location',
        sortable: true
      }, {
        label: 'DID State',
        valuePath: 'didstatename',
        sortable: true
      }];
      if (this.modalMode == 'Deallocate') {
        cols.push({
          label: 'Owner',
          valuePath: 'owner',
          sortable: true
        });
      }
      return cols;
    }),
    promptOnClose: true,
    modalCancelling: false,
    table: (0, _object.computed)(function () {
      return _emberLightTable.default.create({
        columns: this.get('columns'),
        rows: this.get('model'),
        enableSync: true
      });
    }),
    make_choices: function make_choices(result_array, property) {
      var choices = [{
        label: 'All',
        value: ''
      }];
      for (var i = 0, len = result_array.length; i < len; i++) {
        choices.push({
          label: result_array[i].get(property),
          value: result_array[i].get(property)
        });
      }
      return choices;
    },
    get_countrychoices: (0, _object.computed)(function () {
      var self = this;
      return _emberData.default.PromiseObject.create({
        promise: this.store.findAll('countrychoices').then(function (result) {
          return self.make_choices(result.toArray(), 'country');
        })
      });
    }),
    resetModal: function resetModal() {
      if (!(this.router.currentRoute.attributes && this.router.currentRoute.attributes.hasOwnProperty('owner'))) {
        this.set('owner', null);
      }
      this.set('accountInfo', null);
      this.set('country', null);
      this.set('location', null);
      this.set('search', null);
      this.set('ownerInvalid', false);
      this.send('refresh');
    },
    saveChanges: function saveChanges() {
      var _this = this;
      var self = this;
      if (this.modalMode == "Assign" && (this.get('owner') == null || this.get('owner') == "" || this.get('owner') == undefined)) {
        this.set('ownerInvalid', true);
      }
      if (this.get('table.selectedRows').length == 0 || this.get('ownerInvalid')) {
        return;
      }
      var did_list = [];
      var did_record_list = [];
      var numberpresentation_list = [];
      this.toggleProperty('settingNumberChange');
      this.get('table.selectedRows').forEach(function (row) {
        did_list.push(_emberData.default.PromiseObject.create({
          promise: _this.store.findRecord('did', row.get('did')).then(function (did_record) {
            did_record_list.push(did_record);
            var owner = null;
            var label = null;
            var didstate = null;
            var jsondata_obj = new Object();
            if (_this.modalMode == 'Assign') {
              owner = _this.get('owner');
              label = _this.get('label');
              didstate = 1;
              jsondata_obj['Owner'] = owner;
            } else if (_this.modalMode == 'Deallocate') {
              owner = null;
              label = null;
              didstate = 2;
              jsondata_obj['PreviousOwner'] = did_record.owner;
            } else if (_this.modalMode == 'Quarantine') {
              owner = did_record.owner;
              label = null;
              didstate = 12;
            }
            did_record.set('owner', owner);
            did_record.set('label', label);
            did_record.set('account', null);
            did_record.set('didstate', didstate);
            did_record.set('jsondata', JSON.stringify(jsondata_obj));
            did_record.set('historynotes', _this.notes);
            return did_record.save();
          })
        }));
      });
      return Promise.all(did_list).then(function (result) {
        _this.toggleProperty('settingNumberChange');
        self.toggleProperty('modalShowing');
        _this.toggleProperty('alertShowing');
        self.resetModal();
        self.toast.success("Saved numbers successfully.");
      }).catch(function (adapterError) {
        _this.toggleProperty('settingNumberChange');
        if (Array.isArray(adapterError.errors)) {
          _this.toast.error((0, _template.htmlSafe)("Error saving " + self.object_name + '<br />' + adapterError.errors.map(function (err) {
            return err.detail;
          }).join('<br />')));
        } else {
          _this.toast.error("Error saving " + self.object_name + '<br />');
        }
      });
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('modalShowing');
        this.send('refresh');
      },
      cancellingModal: function cancellingModal() {
        this.toggleProperty('modalCancelling');
        if (!this.promptOnClose) {
          this.toggleProperty('modalShowing');
        }
      },
      cancelModal: function cancelModal() {
        this.toggleProperty('modalCancelling');
        this.toggleProperty('modalShowing');
        this.resetModal();
      },
      reloadLocation: function reloadLocation() {
        var self = this;
        return _emberData.default.PromiseObject.create({
          promise: this.store.query('locationchoices', {
            country: self.country ? self.country : ''
          }).then(function (result) {
            var choices = self.make_choices(result.toArray(), 'location');
            self.set('locationChoices', choices);
          })
        });
      },
      selectAll: function selectAll(selectAllChecked) {
        //when selectAll are clicked, need to check whether the table rendered total number of available row or not before the select action
        var tableMetaCount = selectAllChecked.target.attributes['data-meta-count'];
        if (tableMetaCount == undefined) return;
        var selected = this.get('table.selectedRows');
        var totalRow = this.get('table.rows');

        //If number of rendered rows in table is same as total count, then simply perform select action
        if (totalRow.length == tableMetaCount.value || !selectAllChecked.target.checked) selectHelper();
        //if number of rendered rows in table is smaller than the total count
        //set the page size to total count and perform table fetching and pass selectHelper() to fetchRecords() as a call back
        else if (totalRow.length < tableMetaCount.value) {
          if (this.get('model') != undefined && this.get('model').length > 0) {
            var list = this.get('model').toArray();
            this.get('model').removeObjects(list);
          }
          this.send('setPage', 1, false);
          this.set('cursor', null);
          this.page_size = parseInt(tableMetaCount.value);
          this.get('fetchRecords').perform(selectHelper);
          this.page_size = 20; //Set the pag size back to original value
        }

        function selectHelper() {
          if (selectAllChecked.target.checked) {
            totalRow.setEach('selected', true);
          } else {
            selected.setEach('selected', false);
          }
        }
      },
      searchOwner: function searchOwner() {
        var self = this;
        if (self.get('owner') == null || self.get('owner') == '') {
          self.set('ownerInvalid', true);
          return;
        }
        return _emberData.default.PromiseObject.create({
          promise: this.store.findRecord('owner', self.owner).then(function (record) {
            var account_info = "";
            var curr_owner = record.owner;
            var company_name = self.simtex.getSmileProperty(record.smile_data.Properties.Object.String, 'name');
            account_info = record.owner + ' - ' + company_name;
            self.set('accountInfo', (0, _template.htmlSafe)(account_info));
            self.set('ownerInvalid', false);
          }).catch(function () {
            self.set('accountInfo', (0, _template.htmlSafe)('Account not found'));
            self.set('ownerInvalid', true);
          })
        });
      },
      toggleAlert: function toggleAlert() {
        if (this.modalMode == "Assign" && (this.get('owner') == null || this.get('owner') == "" || this.get('owner') == undefined)) {
          this.set('ownerInvalid', true);
        }
        if (this.get('table.selectedRows').length == 0 || this.get('ownerInvalid')) {
          return;
        }
        this.toggleProperty('alertShowing');
      },
      completeModal: function completeModal() {
        this.saveChanges();
      }
    }
  });
  _exports.default = _default;
});