define("simtex-myaccount/templates/components/handsets/handset-light-table-cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!#if this.row.isPending}}
  {{#if (or this.row.isPending (or this.row.content.endpoint.isPending))}}
      <div class="spinner spinner-sm">Loading...</div>
  {{else}}
      {{#if this.row.endpoint.endpointaccount_data}}
          {{#each this.row.endpoint.endpointaccount_data as |endpointaccount|}}
              {{#if endpointaccount.id}}
                  <LinkTo @route='restricted.subscriptions.subscription.handset'
                          @models={{array this.row.owner endpointaccount.account.id this.row.endpoint._endpointid}}
                             class="nav-link" target="_blank">{{this.row.endpoint.macaddress}}</LinkTo><br />
                  <small style="">{{this.row.endpoint.label}}</small>
              {{/if}}
          {{/each}}
      {{else}}
          {{this.row.endpoint.macaddress}}
          <br />
          <small style="">{{this.row.endpoint.label}}</small>
      {{/if}}
  {{/if}}
  */
  {
    "id": "EowFL013",
    "block": "[[[41,[28,[37,1],[[30,0,[\"row\",\"isPending\"]],[28,[37,1],[[30,0,[\"row\",\"content\",\"endpoint\",\"isPending\"]]],null]],null],[[[1,\"    \"],[10,0],[14,0,\"spinner spinner-sm\"],[12],[1,\"Loading...\"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"row\",\"endpoint\",\"endpointaccount_data\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"row\",\"endpoint\",\"endpointaccount_data\"]]],null]],null],null,[[[41,[30,1,[\"id\"]],[[[1,\"                \"],[8,[39,4],[[24,0,\"nav-link\"],[24,\"target\",\"_blank\"]],[[\"@route\",\"@models\"],[\"restricted.subscriptions.subscription.handset\",[28,[37,5],[[30,0,[\"row\",\"owner\"]],[30,1,[\"account\",\"id\"]],[30,0,[\"row\",\"endpoint\",\"_endpointid\"]]],null]]],[[\"default\"],[[[[1,[30,0,[\"row\",\"endpoint\",\"macaddress\"]]]],[]]]]],[10,\"br\"],[12],[13],[1,\"\\n                \"],[10,\"small\"],[14,5,\"\"],[12],[1,[30,0,[\"row\",\"endpoint\",\"label\"]]],[13],[1,\"\\n\"]],[]],null]],[1]],null]],[]],[[[1,\"        \"],[1,[30,0,[\"row\",\"endpoint\",\"macaddress\"]]],[1,\"\\n        \"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[10,\"small\"],[14,5,\"\"],[12],[1,[30,0,[\"row\",\"endpoint\",\"label\"]]],[13],[1,\"\\n\"]],[]]]],[]]]],[\"endpointaccount\"],false,[\"if\",\"or\",\"each\",\"-track-array\",\"link-to\",\"array\"]]",
    "moduleName": "simtex-myaccount/templates/components/handsets/handset-light-table-cell.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});