define("simtex-myaccount/templates/restricted/callrouting/callforwarding", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title 'Call Forwarding (' this.simtex.owner_description ')'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top: 0;">Call Forwarding for {{this.simtex.owner_description}}</h2>
              {{callrouting/callforwarding-table
                      ownerFilterTerm=this.simtex.owner_search_term}}
          </div>
      </div>
  </div>
  */
  {
    "id": "9Cmk8dwW",
    "block": "[[[1,[28,[35,0],[\"Call Forwarding (\",[30,0,[\"simtex\",\"owner_description\"]],\")\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top: 0;\"],[12],[1,\"Call Forwarding for \"],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n            \"],[1,[28,[35,1],null,[[\"ownerFilterTerm\"],[[30,0,[\"simtex\",\"owner_search_term\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"callrouting/callforwarding-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/callrouting/callforwarding.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});