define("simtex-myaccount/components/subscriptions/subscription/handset-advanced-data", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object"], function (_exports, _component, _modelSaverCommon, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Advanced Handset Configuration',
    range_slider_formatter: {
      to: function to(value) {
        return Math.round(value * 100) / 100;
      },
      from: function from(value) {
        return Number(value);
      }
    },
    actions: {
      setRangeSliderValue: function setRangeSliderValue(configStoreObject, newValue) {
        if (configStoreObject && newValue !== undefined) {
          (0, _object.set)(configStoreObject, 'configvalue', newValue);
        }
      },
      setCheckboxValue: function setCheckboxValue(configStoreObject, event) {
        if (configStoreObject && event !== undefined) {
          var val = event.target.checked ? '1' : '0';
          (0, _object.set)(configStoreObject, 'configvalue', val);
        }
      }
    }
  });
  _exports.default = _default;
});