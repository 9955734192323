define("simtex-myaccount/services/ajax", ["exports", "ember-ajax/services/ajax", "simtex-myaccount/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Required for OPTIONS requests for Models without any records (owner-visible, or otherwise)
  var _default = _ajax.default.extend({
    host: _environment.default.APP.API_HOST
  });
  _exports.default = _default;
});