define("simtex-myaccount/templates/components/subscriptions/subscription/handset-custom-parameters", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="clr-row">
      <div class="clr-col-12 clr-col-xl-7">
          <div class="card simtex-card">
              <div class="card-header">
                  Custom Parameters
              </div>
              <div class="card-block">
                  <div class="card-text">
                      <form class="clr-form">
                          <div class="clr-form-control simtex-inherit-margin">
                              <label for="customconfig" class="clr-control-label">Custom Config:</label>
                              <div class="clr-control-container">
                                  <div class="clr-textarea-wrapper simtex-textarea">
                                      <Textarea class="clr-textarea"
                                                id="customconfig"
                                                @type="textarea"
                                                @value={{this.model.endpoint.customconfig}}
                                      />
                                  </div>
                                  <span class="clr-subtext">
                                      Custom Config entered here will be included in generated profile
                                      configuration files.
                                  </span>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
              {{card-data-footer model=this.model.endpoint object_name='Custom Parameters'}}
          </div>
      </div>
  </div>
  */
  {
    "id": "XKUEb1wn",
    "block": "[[[10,0],[14,0,\"clr-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"clr-col-12 clr-col-xl-7\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"card simtex-card\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n                Custom Parameters\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"card-block\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"card-text\"],[12],[1,\"\\n                    \"],[10,\"form\"],[14,0,\"clr-form\"],[12],[1,\"\\n                        \"],[10,0],[14,0,\"clr-form-control simtex-inherit-margin\"],[12],[1,\"\\n                            \"],[10,\"label\"],[14,\"for\",\"customconfig\"],[14,0,\"clr-control-label\"],[12],[1,\"Custom Config:\"],[13],[1,\"\\n                            \"],[10,0],[14,0,\"clr-control-container\"],[12],[1,\"\\n                                \"],[10,0],[14,0,\"clr-textarea-wrapper simtex-textarea\"],[12],[1,\"\\n                                    \"],[8,[39,0],[[24,0,\"clr-textarea\"],[24,1,\"customconfig\"]],[[\"@type\",\"@value\"],[\"textarea\",[30,0,[\"model\",\"endpoint\",\"customconfig\"]]]],null],[1,\"                                \"],[13],[1,\"\\n                                \"],[10,1],[14,0,\"clr-subtext\"],[12],[1,\"\\n                                    Custom Config entered here will be included in generated profile\\n                                    configuration files.\\n                                \"],[13],[1,\"\\n                            \"],[13],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[1,[28,[35,1],null,[[\"model\",\"object_name\"],[[30,0,[\"model\",\"endpoint\"]],\"Custom Parameters\"]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"textarea\",\"card-data-footer\"]]",
    "moduleName": "simtex-myaccount/templates/components/subscriptions/subscription/handset-custom-parameters.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});