define("simtex-myaccount/serializers/endpoint", ["exports", "simtex-myaccount/serializers/drf", "ember-data", "@ember/service"], function (_exports, _drf, _emberData, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: "endpointid",
    attrs: {
      endpointmodel: {
        embedded: 'always'
      },
      endpointdialplan: {
        embedded: 'always'
      },
      endpointaccount_data: {
        embedded: 'always'
      },
      endpointexpansion_data: {
        embedded: 'always'
      },
      configdefinitiongroup_data: {
        embedded: 'always'
      }
    },
    simtex: (0, _service.inject)('simtex'),
    extractMeta: function extractMeta(store, type, payload) {
      var meta = this._super(store, type, payload);
      return this.simtex.extract_meta(meta, type, payload);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var provisionconfig = '';
      var provisionconfig_label = '';
      if (payload) {
        provisionconfig = payload.provisionconfig;
        provisionconfig_label = payload.provisionconfig_label;
        delete payload.provisionconfig;
        delete payload.provisionconfig_label;
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
  _exports.default = _default;
});