define("simtex-myaccount/models/product", ["exports", "ember-data", "@ember/object", "@ember/service"], function (_exports, _emberData, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    simtex: (0, _service.inject)('simtex'),
    productid: _emberData.default.attr(),
    producttype: _emberData.default.attr(),
    label: _emberData.default.attr(),
    deprecated: _emberData.default.attr('boolean'),
    smileplancode: _emberData.default.attr(),
    is_fax: (0, _object.computed)('producttype.producttypeid', function () {
      return this.get('producttype.producttypeid') === this.simtex.PRODUCTTYPE.FAX;
    }),
    is_sip_trunk: (0, _object.computed)('producttype.producttypeid', function () {
      return this.get('producttype.producttypeid') === this.simtex.PRODUCTTYPE.SIP_TRUNK;
    }),
    is_cloud_pbx: (0, _object.computed)('producttype.producttypeid', function () {
      return this.get('producttype.producttypeid') === this.simtex.PRODUCTTYPE.CLOUD_PBX;
    }),
    is_primary_inbound: (0, _object.computed)('producttype.producttypeid', function () {
      return this.get('producttype.producttypeid') === this.simtex.PRODUCTTYPE.PRIMARY_INBOUND;
    }),
    is_teams_direct_route: (0, _object.computed)('producttype.producttypeid', function () {
      return this.get('producttype.producttypeid') === this.simtex.PRODUCTTYPE.TEAMS_DIRECT_ROUTE;
    })
  });
  _exports.default = _default;
});