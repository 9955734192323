define("simtex-myaccount/templates/components/numbers/dids/did-clidstampingtarget", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="card simtex-card">
      <div class="card-header">
          Caller ID Over-stamping Target
      </div>
      <div class="card-block">
          <div class="card-text">
              The following number(s) have their Caller ID over-stamped as {{@model.did}}<br>
              <ul class="list-unstyled">
                  {{#each @model.targetclidstampphone_did as |did| }}
                      <li>
                          <Numbers::Dids::DidLink @owner={{@model.owner}} @linked_did={{did.did}}
                              @display_did={{did.did}} />
                      </li>
                  {{/each}}
              </ul>
          </div>
      </div>
  </div>
  */
  {
    "id": "pTEyiGKD",
    "block": "[[[10,0],[14,0,\"card simtex-card\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n        Caller ID Over-stamping Target\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"card-block\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"card-text\"],[12],[1,\"\\n            The following number(s) have their Caller ID over-stamped as \"],[1,[30,1,[\"did\"]]],[10,\"br\"],[12],[13],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"list-unstyled\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"targetclidstampphone_did\"]]],null]],null],null,[[[1,\"                    \"],[10,\"li\"],[12],[1,\"\\n                        \"],[8,[39,2],null,[[\"@owner\",\"@linked_did\",\"@display_did\"],[[30,1,[\"owner\"]],[30,2,[\"did\"]],[30,2,[\"did\"]]]],null],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[2]],null],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@model\",\"did\"],false,[\"each\",\"-track-array\",\"numbers/dids/did-link\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/dids/did-clidstampingtarget.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});