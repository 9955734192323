define("simtex-myaccount/templates/components/accounts/account-table-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.row.smile_data}}
  <button class="btn btn-link btn-primary btn-simtex-table-action" title="View Subscriptions"
      {{action this.tableActions.listSubscriptions this.row bubbles=false}}>
      <FaIcon @icon='list' /> Subscriptions
  </button>
  <button class="btn btn-link btn-default btn-simtex-table-action" title="View Numbers"
      {{action this.tableActions.listDIDs this.row bubbles=false}}>
      <FaIcon @icon='hashtag' /> Numbers
  </button>
  <button class="btn btn-link btn-default btn-simtex-table-action" title="View Handsets & Devices"
      {{action this.tableActions.listHandsets this.row bubbles=false}}>
      <FaIcon @icon='mobile' /> Devices
  </button>
  {{!Remove for now, add post go-live}}
      {{#if false}}
  <button class="btn btn-link btn-default btn-simtex-table-action" title="Edit Account Details"
      {{action this.tableActions.editAccount this.row bubbles=false}}>
      <FaIcon @icon='edit' /> Edit Acct
  </button>
      {{/if}}
  {{/if}}
  */
  {
    "id": "H5kMXGDj",
    "block": "[[[41,[30,0,[\"row\",\"smile_data\"]],[[[11,\"button\"],[24,0,\"btn btn-link btn-primary btn-simtex-table-action\"],[24,\"title\",\"View Subscriptions\"],[4,[38,1],[[30,0],[30,0,[\"tableActions\",\"listSubscriptions\"]],[30,0,[\"row\"]]],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[\"list\"]],null],[1,\" Subscriptions\\n\"],[13],[1,\"\\n\"],[11,\"button\"],[24,0,\"btn btn-link btn-default btn-simtex-table-action\"],[24,\"title\",\"View Numbers\"],[4,[38,1],[[30,0],[30,0,[\"tableActions\",\"listDIDs\"]],[30,0,[\"row\"]]],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[\"hashtag\"]],null],[1,\" Numbers\\n\"],[13],[1,\"\\n\"],[11,\"button\"],[24,0,\"btn btn-link btn-default btn-simtex-table-action\"],[24,\"title\",\"View Handsets & Devices\"],[4,[38,1],[[30,0],[30,0,[\"tableActions\",\"listHandsets\"]],[30,0,[\"row\"]]],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[\"mobile\"]],null],[1,\" Devices\\n\"],[13],[1,\"\\n\"],[41,false,[[[11,\"button\"],[24,0,\"btn btn-link btn-default btn-simtex-table-action\"],[24,\"title\",\"Edit Account Details\"],[4,[38,1],[[30,0],[30,0,[\"tableActions\",\"editAccount\"]],[30,0,[\"row\"]]],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[\"edit\"]],null],[1,\" Edit Acct\\n\"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"action\",\"fa-icon\"]]",
    "moduleName": "simtex-myaccount/templates/components/accounts/account-table-actions.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});