define("simtex-myaccount/templates/components/subscriptions/routing-data", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="card">
      <div class="card-header">
          Routing
      </div>
      <div class="card-block">
          <div class="card-text">
              <form class="clr-form clr-form-horizontal">
                  <div class="clr-form-control">
                      <label class="clr-control-label simtex-label-tips" for='routing_didroute'>
                          {{label-tips componentid='routing_didroute'}}
                      </label>
                      <div class="clr-input-wrapper">
                          {{#if (or this.didroute_choices_arr.isPending this.didroute_choices_arr.isUpdating) }}
                              Loading...
                          {{else}}
                              {{element-select
                                      content=this.didroute_choices_arr.content
                                      selection=this.model.didrouteid
                                      disabled=this.simtex.is_enduser
                                      name="didroute"
                                      optionLabelPath="label"
                                      optionValuePath="value"
                                      prompt="-- Select Routing --"
                                      class="clr-select-wrapper" }}
                          {{/if}}
                      </div>
                  </div>
              </form>
          </div>
      </div>
      {{#if (is-equal this.model.didrouteidDirty true)}}
          {{card-data-footer model=this.model object_name=this.object_name}}
      {{else}}
          {{card-data-footer model=this.model object_name=this.object_name formTouched=false}}
      {{/if}}
  
  </div>
  */
  {
    "id": "NL+WgbWP",
    "block": "[[[10,0],[14,0,\"card\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n        Routing\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"card-block\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"card-text\"],[12],[1,\"\\n            \"],[10,\"form\"],[14,0,\"clr-form clr-form-horizontal\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"clr-form-control\"],[12],[1,\"\\n                    \"],[10,\"label\"],[14,0,\"clr-control-label simtex-label-tips\"],[14,\"for\",\"routing_didroute\"],[12],[1,\"\\n                        \"],[1,[28,[35,0],null,[[\"componentid\"],[\"routing_didroute\"]]]],[1,\"\\n                    \"],[13],[1,\"\\n                    \"],[10,0],[14,0,\"clr-input-wrapper\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"didroute_choices_arr\",\"isPending\"]],[30,0,[\"didroute_choices_arr\",\"isUpdating\"]]],null],[[[1,\"                            Loading...\\n\"]],[]],[[[1,\"                            \"],[1,[28,[35,3],null,[[\"content\",\"selection\",\"disabled\",\"name\",\"optionLabelPath\",\"optionValuePath\",\"prompt\",\"class\"],[[30,0,[\"didroute_choices_arr\",\"content\"]],[30,0,[\"model\",\"didrouteid\"]],[30,0,[\"simtex\",\"is_enduser\"]],\"didroute\",\"label\",\"value\",\"-- Select Routing --\",\"clr-select-wrapper\"]]]],[1,\"\\n\"]],[]]],[1,\"                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"model\",\"didrouteidDirty\"]],true],null],[[[1,\"        \"],[1,[28,[35,5],null,[[\"model\",\"object_name\"],[[30,0,[\"model\"]],[30,0,[\"object_name\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,5],null,[[\"model\",\"object_name\",\"formTouched\"],[[30,0,[\"model\"]],[30,0,[\"object_name\"]],false]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[13]],[],false,[\"label-tips\",\"if\",\"or\",\"element-select\",\"is-equal\",\"card-data-footer\"]]",
    "moduleName": "simtex-myaccount/templates/components/subscriptions/routing-data.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});