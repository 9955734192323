define("simtex-myaccount/mixins/model-saver-common", ["exports", "@ember/object/mixin", "jquery", "@ember/service", "@ember/object", "@ember/template"], function (_exports, _mixin, _jquery, _service, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = _mixin.default.create({
    toast: (0, _service.inject)(),
    object_name: "Object",
    displayErrors: function displayErrors(errors) {
      var error_message = "Error saving " + this.object_name + "<br/>";
      if (errors !== undefined) {
        errors.forEach(function (error) {
          error_message = error_message + error.detail + '<br/>';
        });
      }
      return (0, _template.htmlSafe)(error_message);
    },
    actions: {
      save_nested: function save_nested(nested_obj) {
        var refresh_table = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var deleting_model = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var simtex_property = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
        var self = this;
        self.refresh_table = refresh_table;

        // Toggle off any passwords
        var arrBtn = (0, _jquery.default)('button.btn-toggle-password');
        var _iterator = _createForOfIteratorHelper(arrBtn),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var btn = _step.value;
            if ((0, _jquery.default)(btn).text() === 'Hide') {
              (0, _jquery.default)(btn).click();
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        if (nested_obj.promise) {
          return nested_obj.then(function (result) {
            if (deleting_model) {
              return result.destroyRecord().then(function (record) {
                self.store.unloadRecord(record);
                self.toast.success("Removed " + self.object_name);
                self.send('handleWizardUpdate', self.refresh_table, simtex_property);
              }).catch(function (adapterError) {
                self.toast.error(self.displayErrors(adapterError.errors));
              });
            }
            return result.save().then(function (d) {
              self.toast.success("Saved " + self.object_name);
              self.send('handleWizardUpdate', self.refresh_table, simtex_property);
            }).catch(function (adapterError) {
              self.toast.error(self.displayErrors(adapterError.errors));
            });
          });
        } else {
          if (deleting_model) {
            return nested_obj.destroyRecord().then(function () {
              self.store.unloadRecord(record);
              self.toast.success("Removed " + self.object_name);
              self.send('handleWizardUpdate', self.refresh_table, simtex_property);
            }).catch(function (adapterError) {
              self.toast.error(self.displayErrors(adapterError.errors));
            });
          }
          return nested_obj.save().then(function (record) {
            self.toast.success("Saved " + self.object_name);
            self.send('handleWizardUpdate', self.refresh_table, simtex_property);
            if (self.model !== undefined) {
              if (!self.model.isNew) {
                self.model.reload();
              }
            }
          }).catch(function (adapterError) {
            self.toast.error(self.displayErrors(adapterError.errors));
          });
        }
      },
      rollback: function rollback(nested_obj) {
        var _this = this;
        if (nested_obj.promise) {
          nested_obj.then(function (result) {
            try {
              result.rollbackAttributes();
              result.rollback();
            } catch (e) {}
            if (_this.get('object_name') === 'Dial Restrictions') {
              for (var i = 0, lists = [{
                  'getter': 'prefixblacklist',
                  'setter': 'get_prefixblacklist'
                }, {
                  'getter': 'prefixwhitelist',
                  'setter': 'get_prefixwhitelist'
                }]; i < lists.length; i++) {
                var prefixlist = result[lists[i].getter];
                var get_prefixlist = Ember.Object.create({
                  "prefixes": Ember.A([])
                });
                if (prefixlist) for (var _i = 0, list = prefixlist.split('|'), len = list.length; _i < len; _i++) {
                  get_prefixlist.prefixes.pushObject(list[_i]);
                }
                _this.set(lists[i].setter, get_prefixlist);
              }
            }
          });
        } else {
          try {
            nested_obj.rollbackAttributes();
            nested_obj.rollback();
          } catch (e) {}
        }
      },
      handleWizardUpdate: function handleWizardUpdate() {
        var refresh_table = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var simtex_property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        if (refresh_table) {
          // Any present TableCommon instance will be listening to changes to this field.
          if (this.get('simtex.refresh_table') === undefined) {
            this.set('simtex.refresh_table', 0);
          } else {
            this.incrementProperty('simtex.refresh_table');
          }
        } else {
          // Not refreshing whole table, check if there is a 'local' table/row/column defined
          if (this.column && this.column.updateWizard && this.table && this.table.columns) {
            if (this.table.columns.find(function (column) {
              return column.hasWizard;
            })) {
              if (this.get('row.updateWizard') === undefined) {
                this.set('row.updateWizard', 0);
              } else {
                this.incrementProperty('row.updateWizard');
              }
            }
          }
        }
        if (this.get('simtex.update_buckets') === undefined) {
          this.set('simtex.update_buckets', 0);
        } else {
          this.incrementProperty('simtex.update_buckets');
        }
        if (simtex_property !== '') {
          if (this.get(simtex_property) === undefined) {
            this.set(simtex_property, 0);
          } else {
            this.incrementProperty(simtex_property);
          }
        }
        if (this.get('simtex.refresh_model') === undefined) {
          this.set('simtex.refresh_model', 0);
        } else {
          this.incrementProperty('simtex.refresh_model');
        }
      }
    }
  });
  _exports.default = _default;
});