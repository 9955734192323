define("simtex-myaccount/components/subscriptions/subscription/handset-expansion-data", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/object", "@ember/template"], function (_exports, _component, _tableCommon, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, {
    modelName: 'endpointexpansion',
    modelId: 0,
    endpointaccount_data: null,
    sort: 'index',
    togglingAddExpansion: false,
    togglingEditExpansion: false,
    addModalWorking: false,
    removeModalWorking: false,
    togglingRemoveExpansion: false,
    // Placeholder for adding a new expansion
    new_expansion: {
      endpointexpansiontypeid: null
    },
    // Placeholders for editing/removing an existing expansion
    edit_expansion: null,
    remove_expansion: null,
    init: function init() {
      this.set('filterList', ['endpoint__endpointid']);
      this.set('endpoint__endpointid', this.get('model.endpoint.id'));

      // Free up model for use by TableCommon
      this.set('endpointaccount_data', this.get('model'));
      this.set('model', []);
      this._super.apply(this, arguments);
      this.send('setPage', 1);
    },
    resetNewExpansion: function resetNewExpansion() {
      this.set('new_expansion', {
        endpointexpansiontypeid: null
      });
    },
    addExpansionInvalid: (0, _object.computed)('new_expansion.endpointexpansiontypeid', function () {
      return this.get('new_expansion.endpointexpansiontypeid') === null;
    }),
    columns: (0, _object.computed)(function () {
      return [{
        label: 'Info',
        width: '150px',
        sortable: false,
        cellComponent: 'subscriptions/subscription/handset-expansion-table-info'
      }, {
        label: 'Expansion Model',
        width: '200px',
        valuePath: 'endpointexpansiontype.formatted_brand_model',
        sortable: true,
        format: function format(result) {
          return (0, _template.htmlSafe)(result + ' <small>(id:' + this.row.get('endpointexpansionid') + ')</small>');
        }
      }, {
        label: 'Actions',
        sortable: false,
        cellComponent: 'subscriptions/subscription/handset-expansion-table-actions'
      }];
    }),
    actions: {
      toggleAddExpansion: function toggleAddExpansion() {
        var togglingAddExpansion = this.get('togglingAddExpansion');
        if (!togglingAddExpansion) this.resetNewExpansion();
        this.toggleProperty('togglingAddExpansion');
      },
      addExpansion: function addExpansion(endpoint) {
        var _this = this;
        var expansion = this.store.createRecord('endpointexpansion', {
          endpointexpansiontype: endpoint.get('endpointmodel.endpointexpansion_choices').content.find(function (eet) {
            return eet.endpointexpansiontypeid === _this.get('new_expansion.endpointexpansiontypeid');
          }),
          endpoint: endpoint
        });
        var self = this;
        expansion.save().then(function (result) {
          self.toast.success(self.simtex.STRINGS.ADDED_EXPANSION);
          self.incrementProperty('simtex.refresh_table');
          self.send('toggleEditExpansion', result);
        }).catch(function (e) {
          self.toast.error(self.simtex.STRINGS.ADDED_EXPANSION_ERROR);
          self.send('toggleEditExpansion');
        }).finally(function () {
          self.set('addModalWorking', false);
          self.send('toggleAddExpansion');
        });
      },
      toggleEditExpansion: function toggleEditExpansion(row) {
        this.set('edit_expansion', row);
        this.set('modelId', row ? row.get('endpointexpansionid') : 0);
        this.set('togglingEditExpansion', this.get('modelId') !== 0);
      },
      toggleRemoveExpansion: function toggleRemoveExpansion() {
        var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        this.set('remove_expansion', row);
        this.set('togglingRemoveExpansion', row !== null);
      },
      removeExpansion: function removeExpansion() {
        this.set('removeModalWorking', true);
        var self = this;
        var expansion = this.get('remove_expansion');
        self.remove_expansionid = expansion.get('endpointexpansionid');
        expansion.deleteRecord();
        expansion.save().then(function () {
          self.toast.success(self.simtex.STRINGS.REMOVED_EXPANSION);
          // If we're editing the expansion being removed, stop trying to display it
          if (self.remove_expansionid === self.get('modelId')) self.send('toggleEditExpansion');
          self.incrementProperty('simtex.refresh_table');
        }).catch(function (e) {
          self.toast.error(self.simtex.STRINGS.REMOVED_EXPANSION_ERROR);
        }).finally(function () {
          self.set('removeModalWorking', false);
          self.set('remove_expansion', null);
          self.send('toggleRemoveExpansion');
        });
      }
    }
  });
  _exports.default = _default;
});