define("simtex-myaccount/templates/restricted/handsets", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  <nav class="sidenav">
      <section class="sidenav-content">
          <section class="nav-group">
              <label>Handsets and Devices</label>
              <ul class="nav-list">
                  <li><LinkTo @route='restricted.handsets.list' @model={{this.simtex.get_claims_account}} class="nav-link">Handset and Device List</LinkTo></li>
                  {{#unless this.simtex.is_enduser}}
                  <li><LinkTo @route='restricted.handsets.mapping' @model={{this.simtex.get_claims_account}} class="nav-link">Subscription Mapping</LinkTo></li>
                  {{/unless}}
              </ul>
          </section>
      </section>
  </nav>
  */
  {
    "id": "N9RJNtjz",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[10,\"nav\"],[14,0,\"sidenav\"],[12],[1,\"\\n    \"],[10,\"section\"],[14,0,\"sidenav-content\"],[12],[1,\"\\n        \"],[10,\"section\"],[14,0,\"nav-group\"],[12],[1,\"\\n            \"],[10,\"label\"],[12],[1,\"Handsets and Devices\"],[13],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"nav-list\"],[12],[1,\"\\n                \"],[10,\"li\"],[12],[8,[39,2],[[24,0,\"nav-link\"]],[[\"@route\",\"@model\"],[\"restricted.handsets.list\",[30,0,[\"simtex\",\"get_claims_account\"]]]],[[\"default\"],[[[[1,\"Handset and Device List\"]],[]]]]],[13],[1,\"\\n\"],[41,[51,[30,0,[\"simtex\",\"is_enduser\"]]],[[[1,\"                \"],[10,\"li\"],[12],[8,[39,2],[[24,0,\"nav-link\"]],[[\"@route\",\"@model\"],[\"restricted.handsets.mapping\",[30,0,[\"simtex\",\"get_claims_account\"]]]],[[\"default\"],[[[[1,\"Subscription Mapping\"]],[]]]]],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"link-to\",\"unless\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/handsets.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});