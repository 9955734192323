define("simtex-myaccount/templates/restricted/system/zerotouchjob", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title 'Devices (' this.simtex.owner_description ')'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top:0">ZeroTouch Job List</h2>
              {{system/zerotouchjob-table ownerFilterTerm=this.simtex.owner_search_term}}
          </div>
      </div>
  </div>
  */
  {
    "id": "hMRG9DJ6",
    "block": "[[[1,[28,[35,0],[\"Devices (\",[30,0,[\"simtex\",\"owner_description\"]],\")\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top:0\"],[12],[1,\"ZeroTouch Job List\"],[13],[1,\"\\n            \"],[1,[28,[35,1],null,[[\"ownerFilterTerm\"],[[30,0,[\"simtex\",\"owner_search_term\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"system/zerotouchjob-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/system/zerotouchjob.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});