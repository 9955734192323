define("simtex-myaccount/templates/components/callrouting/ringgroup-table-action", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (or this.row.isPending (or this.row.content.accounts.isPending this.row.content.account.isPending))}}
      <div class="spinner spinner-sm">Loading...</div>
  {{else}}
      <div style="display: inline;">
          <div style="display: inline-block;">
              {{callrouting/ringgroup-dialog
                      owner=this.row.content.owner  modalMode='Edit' modelId=this.row.content.broadcastgroupid}}
          </div>
          {{#if (or
                      (is-equal this.row.content.maxsubgroupindex this.row.content.subgroupindex)
                      (gt this.row.content.subgroupindex this.row.content.maxsubgroupindex)
                )}}
              <div style="display: inline-block;">
                  {{callrouting/ringgroup-dialog
                          owner=this.row.content.owner  modalMode='Delete' modelId=this.row.content.broadcastgroupid}}
              </div>
          {{/if}}
      </div>
  {{/if}}
  */
  {
    "id": "POKlOfhx",
    "block": "[[[41,[28,[37,1],[[30,0,[\"row\",\"isPending\"]],[28,[37,1],[[30,0,[\"row\",\"content\",\"accounts\",\"isPending\"]],[30,0,[\"row\",\"content\",\"account\",\"isPending\"]]],null]],null],[[[1,\"    \"],[10,0],[14,0,\"spinner spinner-sm\"],[12],[1,\"Loading...\"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,5,\"display: inline;\"],[12],[1,\"\\n        \"],[10,0],[14,5,\"display: inline-block;\"],[12],[1,\"\\n            \"],[1,[28,[35,2],null,[[\"owner\",\"modalMode\",\"modelId\"],[[30,0,[\"row\",\"content\",\"owner\"]],\"Edit\",[30,0,[\"row\",\"content\",\"broadcastgroupid\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[28,[37,1],[[28,[37,3],[[30,0,[\"row\",\"content\",\"maxsubgroupindex\"]],[30,0,[\"row\",\"content\",\"subgroupindex\"]]],null],[28,[37,4],[[30,0,[\"row\",\"content\",\"subgroupindex\"]],[30,0,[\"row\",\"content\",\"maxsubgroupindex\"]]],null]],null],[[[1,\"            \"],[10,0],[14,5,\"display: inline-block;\"],[12],[1,\"\\n                \"],[1,[28,[35,2],null,[[\"owner\",\"modalMode\",\"modelId\"],[[30,0,[\"row\",\"content\",\"owner\"]],\"Delete\",[30,0,[\"row\",\"content\",\"broadcastgroupid\"]]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"or\",\"callrouting/ringgroup-dialog\",\"is-equal\",\"gt\"]]",
    "moduleName": "simtex-myaccount/templates/components/callrouting/ringgroup-table-action.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});