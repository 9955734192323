define("simtex-myaccount/templates/restricted/numbers/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  {{page-title 'Numbers Admin'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top:0">Direct In Dials (DIDs) for {{get-simtex-string 'LIST_ACCT_CONTEXT'}}</h2>
              <div>
                  <div class="btn-group btn-primary btn-icon">
                      {{numbers/dids/did-clr-wizard
                      model=this.model
                      wizardMode='new'}}
                      {{numbers/dids/did-assignment-dialog modalMode='Assign' modalTitle= 'Assign Numbers' modalIcon='assign-user'}}
                      {{numbers/dids/did-assignment-dialog modalMode='Deallocate' modalTitle= 'Bulk Deallocate' modalIcon='unlink'}}
                      {{numbers/dids/did-assignment-dialog modalMode='Quarantine' modalTitle= 'Bulk Quarantine' modalIcon='ban'}}
                  </div>
              </div>
              <div>
                  <small>{{get-simtex-string 'LIST_DESCRIPTION_DID'}} for {{get-simtex-string 'LIST_ACCT_CONTEXT'}}</small>
              </div>
              {{numbers/dids/did-table}}
          </div>
      </div>
  </div>
  */
  {
    "id": "tv+Hzmub",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[1,[28,[35,2],[\"Numbers Admin\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top:0\"],[12],[1,\"Direct In Dials (DIDs) for \"],[1,[28,[35,3],[\"LIST_ACCT_CONTEXT\"],null]],[13],[1,\"\\n            \"],[10,0],[12],[1,\"\\n                \"],[10,0],[14,0,\"btn-group btn-primary btn-icon\"],[12],[1,\"\\n                    \"],[1,[28,[35,4],null,[[\"model\",\"wizardMode\"],[[30,0,[\"model\"]],\"new\"]]]],[1,\"\\n                    \"],[1,[28,[35,5],null,[[\"modalMode\",\"modalTitle\",\"modalIcon\"],[\"Assign\",\"Assign Numbers\",\"assign-user\"]]]],[1,\"\\n                    \"],[1,[28,[35,5],null,[[\"modalMode\",\"modalTitle\",\"modalIcon\"],[\"Deallocate\",\"Bulk Deallocate\",\"unlink\"]]]],[1,\"\\n                    \"],[1,[28,[35,5],null,[[\"modalMode\",\"modalTitle\",\"modalIcon\"],[\"Quarantine\",\"Bulk Quarantine\",\"ban\"]]]],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[12],[1,\"\\n                \"],[10,\"small\"],[12],[1,[28,[35,3],[\"LIST_DESCRIPTION_DID\"],null]],[1,\" for \"],[1,[28,[35,3],[\"LIST_ACCT_CONTEXT\"],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[1,[34,6]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"page-title\",\"get-simtex-string\",\"numbers/dids/did-clr-wizard\",\"numbers/dids/did-assignment-dialog\",\"numbers/dids/did-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/numbers/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});