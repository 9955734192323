define("simtex-myaccount/templates/components/callrouting/callforwarding-table-action", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (or this.row.isPending (or this.row.content.accounts.isPending this.row.content.account.isPending))}}
      <div class="spinner spinner-sm">Loading...</div>
  {{else}}
      <LinkTo @route='restricted.subscriptions.subscription.callforwarding'
              @models={{array this.row.owner this.row.account}}
          class="btn btn-sm btn-link btn-simtex-table-action">
      <FaIcon @icon='edit' /> Edit
      </LinkTo>
  {{/if}}
  */
  {
    "id": "h/3MF/hd",
    "block": "[[[41,[28,[37,1],[[30,0,[\"row\",\"isPending\"]],[28,[37,1],[[30,0,[\"row\",\"content\",\"accounts\",\"isPending\"]],[30,0,[\"row\",\"content\",\"account\",\"isPending\"]]],null]],null],[[[1,\"    \"],[10,0],[14,0,\"spinner spinner-sm\"],[12],[1,\"Loading...\"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[24,0,\"btn btn-sm btn-link btn-simtex-table-action\"]],[[\"@route\",\"@models\"],[\"restricted.subscriptions.subscription.callforwarding\",[28,[37,3],[[30,0,[\"row\",\"owner\"]],[30,0,[\"row\",\"account\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@icon\"],[\"edit\"]],null],[1,\" Edit\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"or\",\"link-to\",\"array\",\"fa-icon\"]]",
    "moduleName": "simtex-myaccount/templates/components/callrouting/callforwarding-table-action.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});