define("simtex-myaccount/models/endpointlinekeytype", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // endpointlinekeytypeid: DS.attr(),
    // name: DS.attr('string'),
    // isactive: DS.attr('boolean'),
    // hasdialstring: DS.attr('boolean'),
    // hasaccountlink: DS.attr('boolean'),
    // hasuserlabel: DS.attr('boolean'),
    // isline: DS.attr('boolean'),
  });
  _exports.default = _default;
});