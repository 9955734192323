define("simtex-myaccount/components/subscriptions/fax-data", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common"], function (_exports, _component, _modelSaverCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Fax Details'
  });
  _exports.default = _default;
});