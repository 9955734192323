define("simtex-myaccount/routes/authproceed", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    simtex: (0, _service.inject)('simtex'),
    router: (0, _service.inject)(),
    init: function init() {
      var _this = this;
      this.simtex.check_promise = this.simtex.complete_auth().then(function (result) {
        try {
          _this.get('__schema').init();
        } catch (e) {}
        _this.router.transitionTo('restricted.index');
      });
    }
  });
  _exports.default = _default;
});