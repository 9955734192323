define("simtex-myaccount/components/callrouting/ringgroup-member-buckets", ["exports", "@ember/component", "@ember/object", "ember-data", "@ember/service", "@ember/array"], function (_exports, _component, _object, _emberData, _service, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    selected_group: null,
    selected_group_obj: null,
    group_options: [],
    group_members_options: [],
    non_group_members_options: [],
    selected_group_members: [],
    selected_non_group_members: [],
    disable_allocate: (0, _object.computed)('bucket_data.content.@each.data', 'selected_group', 'selected_non_group_members', function () {
      if (this.selected_group == null) {
        return true;
      } else {
        return this.selected_non_group_members.length === 0;
      }
    }),
    disable_deallocate: (0, _object.computed)('bucket_data.content.@each.data', 'selected_group', 'selected_group_members', function () {
      if (this.selected_group == null) {
        return true;
      } else {
        return this.selected_group_members.length === 0;
      }
    }),
    disable_save: (0, _object.computed)('bucket_data.content.@each.data', function () {
      if (this.bucket_data.content) {
        var ad = this.bucket_data.get(this.ALL_MEMBERS_INDEX).data;
        var od = this.bucket_data.get(this.ORIGINAL_ALL_MEMBERS_INDEX).data;
        return JSON.stringify(ad) === JSON.stringify(od);
      } else {
        return true;
      }
    }),
    bucket_data: null,
    BROADCASTGROUP_INDEX: 0,
    ALLOCATED_MEMBERS_INDEX: 1,
    NON_MEMBERS_INDEX: 2,
    ALL_MEMBERS_INDEX: 3,
    ORIGINAL_ALL_MEMBERS_INDEX: 4,
    ALL_ACCOUNTS_INDEX: 5,
    init: function init() {
      this._super.apply(this, arguments);
      this.get_bucket_data();
    },
    update_bucket: (0, _object.observer)('simtex.update_ringgroup_buckets', function () {
      this.get_bucket_data();
    }),
    get_bucket_data: function get_bucket_data() {
      var _this = this;
      var self = this;
      var bucketPromise = Promise.all([_emberData.default.PromiseObject.create({
        promise: this.store.query('broadcastgroup', {
          'account__owner': this.owner,
          'account': this.primary_account ? this.primary_account : ''
        })
      }), _emberData.default.PromiseObject.create({
        promise: this.store.query('account', {
          'owner_filter_term': this.owner,
          'productid__producttype__producttypeid': 3,
          'page_size': 200,
          'remove_fields': ['did_data', 'channellimit_data', 'dialrestriction_data', 'transport_data', 'callcontrol_data', 'advancedforwardcontrol_data', 'endpoint_status_data', 'product_data', 'numberpresentation_data', 'voicemail_data', 'callaccounting_data']
        })
      }), _emberData.default.PromiseObject.create({
        promise: this.store.query('broadcastmember', {
          'owner_filter_term': this.owner,
          'page_size': 1000
        })
      })]).then(function (values) {
        var brocastgroups_values = values[0];
        var accounts_values = values[1];
        var brocastmembers_values = values[2];
        var broadcastgroups = (0, _array.A)([]),
          allocated_members = (0, _array.A)([]),
          non_members = (0, _array.A)([]),
          all_members = (0, _array.A)([]),
          all_accounts = (0, _array.A)([]);
        brocastgroups_values.forEach(function (group) {
          broadcastgroups.push({
            label: "".concat(group.account.get('internalextension'), " / ").concat(group.account.get('accountid'), " - ").concat(group.name, " - Subgroup ").concat(group.subgroupindex),
            value: group.broadcastgroupid,
            groupaccountid: group.account.get('accountid'),
            groupid: group.broadcastgroupid
          });
        });
        brocastmembers_values.forEach(function (member) {
          all_members.push({
            label: "".concat(member.account.get('internalextension'), " - ").concat(member.account.get('label')),
            value: member.broadcastmemberid || member.account.get('accountid'),
            groupid: member.broadcastgroupid,
            groupaccountid: member.broadcastgroupaccount ? member.broadcastgroupaccount.get('accountid') : null,
            accountid: member.account.get('accountid'),
            broadcastmemberid: member.broadcastmemberid
          });
        });
        accounts_values.forEach(function (account) {
          non_members.push({
            label: "".concat(account.internalextension, " - ").concat(account.label),
            value: account.accountid,
            groupid: null,
            groupaccountid: null,
            accountid: account.accountid,
            broadcastmemberid: null
          });
          all_accounts.push(account);
        });
        return [_object.default.create({
          type: 'broadcastgroups',
          data: broadcastgroups
        }), _object.default.create({
          type: 'allocatedmembers',
          data: allocated_members
        }), _object.default.create({
          type: 'nonmembers',
          data: non_members
        }), _object.default.create({
          type: 'allmembers',
          data: all_members
        }),
        // this includes all allocated/unallocated members or members have been allocated to multiple groups
        _object.default.create({
          type: 'originalallmembers',
          data: JSON.parse(JSON.stringify(all_members))
        }), _object.default.create({
          type: 'accounts',
          data: all_accounts
        }) //source of truth - this array should never be updated
        ];
      });

      var set_data_promise = _emberData.default.PromiseObject.create({
        promise: this.set('bucket_data', _emberData.default.PromiseObject.create({
          promise: bucketPromise
        }))
      });
      set_data_promise.then(function () {
        return _this.refresh_buckets();
      });
    },
    refresh_allocated: function refresh_allocated() {
      var _this2 = this;
      if (!this.selected_group) {
        this.bucket_data.content[this.ALLOCATED_MEMBERS_INDEX].set('data', []);
      } else {
        this.bucket_data.content[this.ALLOCATED_MEMBERS_INDEX].set('data', this.bucket_data.content[this.ALL_MEMBERS_INDEX].get('data').filter(function (item) {
          return item.groupid == _this2.selected_group;
        }));
      }
    },
    refresh_unallocated: function refresh_unallocated() {
      var _this3 = this;
      var group = this.bucket_data.content[this.BROADCASTGROUP_INDEX].get('data').find(function (item) {
        return item.groupid == _this3.selected_group;
      });
      //Get all members that have been allocated to group account id
      if (group) {
        var allocated_members = [];
        this.bucket_data.content[this.ALL_MEMBERS_INDEX].get('data').filter(function (item) {
          return item.groupaccountid == group.groupaccountid;
        }).forEach(function (member) {
          allocated_members.push(member.accountid);
        });
        //Get all members/accounts excluding the ones that have been allocated this group account id
        var all_non_member_accounts = this.bucket_data.content[this.ALL_ACCOUNTS_INDEX].get('data').filter(function (item) {
          return allocated_members.indexOf(item.accountid) < 0;
        });
        var non_members = [];
        all_non_member_accounts.forEach(function (account) {
          non_members.push({
            label: "".concat(account.internalextension, " - ").concat(account.label),
            value: account.accountid,
            groupid: null,
            groupaccountid: null,
            accountid: account.accountid,
            broadcastmemberid: null
          });
        });
        this.bucket_data.content[this.NON_MEMBERS_INDEX].set('data', non_members);
      }
    },
    update_allocation_data: function update_allocation_data(allocations, groupid, groupaccountid) {
      var all_members = this.bucket_data.content[this.ALL_MEMBERS_INDEX].get('data');
      var original_all_members = this.bucket_data.content[this.ORIGINAL_ALL_MEMBERS_INDEX].get('data');
      var non_members = this.bucket_data.content[this.NON_MEMBERS_INDEX].get('data');
      allocations.forEach(function (allocation) {
        if (groupid == null && groupaccountid == null)
          //Removing members
          {
            //Find the member in all_members - value = broadcastmemberid
            var member_to_delete_index = all_members.findIndex(function (item) {
              return item.value == allocation;
            });

            //Member doesn't exist in the backend yet
            if (all_members[member_to_delete_index].broadcastmemberid == null) {
              all_members.splice(member_to_delete_index, 1);
            } else {
              //Set groupid and groupaccountid to null
              all_members[member_to_delete_index]['groupid'] = groupid;
              all_members[member_to_delete_index]['groupaccountid'] = groupaccountid;
            }
          } else {
          //Adding members
          //Check if member already exists
          var original_member = original_all_members.find(function (item) {
            return item.groupid == groupid && item.accountid == allocation;
          });
          if (original_member) {
            //Find current member in all members
            var current_member_index = all_members.findIndex(function (item) {
              return item.broadcastmemberid == original_member.broadcastmemberid;
            });
            all_members[current_member_index] = JSON.parse(JSON.stringify(original_member)); //Deep copy the original member back to all members
          } else {
            var non_member_idx = non_members.findIndex(function (item) {
              return item.value == allocation;
            });
            non_members[non_member_idx]['groupid'] = groupid;
            non_members[non_member_idx]['groupaccountid'] = groupaccountid;
            all_members.push(non_members[non_member_idx]);
          }
        }
      });
      this.bucket_data.content[this.ALL_MEMBERS_INDEX].set('data', all_members);
      this.refresh_buckets();
    },
    reset_selection: function reset_selection() {
      var _this4 = this;
      this.set('selected_group_members', []);
      this.set('selected_non_group_members', []);
      if (this.bucket_data) this.set('selected_group_obj', this.bucket_data.content[this.BROADCASTGROUP_INDEX].get('data').find(function (item) {
        return item.groupid == _this4.selected_group;
      }));else this.set('selected_group_obj', []);
    },
    refresh_buckets: function refresh_buckets() {
      this.reset_selection();
      this.refresh_allocated();
      this.refresh_unallocated();
    },
    actions: {
      groupDidChange: function groupDidChange() {
        this.refresh_buckets();
      },
      allocate: function allocate() {
        this.update_allocation_data(this.selected_non_group_members, this.selected_group_obj.groupid, this.selected_group_obj.groupaccountid);
      },
      deallocate: function deallocate() {
        this.update_allocation_data(this.selected_group_members, null, null);
      },
      undo: function undo() {
        // Clone _original_allocation_data to allocation_data and refresh buckets.
        var original_all_members_copy = JSON.parse(JSON.stringify(this.bucket_data.content[this.ORIGINAL_ALL_MEMBERS_INDEX].get('data')));
        this.bucket_data.content[this.ALL_MEMBERS_INDEX].set('data', original_all_members_copy);
        this.refresh_buckets();
      },
      save: function save() {
        var _this5 = this;
        var self = this;
        var all_members = this.bucket_data.content[this.ALL_MEMBERS_INDEX].get('data');
        var all_accounts = this.bucket_data.content[this.ALL_ACCOUNTS_INDEX].get('data');
        var allocationSavePromiseArray = [];
        all_members.forEach(function (member) {
          //member doesn't exist yet
          if (member.broadcastmemberid == null && member.groupid != null) {
            var create_member = self.store.createRecord('broadcastmember', {
              broadcastgroupid: member.groupid,
              account: all_accounts.find(function (item) {
                return item.accountid == member.accountid;
              })
            });
            allocationSavePromiseArray.push(_emberData.default.PromiseObject.create({
              promise: create_member.save()
            }));
          }
        });
        all_members.forEach(function (member) {
          //Find members that have a broadcastmemberid but groupid is null
          // then it means the user intended to remove this member from the group
          if (member.broadcastmemberid !== null && member.groupid == null) {
            var delete_member = self.store.peekRecord('broadcastmember', member.broadcastmemberid);
            allocationSavePromiseArray.push(_emberData.default.PromiseObject.create({
              promise: delete_member.destroyRecord()
            }));
          }
        });
        return Promise.all(allocationSavePromiseArray).then(function (results) {
          var result_tests = results;
          self.toast.success("Saved members successfully");
          var all_members_copy = JSON.parse(JSON.stringify(self.bucket_data.content[_this5.ALL_MEMBERS_INDEX].get('data')));
          self.bucket_data.content[_this5.ORIGINAL_ALL_MEMBERS_INDEX].set('data', all_members_copy);
          self.get_bucket_data();
          //refresh the ring group table
          if (_this5.get('simtex.refresh_table') === undefined) {
            _this5.set('simtex.refresh_table', 0);
          } else {
            _this5.incrementProperty('simtex.refresh_table');
          }
        }, function (err) {
          self.toast.error("Failed to save members");
        });
      }
    }
  });
  _exports.default = _default;
});