define("simtex-myaccount/components/numbers/sites/site-table", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/object", "@ember/service", "@ember/template"], function (_exports, _component, _tableCommon, _object, _service, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, {
    __schema: (0, _service.inject)('schema'),
    modelName: 'ipndsite',
    sort: 'label',
    init: function init() {
      this._super.apply(this, arguments);
      this.send('setPage', 1);
    },
    columns: (0, _object.computed)(function () {
      return [{
        label: 'Actions',
        width: '150px',
        sortable: false,
        cellComponent: 'numbers/sites/site-clr-wizard',
        hasWizard: true,
        wizardMode: 'edit',
        updateWizard: true
      }, {
        label: 'Site Label',
        sortable: true,
        valuePath: 'label',
        valueEditable: true,
        cellClassNames: 'align-middle',
        cellComponent: 'light-table-cell',
        width: '200px',
        updateWizard: true
      }, {
        label: 'IPND Customer',
        sortable: true,
        valuePath: 'customername1',
        valueEditable: true,
        cellClassNames: 'align-middle',
        cellComponent: 'light-table-cell',
        width: '200px',
        updateWizard: true
      }, {
        label: 'IPND Service Location',
        sortable: true,
        cellClassNames: 'align-middle single-line',
        classNames: 'single-line',
        cellComponent: 'numbers/sites/site-summary'
      }, {
        label: 'List Code',
        sortable: true,
        cellClassNames: 'align-middle',
        valuePath: 'listcode',
        format: function format(value) {
          return this.simtex.resolveEnum(['ipndsite', 'listcode', value], true);
        }
      }, {
        label: 'Usage Code',
        sortable: true,
        cellClassNames: 'align-middle',
        valuePath: 'usagecode',
        format: function format(value) {
          return this.simtex.resolveEnum(['ipndsite', 'usagecode', value], true);
        }
      }];
    }),
    actions: {
      editSite: function editSite(row) {
        if (row.content) this.router.transitionTo('restricted.numbers.sites.site', row.content.owner, row.content.id);else this.router.transitionTo('restricted.numbers.sites.site', row.owner, row.id);
      }
    }
  });
  _exports.default = _default;
});