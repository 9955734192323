define("simtex-myaccount/components/numbers/dids/didhistory-actions", ["exports", "@ember/component", "@ember/service", "simtex-myaccount/mixins/model-saver-common", "@ember/object"], function (_exports, _component, _service, _modelSaverCommon, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    store: (0, _service.inject)(),
    simtex: (0, _service.inject)(),
    modalShowing: false,
    promptOnClose: true,
    modalCancelling: false,
    notes: null,
    quarantineDisabled: (0, _object.computed)(function () {
      if (this.model.didstate == 12) return true;
      return false;
    }),
    deallocateDisabled: (0, _object.computed)(function () {
      if (!this.model.owner) return true;
      return false;
    }),
    quarantineDid: function quarantineDid(historynotes) {
      var _this = this;
      var json_obj = new Object();
      var self = this;
      json_obj['Owner'] = this.model.owner;
      if (this.model.didstate != 12) {
        this.store.findRecord('did', this.model.did).then(function (did) {
          did.didstate = 12; //12 - Quarantined
          did.label = null;
          did.account = null;
          did.jsondata = JSON.stringify(json_obj);
          did.historynotes = historynotes;
          return _this.send('save_nested', did, true);
        }).then(function () {
          self.set('quarantineDisabled', true);
        });
      }
    },
    deallocateDid: function deallocateDid(historynotes) {
      var _this2 = this;
      var json_obj = new Object();
      var self = this;
      json_obj['PreviousOwner'] = this.model.owner;
      this.store.findRecord('did', this.model.did).then(function (did) {
        did.didstate = 2; //2 - Deallocated from Owner
        did.owner = null;
        did.label = null;
        did.account = null;
        did.jsondata = JSON.stringify(json_obj);
        did.historynotes = historynotes;
        return _this2.send('save_nested', did, true);
      }).then(function () {
        self.set('deallocateDisabled', true);
      });
    },
    addTranslation: function addTranslation(historynotes) {
      var self = this;
      if (this.selected_number === undefined) {
        self.toast.error("You need to select a valid target DID");
        return false;
      }
      var json_obj = {};
      var selected_phoneid = this.selected_number.get('phoneid');
      json_obj['PreviousTranslatedPhoneId'] = this.model.get('translatedphoneid');
      json_obj['CurrentTranslatedPhoneId'] = selected_phoneid;
      json_obj['TranslatedPhoneIdUpdatedBy'] = this.simtex.username;
      this.store.findRecord('did', this.model.get('did')).then(function (did) {
        did.translatedphoneid = selected_phoneid;
        did.jsondata = JSON.stringify(json_obj);
        did.historynotes = historynotes;
        did.save().then(function () {
          self.toast.success("Target DID Updated");
        });
      });
    },
    removeTranslation: function removeTranslation(historynotes) {
      var json_obj = new Object();
      var self = this;
      json_obj['PreviousTranslatedPhoneId'] = this.model.get('translatedphoneid');
      json_obj['TranslatedPhoneIdRemovedBy'] = this.simtex.username;
      this.store.findRecord('did', this.model.get('did')).then(function (did) {
        did.translatedphoneid = null;
        did.jsondata = JSON.stringify(json_obj);
        did.historynotes = historynotes;
        did.save().then(function () {
          self.toast.success("Target DID Deleted");
        });
      });
    },
    removeOverStamping: function removeOverStamping(historynotes) {
      var json_obj = new Object();
      var self = this;
      json_obj['PreviousOverStampedPhoneId'] = this.model.get('clidstampphoneid');
      json_obj['OverStampedPhoneIdRemovedBy'] = this.simtex.username;
      this.store.findRecord('did', this.model.get('did')).then(function (did) {
        did.clidstampphone = null;
        did.jsondata = JSON.stringify(json_obj);
        did.historynotes = historynotes;
        did.save().then(function () {
          self.toast.success("Over-Stamped DID Deleted");
        });
      });
    },
    rollbackChanges: function rollbackChanges() {
      // Roll back changes to the model.
      if (this.get('model') !== null) {
        this.send('rollback', this.get('model'));
      }
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('modalShowing');
      },
      cancellingModal: function cancellingModal() {
        this.toggleProperty('modalCancelling');
        if (!this.promptOnClose) {
          this.toggleProperty('modalShowing');
          this.rollbackChanges();
        }
      },
      cancelModal: function cancelModal() {
        this.toggleProperty('modalCancelling');
        this.toggleProperty('modalShowing');
        this.rollbackChanges();
      },
      completeModal: function completeModal() {
        this.toggleProperty('modalShowing');
        if (this.parentModalAction) {
          this.parentModalAction();
        }
        if (this.modalMode === 'Quarantine') {
          this.quarantineDid(this.notes);
        } else if (this.modalMode === 'Deallocate') {
          this.deallocateDid(this.notes);
        } else if (this.modalMode === 'Add Translation') {
          this.addTranslation(this.notes);
        } else if (this.modalMode === 'Remove Translation') {
          this.removeTranslation(this.notes);
        } else if (this.modalMode === 'Remove Over-Stamping') {
          this.removeOverStamping(this.notes);
        }
      }
    }
  });
  _exports.default = _default;
});