define("simtex-myaccount/models/callforwardreport", ["exports", "ember-data", "@ember/object"], function (_exports, _emberData, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    callforwardid: _emberData.default.attr(),
    owner: _emberData.default.attr(),
    account: _emberData.default.attr(),
    internalextension: _emberData.default.attr(),
    divtype: _emberData.default.attr('string'),
    divtypelabel: _emberData.default.attr('string'),
    number: _emberData.default.attr('')
  });
  _exports.default = _default;
});