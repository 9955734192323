define("simtex-myaccount/models/callaccounting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    creditlimit: _emberData.default.attr(),
    credit: _emberData.default.attr(),
    enable: _emberData.default.attr('boolean'),
    account_id: _emberData.default.attr()
  });
  _exports.default = _default;
});