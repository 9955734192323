define("simtex-myaccount/models/configdefinition", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fragment.default.extend({
    configdefinitionid: _emberData.default.attr(),
    configdefinitiongroup: _emberData.default.attr(),
    configstore_data: (0, _attributes.fragmentArray)('configstore', {
      async: false
    }),
    icon: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    enumreference: _emberData.default.attr(),
    defaultvalue: _emberData.default.attr('string'),
    ordering: _emberData.default.attr()
  });
  _exports.default = _default;
});