define("simtex-myaccount/templates/components/subscriptions/extension-label-light-table-cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (or this.row.isPending this.row.content.account.isPending)}}
      <div class="spinner spinner-sm">Loading...</div>
  {{else}}
      {{#if this.row.account.id}}
          {{#if this.row.account.internalextension}}
              {{this.row.account.internalextension}}<br />
              <small style="white-space: nowrap;">({{if this.row.account.label this.row.account.label "empty label"}})</small>
          {{else}}
              N/A
          {{/if}}
      {{else}}
          (empty)
      {{/if}}
  {{/if}}
  */
  {
    "id": "u+pW5O7S",
    "block": "[[[41,[28,[37,1],[[30,0,[\"row\",\"isPending\"]],[30,0,[\"row\",\"content\",\"account\",\"isPending\"]]],null],[[[1,\"    \"],[10,0],[14,0,\"spinner spinner-sm\"],[12],[1,\"Loading...\"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"row\",\"account\",\"id\"]],[[[41,[30,0,[\"row\",\"account\",\"internalextension\"]],[[[1,\"            \"],[1,[30,0,[\"row\",\"account\",\"internalextension\"]]],[10,\"br\"],[12],[13],[1,\"\\n            \"],[10,\"small\"],[14,5,\"white-space: nowrap;\"],[12],[1,\"(\"],[1,[52,[30,0,[\"row\",\"account\",\"label\"]],[30,0,[\"row\",\"account\",\"label\"]],\"empty label\"]],[1,\")\"],[13],[1,\"\\n\"]],[]],[[[1,\"            N/A\\n\"]],[]]]],[]],[[[1,\"        (empty)\\n\"]],[]]]],[]]]],[],false,[\"if\",\"or\"]]",
    "moduleName": "simtex-myaccount/templates/components/subscriptions/extension-label-light-table-cell.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});