define("simtex-myaccount/mixins/slush-buckets-common", ["exports", "@ember/object/mixin", "@ember/object", "@ember/array", "ember-data", "@ember/service", "@ember/template"], function (_exports, _mixin, _object, _array, _emberData, _service, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _construct(Parent, args, Class) { if (_isNativeReflectConstruct()) { _construct = Reflect.construct.bind(); } else { _construct = function _construct(Parent, args, Class) { var a = [null]; a.push.apply(a, args); var Constructor = Function.bind.apply(Parent, a); var instance = new Constructor(); if (Class) _setPrototypeOf(instance, Class.prototype); return instance; }; } return _construct.apply(null, arguments); }
  function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
  function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }
  var _default = _mixin.default.create({
    store: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    router: (0, _service.inject)(),
    simtex: (0, _service.inject)('simtex'),
    bucket_data: null,
    bucket_selected_primary: null,
    bucket_selected_allocated: null,
    bucket_selected_unallocated: null,
    owner: null,
    is_saving: false,
    // After bucket data is available, if select_primary non-null, set bucket_selected_primary to this.
    select_primary: null,
    // To allow allocations to be shared between primary objects, define `allow_shared_allocations_per_primary`
    // on the slush_bucket_context.
    // Setting it true will cause the unallocated bucket to be calculated based on currently selected primary object,
    // rather than based on all available primary objects.

    PRIMARY_INDEX: 0,
    ALLOCATED_INDEX: 1,
    UNALLOCATED_INDEX: 2,
    ALLOCATION_DATA_INDEX: 3,
    ORIGINAL_ALLOCATION_DATA_INDEX: 4,
    ORIGINAL_UNALLOCATED_INDEX: 5,
    PROMISE_PRIMARY_INDEX: 0,
    PROMISE_ALLOCATION_INDEX: 1,
    PROMISE_ACCOUNT_INDEX: 2,
    bucket_selected_primary_description: (0, _object.computed)('bucket_selected_primary', function () {
      var _this = this;
      if (this.slush_bucket_context.bucket_selected_primary_descriptionref) {
        return this.get(this.slush_bucket_context.bucket_selected_primary_descriptionref);
      } else {
        var pd = this.bucket_data.get(this.PRIMARY_INDEX);
        if (pd && pd.data) {
          var selected_data = pd.data.find(function (item) {
            return item.value === _this.bucket_selected_primary;
          });
          return selected_data ? selected_data.label : '';
        }
      }
    }),
    none_unallocated: (0, _object.computed)('bucket_data.content.@each.data', function () {
      if (this.bucket_data.content) {
        var ad = this.bucket_data.get(this.ALLOCATION_DATA_INDEX).data;
        return !ad || ad.filter(function (item) {
          return item.extra.primaryrefid.length === 0;
        }).length === 0;
      } else {
        return false;
      }
    }),
    disable_save: (0, _object.computed)('bucket_data.content.@each.data', 'is_saving', function () {
      if (this.get('is_saving')) {
        return true;
      } else if (this.bucket_data.content) {
        var ad = this.bucket_data.get(this.ALLOCATION_DATA_INDEX).data;
        var od = this.bucket_data.get(this.ORIGINAL_ALLOCATION_DATA_INDEX).data;
        return JSON.stringify(ad) === JSON.stringify(od);
      } else {
        return true;
      }
    }),
    prompt_primary_empty: (0, _object.computed)('bucket_data.content.@each.data', function () {
      if (this.bucket_data.get(this.PRIMARY_INDEX).data.length === 0) {
        return this.slush_bucket_context.strings.prompt_primary_noresults;
      }
      return false;
    }),
    prompt_allocated_empty: (0, _object.computed)('bucket_data.content.@each.data', 'bucket_selected_primary', function () {
      var _this2 = this;
      if (this.bucket_selected_primary === null) return this.slush_bucket_context.strings.prompt_allocated_empty_noprimary;else if (this.bucket_data.get(this.ALLOCATION_DATA_INDEX).data.filter(function (item) {
        return item.extra.primaryrefid.includes(_this2.bucket_selected_primary);
      }).length === 0) {
        return this.slush_bucket_context.strings.prompt_allocated_empty_noresults;
      }
      return false;
    }),
    prompt_unallocated_empty: (0, _object.computed)('bucket_data.content.@each.data', function () {
      return this.none_unallocated ? this.slush_bucket_context.strings.prompt_unallocated_empty : false;
    }),
    disable_deallocate: (0, _object.computed)('bucket_selected_primary', 'bucket_selected_allocated', function () {
      if (this.bucket_selected_primary === null) return true;else {
        // `Allocated` represents transient items in the allocated bucket
        var allocated = this.bucket_data.get(this.ALLOCATION_DATA_INDEX).data;
        return this.bucket_selected_allocated === null || allocated && allocated.length === 0;
      }
    }),
    disable_allocate: (0, _object.computed)('bucket_data.content.@each.data', 'bucket_selected_primary', 'bucket_selected_unallocated', function () {
      if (this.none_unallocated || this.bucket_selected_primary === null) {
        return true;
      } else {
        return this.bucket_selected_unallocated === null;
      }
    }),
    bucket_data_ready: (0, _object.observer)('bucket_data.content.@each.data', function () {
      if (this.select_primary) {
        if (this.slush_bucket_context.ref_model_primary === 'endpoint') this.set('select_primary', (this.get('select_primary') - 2100000).toString());
        if (this.slush_bucket_context.ref_model_primary_type === 'number') {
          this.set('select_primary', parseInt(this.get('select_primary')));
        }
        this.refresh_allocated(this.select_primary);
        this.refresh_unallocated(this.select_primary);
        this.set('bucket_selected_primary', this.select_primary);
        this.set('select_primary', null);
        this.reset_selections(false);
      }
    }),
    update_buckets: (0, _object.observer)('simtex.update_buckets', function () {
      if (this.simtex.update_buckets !== undefined) {
        this.initBuckets([{
          model: this.model
        }]);
      }
    }),
    get_bucket_data: function get_bucket_data(owner) {
      var self = this;
      var query_dict = {
        'owner_filter_term': owner || this.owner,
        'page_size': 1000,
        'remove_fields': self.slush_bucket_context.ref_model_primary_remove_fields,
        'ordering': self.slush_bucket_context.ref_model_primary_ordering
      };
      query_dict = Object.assign({}, query_dict, self.slush_bucket_context.ref_model_primary_filter_dict);
      var bucketPromise = Promise.all([_emberData.default.PromiseObject.create({
        // PRIMARY OBJECTS
        promise: this.store.query(self.slush_bucket_context.ref_model_primary, query_dict)
      }), _emberData.default.PromiseObject.create({
        // ALLOCATION OBJECTS
        promise: this.store.query(self.slush_bucket_context.ref_model_allocation, {
          'owner_filter_term': owner || this.owner,
          'page_size': 5000,
          'remove_fields': self.slush_bucket_context.ref_model_allocation_remove_fields,
          'ordering': self.slush_bucket_context.ref_model_allocation_ordering || ''
        }).then(function (result) {
          if (self.slush_bucket_context.ref_model_allocation_then) {
            return self.slush_bucket_context.ref_model_allocation_then(result);
          } else {
            return result;
          }
        })
      })
      // This was only used for IPND SITES slush buckets, disable for now to make faster
      // DS.PromiseObject.create({
      //     promise: this.store.query('account', {
      //         'owner_filter_term': owner || this.owner,
      //         'page_size': 200,
      //         'remove_fields': [
      //             'did_data', 'channellimit_data', 'dialrestriction_data',
      //             'transport_data', 'callcontrol_data', 'endpoint_status_data',
      //             'product_data', 'numberpresentation_data', 'voicemail_data'
      //         ]
      //     })
      // })
      ]).then(function (values) {
        var template = function template(tpl, args) {
          var keys = Object.keys(args),
            fn = _construct(Function, keys.concat(['return `' + tpl.replace(/`/g, '\\`') + '`']));
          return fn.apply(void 0, _toConsumableArray(keys.map(function (x) {
            return args[x];
          })));
        };
        var primary = (0, _array.A)([]),
          allocated = (0, _array.A)([]),
          unallocated = (0, _array.A)([]),
          allocation_data = (0, _array.A)([]);

        // Build accounts array for helpful lookups
        // let accounts = values[self.PROMISE_ACCOUNT_INDEX].toArray();

        // Iterate primaryref.
        // If dont_resolve_model_arrays is truthy (will be undefined by default), then we
        //  do not call .toArray() on our array proxies. We set dont_resolve_model_arrays
        //  true for very large arrays to increase performance dramatically, e.g. within
        //  config for did-subscription-buckets.js.
        // It is left undefined for handset-subscription-buckets.js - it is currently buggy
        //  with join-table situations.

        //26/08/2022 - removed dont_resolve_model_arrays as it doesn't increase the performance as much and it breaks later version of ember data
        var primaryrefs = values[self.PROMISE_PRIMARY_INDEX].toArray();
        for (var i = 0, len = primaryrefs.length; i < len; i++) {
          var primaryrefid = primaryrefs[i].get(self.slush_bucket_context.ref_model_primary_optionval);
          var sortval = self.slush_bucket_context.ref_model_primary_ordering ? primaryrefs[i].get(self.slush_bucket_context.ref_model_primary_ordering) : '';
          var labelargs = {};
          for (var _i = 0, _Object$entries = Object.entries(self.slush_bucket_context.ref_model_primary_optionlabeldict); _i < _Object$entries.length; _i++) {
            var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
              key = _Object$entries$_i[0],
              val = _Object$entries$_i[1];
            labelargs[key] = primaryrefs[i].get(val) || "(empty ".concat(key, ")");
          }
          primary.pushObject({
            label: template(self.slush_bucket_context.ref_model_primary_optionlabel, labelargs),
            value: primaryrefid,
            sortval: sortval
          });
        }

        // Iterate allocations
        var allocations = values[self.PROMISE_ALLOCATION_INDEX].toArray();
        var _loop = function _loop(_i2, _len) {
          var allocationval = allocations[_i2].get(self.slush_bucket_context.ref_model_allocation_optionval);
          var sortval = self.slush_bucket_context.ref_model_allocation_ordering ? allocations[_i2].get(self.slush_bucket_context.ref_model_allocation_ordering) : '';

          // Determine if we need to override the default 'allocation item label', which is just the value.
          var allocationlabel = allocationval;
          if (self.slush_bucket_context.ref_model_allocation_optionlabel && self.slush_bucket_context.ref_model_allocation_optionlabeldict) {
            var _labelargs = {};
            for (var _i3 = 0, _Object$entries2 = Object.entries(self.slush_bucket_context.ref_model_allocation_optionlabeldict); _i3 < _Object$entries2.length; _i3++) {
              var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i3], 2),
                _key = _Object$entries2$_i[0],
                _val = _Object$entries2$_i[1];
              _labelargs[_key] = allocations[_i2].get(_val) || "(empty ".concat(_key, ")");
            }
            allocationlabel = template(self.slush_bucket_context.ref_model_allocation_optionlabel, _labelargs);
          }

          // Build route data for this allocation object
          var routedata = [];
          switch (self.slush_bucket_context.ref_model_allocation) {
            case 'account':
              routedata = ['restricted.subscriptions.subscription', [self.owner, allocationval]
              // { queryParams: { refreshAcct: true }}
              ];

              break;
            default:
              break;
          }

          // Discover allocation primaryrefid(s) associated with our primary object,
          // via linking property defined by ref_model_allocation_primaryrefid.
          // Linking property supports chained FK relations and arrays.
          var primaryrefids = (0, _array.A)([]);
          var linking_property = self.slush_bucket_context.ref_model_allocation_primaryrefid;

          // We define object walking using > rather than . as Ember allows us to treat something
          // like endpoint.id as a property on the parent object, even if endpoint is an unresolved Proxy.
          (function try_get_property(_try_get_result, _arr_props, _prop_index) {
            if (_prop_index >= _arr_props.length) {
              if (_try_get_result) {
                primaryrefids.pushObject(_try_get_result);
              }
              return;
            }
            if ((0, _array.isArray)(_try_get_result)) {
              // Filter our array elements down to those which we have IDs for,
              // in case our store cache is stale (partial save due to validation problem)
              _try_get_result.filter(function (item) {
                return item.id;
              } // check id is truthy
              ).forEach(function (item) {
                try_get_property(item.get(_arr_props[_prop_index]), _arr_props, _prop_index + 1);
              });
            } else {
              var __lookup = _try_get_result.get(_arr_props[_prop_index]);
              try_get_property(__lookup, _arr_props, _prop_index + 1);
            }
          })(allocations[_i2], linking_property.split('>'), 0);
          allocation_data.pushObject({
            label: allocationlabel,
            value: allocationval,
            sortval: sortval,
            extra: {
              primaryrefid: primaryrefids,
              routedata: routedata
            }
          });
          if (primaryrefids.length === 0) {
            // Push this allocation object to the unallocated array ONLY IF
            // - It does not occur in primaryrefids (no linked objects)
            // - allow_shared_allocations_per_primary is false (default)
            //   This prevents its re-use for other primary objects.
            if (!self.slush_bucket_context.allow_shared_allocations_per_primary) {
              unallocated.pushObject({
                label: allocationlabel,
                value: allocationval,
                sortval: sortval,
                extra: {
                  primaryrefid: primaryrefids,
                  routedata: routedata
                }
              });
            }
          }

          // If allow_shared_allocations_per_primary true, ALWAYS push this allocation object to
          // the unallocated array so it can be re-used for other primary objects.
          // When viewing a specific primary object, the unallocated list will be dynamically filtered to
          // exclude those already mapped.
          if (self.slush_bucket_context.allow_shared_allocations_per_primary) {
            if (self.simtex.is_admin || !self.slush_bucket_context.hide_allocated_in_unallocated_for_superuser || primaryrefids.length === 0) {
              unallocated.pushObject({
                label: allocationlabel,
                value: allocationval,
                sortval: sortval,
                extra: {
                  primaryrefid: primaryrefids,
                  routedata: routedata
                }
              });
            }
          }
        };
        for (var _i2 = 0, _len = allocations.length; _i2 < _len; _i2++) {
          _loop(_i2, _len);
        }

        // Sort primary and allocation arrays
        primary = primary.sortBy('sortval', 'label');
        allocated = allocated.sortBy('sortval', 'label');
        unallocated = unallocated.sortBy('sortval', 'label');
        return [
        // PRIMARY_INDEX
        _object.default.create({
          type: 'primary',
          data: primary
        }),
        // ALLOCATED_INDEX
        _object.default.create({
          type: 'allocated',
          data: allocated
        }),
        // UNALLOCATED_INDEX
        _object.default.create({
          type: 'unallocated',
          data: unallocated
        }),
        // ALLOCATION_DATA_INDEX
        _object.default.create({
          type: 'allocation_data',
          data: allocation_data
        }),
        // ORIGINAL_ALLOCATION_DATA_INDEX
        _object.default.create({
          type: '_original_allocation_data',
          data: _toConsumableArray(allocation_data)
        }),
        // clones array,
        // ORIGINAL_UNALLOCATED_INDEX - only utilised when allow_shared_allocations_per_primary true
        _object.default.create({
          type: '_original_unallocated',
          data: _toConsumableArray(unallocated)
        })];
      });
      this.set('bucket_data', _emberData.default.PromiseObject.create({
        promise: bucketPromise
      }));
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.initBuckets(arguments);
    },
    initBuckets: function initBuckets(args) {
      if (args && args[0].model) {
        this.owner = args[0].model.owner;
        this.get_bucket_data();
      }
    },
    refresh_allocated: function refresh_allocated(primaryrefid) {
      this.bucket_data.set("".concat(this.ALLOCATED_INDEX, ".data"), this.bucket_data.get("".concat(this.ALLOCATION_DATA_INDEX, ".data")).filter(function (item) {
        return item.extra.primaryrefid.includes(primaryrefid);
      }));
    },
    refresh_unallocated: function refresh_unallocated(primaryrefid) {
      if (this.slush_bucket_context.allow_shared_allocations_per_primary && (this.simtex.is_admin || !this.slush_bucket_context.hide_allocated_in_unallocated_for_superuser)) {
        var original_unallocated = this.bucket_data.get("".concat(this.ORIGINAL_UNALLOCATED_INDEX, ".data")),
          allocated = this.bucket_data.get("".concat(this.ALLOCATED_INDEX, ".data"));

        // Define custom filter function which has access to allocated array.
        // Return true if unallocated item is not allocated to this primary object.
        function filterUnallocated(_allocated, _unallocated_item) {
          return !_allocated.map(function (_allocated_account) {
            return _allocated_account.value;
          }).includes(_unallocated_item.value);
        }
        this.bucket_data.set("".concat(this.UNALLOCATED_INDEX, ".data"), original_unallocated.filter(filterUnallocated.bind(this, allocated)));
      } else {
        this.bucket_data.set("".concat(this.UNALLOCATED_INDEX, ".data"), this.bucket_data.get("".concat(this.ALLOCATION_DATA_INDEX, ".data")).filter(function (item) {
          return item.extra.primaryrefid.length === 0 && item.value !== primaryrefid;
        }));
      }
    },
    reset_selections: function reset_selections() {
      var include_unallocated = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var include_primaryrefid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.set('bucket_selected_allocated', null);
      if (include_unallocated) this.set('bucket_selected_unallocated', null);
      if (include_primaryrefid) this.set('bucket_selected_primary', null);
    },
    update_allocation_data: function update_allocation_data(allocations, primaryrefid) {
      var _this3 = this;
      var refresh_allocation_buckets = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      if (allocations && primaryrefid !== undefined) {
        var allocation_data = this.bucket_data.get("".concat(this.ALLOCATION_DATA_INDEX, ".data"));
        for (var i = 0, len = allocation_data.length; i < len; i++) {
          if (allocations.includes(allocation_data[i].value)) {
            var updated_primaryrefids = (0, _array.A)([]);
            if (this.slush_bucket_context.allow_shared_allocations_per_primary) {
              if (primaryrefid === null) {
                (function () {
                  var selected_primary = _this3.get('bucket_selected_primary');
                  updated_primaryrefids = (allocation_data[i].extra.primaryrefid || (0, _array.A)([])).filter(function (ad) {
                    return ad !== selected_primary;
                  });
                })();
              } else {
                updated_primaryrefids = (0, _array.A)([primaryrefid]).concat(allocation_data[i].extra.primaryrefid || (0, _array.A)([]));
              }
            } else {
              // One allocation object per primary object - empty array or array with single item.
              updated_primaryrefids = primaryrefid === null ? (0, _array.A)([]) : (0, _array.A)([primaryrefid]);
            }
            this.bucket_data.content[this.ALLOCATION_DATA_INDEX].data[i] = {
              label: allocation_data[i].label,
              value: allocation_data[i].value,
              extra: {
                primaryrefid: updated_primaryrefids
              }
            };
          }
        }
        if (refresh_allocation_buckets) {
          this.refresh_allocated(this.get('bucket_selected_primary'));
          this.refresh_unallocated(primaryrefid);
        }
      }
    },
    unpack_values: function unpack_values(data) {
      var property_name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'value';
      var ret = null;
      if (data) {
        if (Array.isArray(data)) {
          ret = [];
          for (var i = 0, len = data.length; i < len; i++) {
            ret.push(data[i][property_name]);
          }
        } else {
          ret = data[property_name];
        }
      }
      return ret;
    },
    displayErrors: function displayErrors(exception) {
      if (Array.isArray(exception.errors)) {
        this.toast.error((0, _template.htmlSafe)(this.slush_bucket_context.strings.saved_allocations_failure + '<br />' + exception.errors.map(function (err) {
          return err.detail;
        }).join('<br />')));
      } else {
        this.toast.error(this.slush_bucket_context.strings.saved_allocations_failure);
      }
      this.set('select_primary', this.get('bucket_selected_primary'));
      this.set('bucket_selected_primary', null);
      if (this.get('simtex.update_buckets') === undefined) {
        this.set('simtex.update_buckets', 0);
      } else {
        this.incrementProperty('simtex.update_buckets');
      }
    },
    actions: {
      allocate: function allocate() {
        this.update_allocation_data(this.get('bucket_selected_unallocated'), this.get('bucket_selected_primary'));
        this.reset_selections();
      },
      deallocate: function deallocate() {
        // if (!this.slush_bucket_context.show_modal_prompt_deallocate) {
        this.update_allocation_data(this.get('bucket_selected_allocated'), null);
        this.reset_selections();
        // }
      },
      contextMenu: function contextMenu(context) {
        var _this$router, _this$router2;
        if (context.selection && context.selection.length === 1) {
          var selected = context.content.find(function (item) {
            return item.value === context.selection.firstObject;
          });
          if (selected && selected.extra.routedata) {
            var routedata = selected.extra.routedata;
            if (Array.isArray(routedata)) {
              switch (routedata.length) {
                case 2:
                  (_this$router = this.router).transitionTo.apply(_this$router, [routedata[0]].concat(_toConsumableArray(routedata[1])));
                  break;
                case 3:
                  (_this$router2 = this.router).transitionTo.apply(_this$router2, [routedata[0]].concat(_toConsumableArray(routedata[1]), [routedata[2]]));
              }
            }
          }
        }
      },
      undo: function undo() {
        // Clone _original_allocation_data to allocation_data and refresh buckets.
        this.bucket_data.set("".concat(this.ALLOCATION_DATA_INDEX, ".data"), _toConsumableArray(this.bucket_data.get("".concat(this.ORIGINAL_ALLOCATION_DATA_INDEX, ".data"))));
        var primaryrefid = this.get('bucket_selected_primary');
        this.refresh_allocated(primaryrefid);
        this.refresh_unallocated(primaryrefid);
      },
      save: function save() {
        var allocation_data = this.bucket_data.get("".concat(this.ALLOCATION_DATA_INDEX, ".data")).sortBy('sortval', 'label');
        var original = this.bucket_data.get("".concat(this.ORIGINAL_ALLOCATION_DATA_INDEX, ".data")).sortBy('sortval', 'label');
        var all_join_objects = this.slush_bucket_context.ref_shared_allocation_join ? this.store.peekAll(this.slush_bucket_context.ref_shared_allocation_join.table_name).toArray() : [];
        var self = this;
        self.all_join_objects = all_join_objects;

        // Build up our Allocation Save promise array
        var allocationSavePromiseArray = (0, _array.A)([]);
        var totalChanges = [];
        var _loop2 = function _loop2(i, len) {
          var allocation_primaryrefs = allocation_data[i].extra.primaryrefid;
          var allocation_value = allocation_data[i].value;
          var original_extra_refs = original.find(function (item) {
            return item.value === allocation_value;
          }).extra.primaryrefid;

          // Determine added/removed allocations
          var allocations_to_create = allocation_primaryrefs.filter(function (ref) {
            return !original_extra_refs.includes(ref);
          }).map(function (item) {
            return {
              primaryrefid: item,
              type: 'create',
              allocationvalue: allocation_value
            };
          });
          var allocations_to_remove = original_extra_refs.filter(function (ref) {
            return !allocation_primaryrefs.includes(ref);
          }).map(function (item) {
            return {
              primaryrefid: item,
              type: 'remove',
              allocationvalue: allocation_value
            };
          });
          var changes = allocations_to_create.concat(allocations_to_remove);
          totalChanges = totalChanges.concat(changes);
          if (changes.length > 0) {
            var allocation = self.store.peekRecord(self.slush_bucket_context.ref_model_allocation, allocation_value);

            // Update allocation object with new/modified reference to
            // primary object(s) (target_value) via primaryrefids
            if (allocation) {
              var linking_property = self.slush_bucket_context.ref_model_allocation_primaryrefobj;
              var _loop3 = function _loop3(j) {
                var change = changes[j];
                var target_value = null;
                if (!self.slush_bucket_context.ref_shared_allocation_join) {
                  target_value = self.store.peekRecord(self.slush_bucket_context.ref_model_primary, change.primaryrefid);
                  if (self.slush_bucket_context.ref_model_primary_backend) {
                    target_value = target_value.get(self.slush_bucket_context.ref_model_primary_backend);
                  }
                } else {
                  var primary_ref = self.slush_bucket_context.ref_shared_allocation_join.primary_model_valueref;
                  var allocation_ref = self.slush_bucket_context.ref_shared_allocation_join.allocation_model_valueref;
                  var join_table_name = self.slush_bucket_context.ref_shared_allocation_join.table_name;
                  if (change.type === 'remove') {
                    target_value = self.all_join_objects.find(function (obj) {
                      return obj.get(allocation_ref) === allocation_value.toString() && obj.get(primary_ref) === change.primaryrefid;
                    });
                  } else {
                    var target_options = {};
                    target_options[self.slush_bucket_context.ref_model_primary] = self.store.peekRecord(self.slush_bucket_context.ref_model_primary, change.primaryrefid);
                    target_options[allocation_ref] = allocation_value;
                    target_value = self.store.createRecord(join_table_name, target_options);
                    // target_value = { table: join_table_name, data: target_options };
                  }
                }

                // We define object walking using > rather than . as Ember allows us to treat something
                // like endpoint.id as a property on the parent object, even if endpoint is an unresolved Proxy.
                (function try_set_property(_target_object, _target_value, _arr_props, _prop_index) {
                  var _target_array_behaviour = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'create';
                  if (_prop_index >= _arr_props.length - 1) {
                    if ((0, _array.isArray)(_target_object)) {
                      if (_target_array_behaviour === 'create') {
                        _target_object.addObject(_target_value);
                        // debugger;
                        // _target_object.push(_target_value);
                        // _target_value.rollback();
                      } else {
                        _target_object.removeObject(_target_value);
                      }
                    } else {
                      //check if there are multiple operation on a single number
                      if (!self.slush_bucket_context.ref_shared_allocation_join && changes.length > 1 && changes.filter(function (change) {
                        return change.type == 'create';
                      }).length > 0 && changes.filter(function (change) {
                        return change.type == 'remove';
                      }).length > 0) {
                        if (_target_array_behaviour === 'create') _target_object.set(_arr_props[_prop_index], _target_value);
                      } else {
                        if (_target_array_behaviour === 'create') {
                          _target_object.set(_arr_props[_prop_index], _target_value);
                        } else {
                          _target_object.set(_arr_props[_prop_index], null);
                        }
                      }
                    }
                    return;
                  }
                  if ((0, _array.isArray)(_target_object)) {
                    _target_object.forEach(function (item) {
                      try_set_property(item.get(_arr_props[_prop_index]), _target_value, _arr_props, _prop_index + 1, _target_array_behaviour);
                    });
                  } else {
                    try_set_property(_target_object.get(_arr_props[_prop_index]), _target_value, _arr_props, _prop_index + 1, _target_array_behaviour);
                  }
                })(allocation, target_value, linking_property.split('>'), 0, change.type);
              };
              for (var j = 0; j < changes.length; j++) {
                _loop3(j);
              } // End changes iteration

              // See note below about pushing just the allocation,
              // rather than a Promise to save the allocation.
              allocationSavePromiseArray.pushObject(allocation);
            }
          }
        };
        for (var i = 0, len = allocation_data.length; i < len; i++) {
          _loop2(i, len);
        }

        // Sort allocations such that create operations occur first, followed by remove operations.
        allocationSavePromiseArray = allocationSavePromiseArray.sort(function (a1, a2) {
          var change1 = totalChanges.find(function (c) {
            return c.allocationvalue === a1.get(self.slush_bucket_context.ref_model_allocation_optionval);
          });
          var change2 = totalChanges.find(function (c) {
            return c.allocationvalue === a2.get(self.slush_bucket_context.ref_model_allocation_optionval);
          });
          var changetype1 = change1 ? change1.type : '';
          var changetype2 = change2 ? change2.type : '';
          if (changetype1 === 'create') {
            return -1;
          } else if (changetype2 === 'create') {
            return 1;
          } else {
            return 0;
          }
        });

        // Use this approach rather than asynchronous execution to ensure that
        // API calls succeed without hitting index constraints.
        // https://stackoverflow.com/questions/20100245/how-can-i-execute-array-of-promises-in-sequential-order/43189700
        self = this;
        self.set('is_saving', true);
        allocationSavePromiseArray.reduce(function (cur, next) {
          return cur.then(function () {
            return next.save().then(function (ret) {
              if (self.slush_bucket_context.strings.saved_allocated_single_success && self.slush_bucket_context.strings.saved_deallocated_single_success) {
                var template = function template(tpl, args) {
                  var keys = Object.keys(args),
                    fn = _construct(Function, keys.concat(['return `' + tpl.replace(/`/g, '\\`') + '`']));
                  return fn.apply(void 0, _toConsumableArray(keys.map(function (x) {
                    return args[x];
                  })));
                };
                var change = totalChanges.find(function (c) {
                  return c.allocationvalue === ret.get(self.slush_bucket_context.ref_model_allocation_optionval);
                });
                if (change) {
                  var label = self.store.peekRecord(self.slush_bucket_context.ref_model_primary, change.primaryrefid).get('label');
                  var labelargs = {
                    'label': label,
                    'allocationvalue': change.allocationvalue
                  };
                  if (change.type === 'create') {
                    self.toast.success(template(self.slush_bucket_context.strings.saved_allocated_single_success, labelargs));
                  } else {
                    self.toast.info(template(self.slush_bucket_context.strings.saved_deallocated_single_success, labelargs));
                  }
                }
              }
            });
          }).catch(function (e) {
            self.displayErrors(e);
          });
        }, Ember.RSVP.resolve()).then(function (ret) {
          self.toast.success(self.slush_bucket_context.strings.saved_allocations_success);
          // Clone allocation_data to _original_allocation_data and refresh buckets.
          self.bucket_data.set("".concat(self.ORIGINAL_ALLOCATION_DATA_INDEX, ".data"), _toConsumableArray(self.bucket_data.get("".concat(self.ALLOCATION_DATA_INDEX, ".data"))));
          var primaryrefid = self.get('bucket_selected_primary');
          self.refresh_allocated(primaryrefid);
          self.refresh_unallocated(primaryrefid);
        }).catch(function (e) {
          self.displayErrors(e);
        }).finally(function () {
          if (!self.isDestroyed) {
            self.set('is_saving', false);
          }
        });
      },
      bucketPrimaryDidChange: function bucketPrimaryDidChange(data) {
        // We don't expect more than one selected primaryrefid, multiple=false
        var unpacked_values = this.unpack_values(data);
        var val = unpacked_values;
        if (Array.isArray(unpacked_values)) val = unpacked_values[0];
        this.refresh_allocated(val);
        this.refresh_unallocated(val);
        this.set('bucket_selected_primary', val);
        this.reset_selections(false);
      },
      bucketAllocatedDidChange: function bucketAllocatedDidChange(data) {
        this.set('bucket_selected_allocated', this.unpack_values(data));
      },
      bucketUnallocatedDidChange: function bucketUnallocatedDidChange(data) {
        this.set('bucket_selected_unallocated', this.unpack_values(data));
      }
    }
  });
  _exports.default = _default;
});