define("simtex-myaccount/components/register-dialog", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    modalShowing: false,
    contactmethods: [{
      display_name: 'Email',
      value: 'email'
    }, {
      display_name: 'Mobile phone',
      value: 'mobile'
    }],
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('modalShowing');
      }
    }
  });
  _exports.default = _default;
});