define("simtex-myaccount/components/password-request-form", ["exports", "@ember/component", "@ember/service", "@ember/template", "@ember/object"], function (_exports, _component, _service, _template, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    simtex: (0, _service.inject)('simtex'),
    router: (0, _service.inject)(),
    username: null,
    password: null,
    password_request_message: null,
    show_error_message: false,
    show_success_message: false,
    show_spinner: false,
    caller: null,
    from_login_page: (0, _object.computed)('caller', function () {
      return this.get('caller') === 'login';
    }),
    actions: {
      passwordrequest: function passwordrequest() {
        var _this = this;
        this.set('show_spinner', true);
        this.set('show_password_request_message', false);
        this.simtex.passwordrequest(this.username).then(function (result) {
          _this.set('show_spinner', false);
          _this.set('password_request_message', (0, _template.htmlSafe)(result.message));
          if (result.status === 'error') {
            _this.set('show_error_message', true);
            _this.set('show_success_message', false);
          } else {
            _this.set('show_success_message', true);
            _this.set('show_error_message', false);
            var continue_url = result.continueurl;
            if (continue_url !== '') window.location.replace(continue_url);
          }
        });
      }
    }
  });
  _exports.default = _default;
});