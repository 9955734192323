define("simtex-myaccount/templates/restricted/subscriptions/list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title 'Subscriptions (' this.simtex.owner_description ')'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top:0;">Subscription List for {{this.simtex.owner_description}}</h2>
              <div>
                  <small>{{get-simtex-string 'LIST_DESCRIPTION_SUBSCRIPTION'}}</small>
              </div>
              {{subscriptions/subscription-table ownerFilterTerm=this.simtex.owner_search_term}}
          </div>
      </div>
  </div>
  */
  {
    "id": "DjPsRQtB",
    "block": "[[[1,[28,[35,0],[\"Subscriptions (\",[30,0,[\"simtex\",\"owner_description\"]],\")\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top:0;\"],[12],[1,\"Subscription List for \"],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n            \"],[10,0],[12],[1,\"\\n                \"],[10,\"small\"],[12],[1,[28,[35,1],[\"LIST_DESCRIPTION_SUBSCRIPTION\"],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[1,[28,[35,2],null,[[\"ownerFilterTerm\"],[[30,0,[\"simtex\",\"owner_search_term\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"get-simtex-string\",\"subscriptions/subscription-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/subscriptions/list.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});