define("simtex-myaccount/templates/components/numbers/sites/site-summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (or this.row.isPending this.row.content.serviceLocationSummary.isPending)}}
      <div class="spinner spinner-sm">Loading...</div>
  {{else}}
      {{#if this.row.content.serviceLocationSummary}}
          {{#if (is-equal column.classNames "single-line")}}
              {{array-join this.row.serviceLocationSummary ', '}}
          {{else}}
              {{array-join this.row.serviceLocationSummary '<br />,'}}
          {{/if}}
      {{else}}
          (empty)
      {{/if}}
  {{/if}}
  */
  {
    "id": "DGBr9GHu",
    "block": "[[[41,[28,[37,1],[[30,0,[\"row\",\"isPending\"]],[30,0,[\"row\",\"content\",\"serviceLocationSummary\",\"isPending\"]]],null],[[[1,\"    \"],[10,0],[14,0,\"spinner spinner-sm\"],[12],[1,\"Loading...\"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"row\",\"content\",\"serviceLocationSummary\"]],[[[41,[28,[37,2],[[33,3,[\"classNames\"]],\"single-line\"],null],[[[1,\"            \"],[1,[28,[35,4],[[30,0,[\"row\",\"serviceLocationSummary\"]],\", \"],null]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[35,4],[[30,0,[\"row\",\"serviceLocationSummary\"]],\"<br />,\"],null]],[1,\"\\n\"]],[]]]],[]],[[[1,\"        (empty)\\n\"]],[]]]],[]]]],[],false,[\"if\",\"or\",\"is-equal\",\"column\",\"array-join\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/sites/site-summary.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});