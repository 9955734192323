define("simtex-myaccount/templates/components/light-table-cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model_valueeditable}}
  <div class="clr-input-wrapper" style="margin-top: -0.3rem;">
      <Input class={{concat "clr-input " this.column.cellComponentClassNames}}
              @type="text"
              @value={{this.model_rowcontent}}
              id={{this.row.column.valuePath}}
              placeholder={{this.model_placeholder}}
      />
      {{#if this.row.content.hasDirtyAttributes}}
      <button {{action "save_nested" row.content}}
          title="Save"
          class="btn btn-sm btn-primary-outline btn-sm-simtex-table-action">
          <clr-icon shape="check"></clr-icon>
      </button>
      {{else}}
      <button
          class="btn btn-sm btn-primary-outline btn-sm-simtex-table-action"
          disabled>
          <clr-icon shape="check"></clr-icon>
      </button>
      {{/if}}
  </div>
  {{#if this.column.subtext}}
  <span class="clr-subtext">
      {{this.column.subtext}}
  </span>
  {{/if}}
  {{else}}
  {{get-coalesce this.model_rowcontent}}
  {{/if}}
  
  
  */
  {
    "id": "r3+1bzYZ",
    "block": "[[[41,[30,0,[\"model_valueeditable\"]],[[[10,0],[14,0,\"clr-input-wrapper\"],[14,5,\"margin-top: -0.3rem;\"],[12],[1,\"\\n    \"],[8,[39,1],[[16,0,[28,[37,2],[\"clr-input \",[30,0,[\"column\",\"cellComponentClassNames\"]]],null]],[16,1,[30,0,[\"row\",\"column\",\"valuePath\"]]],[16,\"placeholder\",[30,0,[\"model_placeholder\"]]]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"model_rowcontent\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"row\",\"content\",\"hasDirtyAttributes\"]],[[[1,\"    \"],[11,\"button\"],[24,\"title\",\"Save\"],[24,0,\"btn btn-sm btn-primary-outline btn-sm-simtex-table-action\"],[4,[38,3],[[30,0],\"save_nested\",[33,4,[\"content\"]]],null],[12],[1,\"\\n        \"],[10,\"clr-icon\"],[14,\"shape\",\"check\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"button\"],[14,0,\"btn btn-sm btn-primary-outline btn-sm-simtex-table-action\"],[14,\"disabled\",\"\"],[12],[1,\"\\n        \"],[10,\"clr-icon\"],[14,\"shape\",\"check\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[41,[30,0,[\"column\",\"subtext\"]],[[[10,1],[14,0,\"clr-subtext\"],[12],[1,\"\\n    \"],[1,[30,0,[\"column\",\"subtext\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,[28,[35,5],[[30,0,[\"model_rowcontent\"]]],null]],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[],false,[\"if\",\"input\",\"concat\",\"action\",\"row\",\"get-coalesce\"]]",
    "moduleName": "simtex-myaccount/templates/components/light-table-cell.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});