define("simtex-myaccount/templates/components/callhistory/callhistory-expanded-action", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (gt this.row.cdrdata_history.length 1) }}
      {{light-table-expansion-action row=this.row}}
  {{/if}}
  */
  {
    "id": "nVVMyXgn",
    "block": "[[[41,[28,[37,1],[[30,0,[\"row\",\"cdrdata_history\",\"length\"]],1],null],[[[1,\"    \"],[1,[28,[35,2],null,[[\"row\"],[[30,0,[\"row\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"gt\",\"light-table-expansion-action\"]]",
    "moduleName": "simtex-myaccount/templates/components/callhistory/callhistory-expanded-action.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});