define("simtex-myaccount/components/subscriptions/subscription/callforwarding-dialog", ["exports", "@ember/component", "@ember/service", "simtex-myaccount/mixins/model-saver-common", "simtex-myaccount/mixins/clr-wizard-common", "@ember/string", "@ember/object", "@ember/array", "ember-data"], function (_exports, _component, _service, _modelSaverCommon, _clrWizardCommon, _string, _object, _array, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_clrWizardCommon.default, _modelSaverCommon.default, {
    store: (0, _service.inject)(),
    modelName: 'callcontrol',
    modelId: 0,
    removeCallForwardShowing: false,
    wizardAppearance: null,
    wizardAppearanceNew: {
      wizardOpenBtnIcon: 'plus',
      wizardOpenBtnText: 'Add Call Forward',
      wizardOpenBtnClass: ' btn-link',
      wizardFinishBtnText: 'Add Call Forward',
      wizardTitle: 'Add Call Forward',
      wizardClass: 'modal-dialog clr-wizard'
    },
    wizardAppearanceEdit: {
      wizardOpenBtnText: 'Edit',
      wizardOpenFaIcon: 'edit',
      wizardTitle: 'Edit Call Forward',
      wizardOpenBtnClass: ' btn-link',
      wizardFinishBtnText: 'Save Changes',
      wizardFinishBtnClass: ' btn-primary',
      wizardClass: 'modal-dialog clr-wizard'
    },
    init: function init() {
      var self = this;
      self._super.apply(self, arguments);
      var wizardMode = (0, _string.capitalize)(self.get('wizardMode') || self.get('column.wizardMode') || 'new');
      var choices = [];
      var disabled_divtype_choices = [];
      if (self.advancedForward != null && self.advancedForward.length > 0) {
        self.advancedForward.forEach(function (control) {
          choices.push({
            display_name: control.label,
            value: control.advancedforwardcontrolid
          });
        });
      } else {
        disabled_divtype_choices.push('AFAB');
      }
      self.set('wizardAppearance', self.get("wizardAppearance".concat(wizardMode)));
      self.set('defaultWizardContext', (0, _array.A)([_object.default.create({
        name: wizardMode + ' Call Forward',
        elements: [{
          valuePath: 'divtype',
          EditDisable: true,
          disabledChoices: disabled_divtype_choices,
          computedBy: {
            element: 'divtype',
            propertyToUpdate: ['invalid', 'invalidText'],
            computeFunction: function computeFunction(wizard, computedByValue) {
              return _emberData.default.PromiseObject.create({
                promise: wizard.store.query('callcontrol', {
                  account: self.account,
                  divtype: computedByValue
                }).then(function (result) {
                  if (self.get('modelId') == 0) {
                    return {
                      invalid: result.length > 0,
                      invalidText: result.length > 0 ? "Call forward type already exists. Please edit the existing settings." : ""
                    };
                  } else {
                    return {
                      invalid: false,
                      invalidText: null
                    };
                  }
                })
              });
            }
          }
        }, {
          valuePath: 'advancedforwardcontrolid',
          type: 'choice',
          choices: choices,
          label: 'Advanced Forward Entry',
          visibleRequires: [{
            element: 'divtype',
            values: ['AFAB']
          }],
          mandatoryRequires: [{
            element: 'divtype',
            requireAny: [/^AFAB$/]
          }]
        }, {
          valuePath: 'number',
          visibleRequires: [{
            element: 'divtype',
            values: ['CFIM', 'CFNA', 'CFBS', '']
          }],
          mandatoryRequires: [{
            element: 'divtype',
            requireAny: [/^CFIM$/, /^CFNA$/, /^CFBS$/]
          }]
        }, {
          valuePath: 'account',
          hideElement: true
        }]
      })]));
    },
    actions: {
      toggleRemoveCallForward: function toggleRemoveCallForward() {
        this.toggleProperty('removeCallForwardShowing');
      },
      removeCallForward: function removeCallForward() {
        var record = _emberData.default.PromiseObject.create({
          promise: this.store.findRecord('callcontrol', this.get('modelId'), {
            backgroundReload: false
          })
        });
        var self = this;
        record.then(function (result) {
          result.destroyRecord().then(function () {
            self.toast.success('Removed Call Forwarding');
            self.toggleProperty('removeCallForwardShowing');
            self.send('handleWizardUpdate', true);
          }, function (err) {
            self.toast.error('Error removing Call Forwarding');
            self.toggleProperty('removeCallForwardShowing');
          });
        });
      }
    }
  });
  _exports.default = _default;
});