define("simtex-myaccount/controllers/authproceed", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    queryParams: ['status', 'access_expires_at', 'access_expires_in', 'refresh_expires_at', 'refresh_expires_in', 'username', 'role', 'session'],
    status: null,
    // username
    access_expires_at: null,
    // caller
    access_expires_in: null,
    refresh_expires_at: null,
    refresh_expires_in: null,
    username: null
  });
  _exports.default = _default;
});