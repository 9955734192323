define("simtex-myaccount/components/date-range-selector", ["exports", "@ember/component", "@ember/object", "@ember/runloop"], function (_exports, _component, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _component.default.extend((_obj = {
    selectedMonth: null,
    currentMonthLabel: '',
    start_gte: null,
    start_lt: null,
    start_gte_render: null,
    start_lt_render: null,
    datetimepicker_format: 'YYYY-MM-DDTHH:mm',
    datetimeserver_format_start: 'YYYY-MM-DD HH:mm:[00]',
    datetimeserver_format_end: 'YYYY-MM-DD HH:mm:[59]',
    init: function init() {
      this._super.apply(this, arguments);
      this.selectedMonth = new Date();
      this.setDateControls();
      this.currentMonthLabel = this.helpers.monthYearForDate(this.selectedMonth);
    },
    nextMonthLabel: (0, _object.computed)('selectedMonth', function () {
      return this.helpers.monthYearForDate(moment(this.selectedMonth).add(1, 'month'));
    }),
    lastMonthLabel: (0, _object.computed)('selectedMonth', function () {
      return this.helpers.monthYearForDate(moment(this.selectedMonth).subtract(1, 'month'));
    }),
    setDateControls: function setDateControls() {
      this.send('setStartGte', moment(this.get('selectedMonth')).startOf('month'));
      this.send('setStartLt', moment(this.get('selectedMonth')).endOf('month'));
      this.set('start_gte_render', moment(this.get('start_gte')).format(this.datetimepicker_format));
      this.set('start_lt_render', moment(this.get('start_lt')).format(this.datetimepicker_format));
      this.queueRefresh();
    },
    queueRefresh: function queueRefresh() {
      var timeout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 500;
      var self = this;
      (0, _runloop.later)(function () {
        self.parentView.send('refresh');
      }, timeout);
    },
    lastMonth: function lastMonth() {
      this.set('selectedMonth', new Date(moment(this.get('selectedMonth')).subtract(1, 'month')));
      this.setDateControls();
    },
    currentMonth: function currentMonth() {
      this.set('selectedMonth', new Date());
      this.setDateControls();
    },
    nextMonth: function nextMonth() {
      this.set('selectedMonth', new Date(moment(this.get('selectedMonth')).add(1, 'month')));
      this.setDateControls();
    },
    setStartGte: function setStartGte(dt) {
      this.set('start_gte', moment(dt).format(this.datetimeserver_format_start));
      this.parentView.set('start_gte', moment(dt).format(this.datetimeserver_format_start));
    },
    setStartLt: function setStartLt(dt) {
      this.set('start_lt', moment(dt).format(this.datetimeserver_format_end));
      this.parentView.set('start_lt', moment(dt).format(this.datetimeserver_format_start));
    }
  }, (_applyDecoratedDescriptor(_obj, "lastMonth", [_object.action], Object.getOwnPropertyDescriptor(_obj, "lastMonth"), _obj), _applyDecoratedDescriptor(_obj, "currentMonth", [_object.action], Object.getOwnPropertyDescriptor(_obj, "currentMonth"), _obj), _applyDecoratedDescriptor(_obj, "nextMonth", [_object.action], Object.getOwnPropertyDescriptor(_obj, "nextMonth"), _obj), _applyDecoratedDescriptor(_obj, "setStartGte", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setStartGte"), _obj), _applyDecoratedDescriptor(_obj, "setStartLt", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setStartLt"), _obj)), _obj));
  _exports.default = _default;
});