define("simtex-myaccount/templates/components/label-tips", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless (is-equal this.tip_text '')}}
      {{this.component_label}}
      <clr-icon shape="info-circle" class="is-solid">
          {{#ember-tooltip}}
              <div class="simtex-tooltips">{{this.tip_text}}</div>
          {{/ember-tooltip}}
      </clr-icon>
  {{else}}
      {{this.component_label}}:
  {{/unless}}
  
  */
  {
    "id": "NuEfEnmO",
    "block": "[[[41,[51,[28,[37,1],[[30,0,[\"tip_text\"]],\"\"],null]],[[[1,\"    \"],[1,[30,0,[\"component_label\"]]],[1,\"\\n    \"],[10,\"clr-icon\"],[14,\"shape\",\"info-circle\"],[14,0,\"is-solid\"],[12],[1,\"\\n\"],[6,[39,2],null,null,[[\"default\"],[[[[1,\"            \"],[10,0],[14,0,\"simtex-tooltips\"],[12],[1,[30,0,[\"tip_text\"]]],[13],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"component_label\"]]],[1,\":\\n\"]],[]]]],[],false,[\"unless\",\"is-equal\",\"ember-tooltip\"]]",
    "moduleName": "simtex-myaccount/templates/components/label-tips.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});