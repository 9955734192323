define("simtex-myaccount/templates/components/numbers/dids/did-table-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <button class="btn btn-link btn-simtex-table-action" title="Edit DID"
      {{action this.tableActions.editDID this.row bubbles=false}}>
      <FaIcon @icon='cogs' /> Edit DID
  </button>
  */
  {
    "id": "4Va4B+Qk",
    "block": "[[[11,\"button\"],[24,0,\"btn btn-link btn-simtex-table-action\"],[24,\"title\",\"Edit DID\"],[4,[38,0],[[30,0],[30,0,[\"tableActions\",\"editDID\"]],[30,0,[\"row\"]]],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\"],[\"cogs\"]],null],[1,\" Edit DID\\n\"],[13]],[],false,[\"action\",\"fa-icon\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/dids/did-table-actions.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});