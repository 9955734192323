define("simtex-myaccount/models/ipndsite", ["exports", "ember-data", "@ember/object", "@ember/service"], function (_exports, _emberData, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { collectionAction } from 'ember-api-actions';
  var _default = _emberData.default.Model.extend({
    __schema: (0, _service.inject)('schema'),
    serviceLocationSummary: (0, _object.computed)('servicebuildingtype', 'servicebuilding1stnr', 'servicebuilding1stsuffix', 'servicebuilding2ndnr', 'servicebuilding2ndsuffix', 'servicebuildingfloortype', 'servicebuildingfloornr', 'servicebuildingfloornrsuffix', 'servicebuildingproperty', 'servicebuildinglocation', 'servicestreethousenr1', 'servicestreethousenr1suffix', 'servicestreethousenr2', 'servicestreethousenr2suffix', 'servicestreetname1', 'servicestreetname2', 'servicestreettype1', 'servicestreetsuffix1', 'serviceaddresslocality', 'serviceaddressstate', 'serviceaddresspostcode', function () {
      var self = this;
      var buildSummary = function buildSummary() {
        var combined = [];

        // Don't check again for non-null schema, this is done below and if it is still pending
        // we are still awaiting a response from the API.
        if (!self.__schema.cache['ipndsite'].isPending) {
          // Define helper functions
          var getChoiceValue = function getChoiceValue(el) {
            var value = self[el] || '';
            if (self.__schema.cache['ipndsite'].get(el)) {
              var choice = self.__schema.cache['ipndsite'].get(el).choices.find(function (choice) {
                return choice.value === value;
              });
              if (choice !== null && choice.display_name !== null && value !== '') {
                // Return only that part of the display name which isn't the value 'helper' prefix.
                value = choice.display_name.replace(value + ' - ', '');
              }
            }
            return value;
          };

          // Now put summary together
          var serviceBuilding = [],
            serviceStreet = [],
            serviceLocality = [];
          var tmp1 = (getChoiceValue('servicebuildingtype') + ' ' + (self.servicebuilding1stnr || '') + (self.servicebuilding1stsuffix || '')).trim();
          var tmp2 = '';
          var servicebuilding2ndnr = self.servicebuilding2ndnr || '';
          if (servicebuilding2ndnr) tmp2 = ('-' + servicebuilding2ndnr + (self.servicebuilding2ndsuffix || '')).trim();
          serviceBuilding.push(tmp1 + tmp2);
          serviceBuilding.push((getChoiceValue('servicebuildingfloortype') + ' ' + (self.servicebuildingfloornr || '') + (self.servicebuildingfloornrsuffix || '')).trim());
          serviceBuilding.push(self.servicebuildingproperty || '');
          serviceBuilding.push(self.servicebuildinglocation || '');
          tmp1 = ((self.servicestreethousenr1 || '') + (self.servicestreethousenr1suffix || '')).trim();
          tmp2 = '';
          var servicestreethousenr2 = self.servicestreethousenr2 || '';
          if (servicestreethousenr2) tmp2 = ('-' + servicestreethousenr2 + (self.servicestreethousenr2suffix || '')).trim();
          serviceStreet.push(tmp1 + tmp2);
          serviceStreet.push(self.servicestreetname1 || '');
          serviceStreet.push(self.servicestreetname2 || ''); // Street Name 1 overflow
          serviceStreet.push(getChoiceValue('servicestreettype1'));
          serviceStreet.push(self.servicestreetsuffix1 || '');
          serviceLocality.push(self.serviceaddresslocality || '');
          serviceLocality.push(self.serviceaddressstate || '');
          serviceLocality.push(self.serviceaddresspostcode || '');
          combined.push(serviceBuilding.filter(function (w) {
            return w.length > 0;
          }).join(', '));
          combined.push(serviceStreet.filter(function (w) {
            return w.length > 0;
          }).join(' '));
          combined.push(serviceLocality.filter(function (w) {
            return w.length > 0;
          }).join(' '));
          combined = combined.filter(function (w) {
            return w.length > 0;
          });
        }
        return combined;
      };
      return _emberData.default.PromiseObject.create({
        promise: Ember.RSVP.Promise.cast(self.__schema.cache['ipndsite']).then(function () {
          return buildSummary();
        })
      });
    }),
    ipndsiteid: _emberData.default.attr(),
    owner: _emberData.default.attr(),
    label: _emberData.default.attr('string'),
    listcode: _emberData.default.attr(),
    usagecode: _emberData.default.attr(),
    typeofservice: _emberData.default.attr(),
    customername1: _emberData.default.attr('string'),
    customername2: _emberData.default.attr('string'),
    longname: _emberData.default.attr('string'),
    customertitle: _emberData.default.attr('string'),
    findingname1: _emberData.default.attr('string'),
    findingname2: _emberData.default.attr('string'),
    findingtitle: _emberData.default.attr('string'),
    servicebuildingtype: _emberData.default.attr('string'),
    servicebuilding1stnr: _emberData.default.attr('string'),
    servicebuilding1stsuffix: _emberData.default.attr('string'),
    servicebuilding2ndnr: _emberData.default.attr('string'),
    servicebuilding2ndsuffix: _emberData.default.attr('string'),
    servicebuildingfloortype: _emberData.default.attr('string'),
    servicebuildingfloornr: _emberData.default.attr('string'),
    servicebuildingfloornrsuffix: _emberData.default.attr('string'),
    servicebuildingproperty: _emberData.default.attr('string'),
    servicebuildinglocation: _emberData.default.attr('string'),
    servicestreethousenr1: _emberData.default.attr('string'),
    servicestreethousenr1suffix: _emberData.default.attr('string'),
    servicestreethousenr2: _emberData.default.attr('string'),
    servicestreethousenr2suffix: _emberData.default.attr('string'),
    servicestreetname1: _emberData.default.attr('string'),
    servicestreettype1: _emberData.default.attr('string'),
    servicestreetsuffix1: _emberData.default.attr('string'),
    servicestreetname2: _emberData.default.attr('string'),
    servicestreettype2: _emberData.default.attr('string'),
    servicestreetsuffix2: _emberData.default.attr('string'),
    serviceaddresslocality: _emberData.default.attr('string'),
    serviceaddressstate: _emberData.default.attr('string'),
    serviceaddresspostcode: _emberData.default.attr('string'),
    directorybuildingtype: _emberData.default.attr('string'),
    directorybuilding1stnr: _emberData.default.attr('string'),
    directorybuilding1stsuffix: _emberData.default.attr('string'),
    directorybuilding2ndnr: _emberData.default.attr('string'),
    directorybuilding2ndsuffix: _emberData.default.attr('string'),
    directorybuildingfloortype: _emberData.default.attr('string'),
    directorybuildingfloornr: _emberData.default.attr('string'),
    directorybuildingfloornrsuffix: _emberData.default.attr('string'),
    directorybuildingproperty: _emberData.default.attr('string'),
    directorybuildinglocation: _emberData.default.attr('string'),
    directorystreethousenr1: _emberData.default.attr('string'),
    directorystreethousenr1suffix: _emberData.default.attr('string'),
    directorystreethousenr2: _emberData.default.attr('string'),
    directorystreethousenr2suffix: _emberData.default.attr('string'),
    directorystreetname1: _emberData.default.attr('string'),
    directorystreettype1: _emberData.default.attr('string'),
    directorystreetsuffix1: _emberData.default.attr('string'),
    directorystreetname2: _emberData.default.attr('string'),
    directorystreettype2: _emberData.default.attr('string'),
    directorystreetsuffix2: _emberData.default.attr('string'),
    directoryaddresslocality: _emberData.default.attr('string'),
    directoryaddressstate: _emberData.default.attr('string'),
    directoryaddresspostcode: _emberData.default.attr('string'),
    alternateaddressflag: _emberData.default.attr('boolean'),
    customercontactname1: _emberData.default.attr('string'),
    customercontactname2: _emberData.default.attr('string'),
    customercontactnr: _emberData.default.attr('string')
  });
  _exports.default = _default;
});