define("simtex-myaccount/serializers/account", ["exports", "simtex-myaccount/serializers/drf", "@ember/service", "ember-data", "@ember/utils"], function (_exports, _drf, _service, _emberData, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: "accountid",
    simtex: (0, _service.inject)('simtex'),
    attrs: {
      status: {
        embedded: 'always'
      },
      product_data: {
        embedded: 'always'
      },
      did_data: {
        embedded: 'always'
      },
      voicemail_data: {
        embedded: 'always'
      },
      dialrestriction_data: {
        embedded: 'always'
      },
      channellimit_data: {
        embedded: 'always'
      },
      transport_data: {
        embedded: 'always'
      },
      numberpresentation_data: {
        embedded: 'always'
      },
      endpointaccount_data: {
        embedded: 'always'
      },
      callcontrol_data: {
        embedded: 'always'
      },
      callaccounting_data: {
        embedded: 'always'
      },
      callrecording_data: {
        embedded: 'always'
      },
      advancedforwardcontrol_data: {
        embedded: 'always'
      },
      musiconholdslot_data: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, resourceHash) {
      if (resourceHash.account_addon && resourceHash.account_addon.call_recording && resourceHash.account_addon.call_recording.addon_parameters) {
        var _resourceHash$account, _resourceHash$account2, _resourceHash$product, _resourceHash$product2;
        resourceHash.callrecording_data = {
          account_id: resourceHash.accountid,
          callrecord_inbound: resourceHash.account_addon.call_recording.addon_parameters.CallRecordIngress,
          callrecord_outbound: resourceHash.account_addon.call_recording.addon_parameters.CallRecordEgress,
          callrecording_prefixblacklist: resourceHash.account_addon.call_recording.addon_parameters.CallRecordBlacklistPrefixes,
          producttype_id: (_resourceHash$account = resourceHash.account_addon.call_recording) === null || _resourceHash$account === void 0 ? void 0 : (_resourceHash$account2 = _resourceHash$account.product_type) === null || _resourceHash$account2 === void 0 ? void 0 : _resourceHash$account2.producttypeid,
          producttype_id_domain: (_resourceHash$product = resourceHash.product_data) === null || _resourceHash$product === void 0 ? void 0 : (_resourceHash$product2 = _resourceHash$product.producttype) === null || _resourceHash$product2 === void 0 ? void 0 : _resourceHash$product2.producttypeid
        };
      } else {
        resourceHash.callrecording_data = {
          account_id: resourceHash.accountid,
          callrecord_inbound: 0,
          callrecord_outbound: 0,
          callrecording_prefixblacklist: '',
          producttype_id: null
        };
      }
      return this._super(modelClass, resourceHash);
    },
    extractMeta: function extractMeta(store, type, payload) {
      var meta = this._super(store, type, payload);
      return this.simtex.extract_meta(meta, type, payload);
    }
  });
  _exports.default = _default;
});