define("simtex-myaccount/templates/components/accounts/test-table", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#light-table table as |t|}}
    {{t.head}}
    {{t.body}}
  {{/light-table}}
  */
  {
    "id": "axfEe1km",
    "block": "[[[6,[39,0],[[33,1]],null,[[\"default\"],[[[[1,\"  \"],[1,[30,1,[\"head\"]]],[1,\"\\n  \"],[1,[30,1,[\"body\"]]],[1,\"\\n\"]],[1]]]]]],[\"t\"],false,[\"light-table\",\"table\"]]",
    "moduleName": "simtex-myaccount/templates/components/accounts/test-table.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});