define("simtex-myaccount/models/zerotouchjob", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    zerotouchjobid: _emberData.default.attr(),
    endpoint: _emberData.default.belongsTo('endpoint', {
      async: true
    }),
    owner: _emberData.default.attr(),
    zerotouchjobstatus_label: _emberData.default.attr(),
    originalzerotouchstate_label: _emberData.default.attr(),
    updatedzerotouchstate_label: _emberData.default.attr(),
    createddatetime: _emberData.default.attr(),
    zerotouchjoberror: _emberData.default.attr(),
    smile_data: _emberData.default.attr()
  });
  _exports.default = _default;
});