define("simtex-myaccount/serializers/endpointlinekeytype", ["exports", "simtex-myaccount/serializers/drf", "ember-data", "@ember/utils"], function (_exports, _drf, _emberData, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: "endpointlinekeytypeid",
    attrs: {}
  });
  _exports.default = _default;
});