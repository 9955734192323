define("simtex-myaccount/models/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inflector = _emberInflector.default.inflector;

  // Tell the inflector that the plural of "account" is "account"
  inflector.uncountable('account');
  inflector.uncountable('owner');
  inflector.uncountable('voicemail');
  inflector.uncountable('dialrestriction');
  inflector.uncountable('channellimit');
  inflector.uncountable('transport');
  inflector.uncountable('numberpresentation');
  inflector.uncountable('did');
  inflector.uncountable('didhistory');
  inflector.uncountable('didroute');
  inflector.uncountable('accountstatuschoices');
  inflector.uncountable('countrychoices');
  inflector.uncountable('locationchoices');
  inflector.uncountable('ipndsite');
  inflector.uncountable('endpointaccount');
  inflector.uncountable('endpointdialplan');
  inflector.uncountable('endpointexpansionallowed');
  inflector.uncountable('endpointexpansiontype');
  inflector.uncountable('endpointlinekey');
  inflector.uncountable('endpointlinekeytypechoices');
  inflector.uncountable('endpoint');
  inflector.uncountable('endpointexpansion');
  inflector.uncountable('endpointmodel');
  inflector.uncountable('endpointmodeltype');
  inflector.uncountable('configdefinitiongroup');
  inflector.uncountable('configdefinition');
  inflector.uncountable('configstore');
  inflector.uncountable('callaccounting');
  inflector.uncountable('callrecording');
  inflector.uncountable('callcontrol');
  inflector.uncountable('callforwardreport');
  inflector.uncountable('advancedforwardcontrol');
  inflector.uncountable('broadcastgroup');
  inflector.uncountable('broadcastmember');
  inflector.uncountable('zerotouchjob');
  inflector.uncountable('cdrdata');
  inflector.uncountable('dialrestrictiontier');

  // Modules must have an export, so we just export an empty object here
  var _default = {};
  _exports.default = _default;
});