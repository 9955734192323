define("simtex-myaccount/components/light-table-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: '',
    icon: (0, _object.computed)('sortIcons', 'sortIconProperty', function () {
      return (0, _object.get)(this.sortIcons, this.sortIconProperty);
    })
  });
  _exports.default = _default;
});