define("simtex-myaccount/components/check-heartbeat", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/runloop"], function (_exports, _component, _object, _service, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _component.default.extend((_obj = {
    simtex: (0, _service.inject)('simtex'),
    recheck_disabled: false,
    last_check: (0, _object.computed)('simtex.heartbeat_lastok', 'simtex.heartbeat_lastnok', function () {
      var lastok = this.get('simtex.heartbeat_lastok');
      var lastnok = this.get('simtex.heartbeat_lastnok');
      return moment(new Date(Math.max(lastok, lastnok))).format('h:mm:ss A');
    }),
    toggle_recheck_disabled: function toggle_recheck_disabled() {
      this.set('recheck_disabled', !this.get('recheck_disabled'));
    },
    force_check_heartbeat: function force_check_heartbeat() {
      this.set('recheck_disabled', true);
      var self = this;
      this.get('simtex').force_check_heartbeat().then(function () {
        // Prevent spamming force checks
        (0, _runloop.later)(self, self.toggle_recheck_disabled, 3000);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "force_check_heartbeat", [_object.action], Object.getOwnPropertyDescriptor(_obj, "force_check_heartbeat"), _obj)), _obj));
  _exports.default = _default;
});