define("simtex-myaccount/components/light-table-expansion-action", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    actions: {
      toggleExpandedRow: function toggleExpandedRow(row) {
        var shouldExpand = !row.expanded;
        row.toggleProperty('expanded');
      }
    }
  });
  _exports.default = _default;
});