define("simtex-myaccount/components/subscriptions/callrecording-data-BKUPFILE", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object", "@ember/service"], function (_exports, _component, _modelSaverCommon, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Call Recording',
    store: (0, _service.inject)(),
    callrecord_inbound_status: (0, _object.computed)('model.callrecord_inbound', {
      get: function get() {
        return this.model.get('callrecord_inbound') ? 1 : 0;
      },
      set: function set(key, value) {
        this.model.set('callrecord_inbound', !!value);
        return value ? 1 : 0;
      }
    }),
    callrecord_outbound_status: (0, _object.computed)('model.callrecord_outbound', {
      get: function get() {
        return this.model.get('callrecord_outbound') ? 1 : 0;
      },
      set: function set(key, value) {
        this.model.set('callrecord_outbound', !!value);
        return value ? 1 : 0;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.init_blacklist_callrecording();
      if (this.simtex.is_admin) {
        this.get_iddtier_list_callrecording();
      }
    },
    init_blacklist_callrecording: function init_blacklist_callrecording() {
      var _this = this;
      this.callrecording_get_prefixblacklist = Ember.Object.create({
        prefixes: Ember.A([])
      });
      if (this.model.get('callrecording_prefixblacklist')) {
        var blacklist = this.model.get('callrecording_prefixblacklist').split('|');
        blacklist.forEach(function (prefix) {
          return _this.callrecording_get_prefixblacklist.prefixes.pushObject(prefix);
        });
      }
    },
    actions: {
      callrecordingAddBlacklistPrefixLabel: function callrecordingAddBlacklistPrefixLabel(value) {
        if (value) {
          var blacklist = this.model.get('callrecording_prefixblacklist') || '';
          var list = blacklist.split('|');
          if (!list.includes(value)) {
            this.callrecording_get_prefixblacklist.prefixes.pushObject(value);
            this.set('callrecording_prefixblacklist_text', '');
          }
          this.model.set('callrecording_prefixblacklist', this.callrecording_get_prefixblacklist.prefixes.join('|'));
        }
      },
      callrecordingRemoveBlacklistPrefixLabel: function callrecordingRemoveBlacklistPrefixLabel(value) {
        this.callrecording_get_prefixblacklist.prefixes.removeObject(value);
        this.model.set('callrecording_prefixblacklist', this.callrecording_get_prefixblacklist.prefixes.join('|'));
      }
    }
  });
  _exports.default = _default;
});