define("simtex-myaccount/templates/components/light-table-expansion-action", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (is-equal this.row.expanded true)}}
      <a class="btn btn-sm btn-link" {{action "toggleExpandedRow" row}} style="display: initial;">
          <clr-icon shape="minus"></clr-icon>
      </a>
  {{else}}
      <a class="btn btn-sm btn-link" {{action "toggleExpandedRow" row}} style="display: initial;">
          <clr-icon shape="plus"></clr-icon>
      </a>
  {{/if}}
  */
  {
    "id": "vUoBxSNj",
    "block": "[[[41,[28,[37,1],[[30,0,[\"row\",\"expanded\"]],true],null],[[[1,\"    \"],[11,3],[24,0,\"btn btn-sm btn-link\"],[24,5,\"display: initial;\"],[4,[38,2],[[30,0],\"toggleExpandedRow\",[33,3]],null],[12],[1,\"\\n        \"],[10,\"clr-icon\"],[14,\"shape\",\"minus\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,3],[24,0,\"btn btn-sm btn-link\"],[24,5,\"display: initial;\"],[4,[38,2],[[30,0],\"toggleExpandedRow\",[33,3]],null],[12],[1,\"\\n        \"],[10,\"clr-icon\"],[14,\"shape\",\"plus\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"is-equal\",\"action\",\"row\"]]",
    "moduleName": "simtex-myaccount/templates/components/light-table-expansion-action.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});