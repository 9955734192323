define("simtex-myaccount/templates/components/numbers/dids/did-table-translation-col", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.display_target_did_col}}
      <div class="clr-input-wrapper" style="margin-top: -0.5rem;">
          {{translated_did_label}}
          <Numbers::Dids::DidTranslationDialog @model={{this.args.row}}/>
      </div>
  {{else}}
      {{translated_did_label}}
  {{/if}}
  */
  {
    "id": "xifiU8ts",
    "block": "[[[41,[30,0,[\"display_target_did_col\"]],[[[1,\"    \"],[10,0],[14,0,\"clr-input-wrapper\"],[14,5,\"margin-top: -0.5rem;\"],[12],[1,\"\\n        \"],[1,[34,1]],[1,\"\\n        \"],[8,[39,2],null,[[\"@model\"],[[30,0,[\"args\",\"row\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,1]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"translated_did_label\",\"numbers/dids/did-translation-dialog\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/dids/did-table-translation-col.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});