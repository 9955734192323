define("simtex-myaccount/components/subscriptions/subscription/handset-summary-data", ["exports", "@ember/component", "ember-data", "@ember/object", "@ember/service", "@ember/template", "simtex-myaccount/config/environment", "fetch"], function (_exports, _component, _emberData, _object, _service, _template, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    object_name: 'Handset Summary',
    jobstatushtml: '',
    running_jobs: [],
    zerotouchtypes: {
      'ZEROTOUCH_DISABLED': 1,
      'POLYCOM_ZEROTOUCH': 2,
      'YEALINK_ZEROTOUCH': 3
    },
    primary_endpointaccount: (0, _object.computed)(function () {
      var self = this;
      var endpointaccount_promise = _emberData.default.PromiseObject.create({
        promise: self.store.query('endpointaccount', {
          endpoint__endpointid: self.get('model.endpoint.endpointid'),
          index: 1
        })
      }).then(function (results) {
        var account_promise = self.get('model.account');
        if (results.length == 1) {
          results.forEach(function (result) {
            account_promise = _emberData.default.PromiseObject.create({
              promise: result.account
            });
          });
        }
        return account_promise;
      });
      return _emberData.default.PromiseObject.create({
        promise: endpointaccount_promise.then(function (account) {
          return account.internalextension;
        })
      });
    }),
    ztp_provisionconfiglabel: null,
    ztp_generationtimestamp: null,
    ztp_zerotouchstatelabel: null,
    ztp_zerotouchmodifieddate: null,
    ztp_zerotouchdevicelastaccess: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.refreshZtpStatus();
    },
    get_jobstatus_html: (0, _object.computed)(function () {
      this.getZTJobStatus(0);
      this.getZTJobStatusLoop(0);
    }),
    get_endpointdialplanareanames: (0, _object.computed)(function () {
      var self = this;
      return _emberData.default.PromiseObject.create({
        promise: this.store.query('endpointdialplan', {
          'page_size': 50,
          'endpointmodeltype__endpointmodeltypeid': this.model.get('endpoint.endpointmodel.endpointmodeltype.endpointmodeltypeid')
        }).then(function (result) {
          var dialplans = [];
          var result_array = result.toArray().filter(function (x) {
            return (x.areaname || '').endsWith('TCP') || x.areaname === self.model.get('endpoint.endpointdialplan.areaname');
          });
          for (var i = 0, len = result_array.length; i < len; i++) {
            dialplans.push({
              endpointdialplanid: result_array[i].endpointdialplanid,
              areaname: result_array[i].areaname
            });
          }
          return dialplans;
        })
      });
    }),
    getZTJobStatusLoop: function getZTJobStatusLoop(counter) {
      var self = this;
      var timeoutvar = null;
      // this loop should only run in this current page
      if (counter <= 60 && this.matchEndpointWithCurrentRoute()) {
        timeoutvar = setTimeout(function () {
          self.getZTJobStatus();
          self.getZTJobStatusLoop(counter);
        }, 3000);
      } else {
        clearTimeout(timeoutvar);
      }
      counter++;
    },
    getZTJobStatus: function getZTJobStatus() {
      this.getRunningZTPJobs();
      this.refreshZtpStatus();
    },
    getRunningZTPJobs: function getRunningZTPJobs() {
      var _this = this;
      var self = this;
      var running_job_obj = null;
      var running_jobs_local = this.get('running_jobs');
      var jobstatushtml_local = '';

      //Get all running jobs for this endpoint
      _emberData.default.PromiseObject.create({
        promise: self.store.query('zerotouchjob', {
          endpoint: self.get('model.endpoint.endpointid'),
          jobstatus: '1,2',
          ordering: 'createdtime'
        }).then(function (jobs) {
          if (jobs.length > 0) {
            jobs.forEach(function (job) {
              if (job.endpoint.content.endpointid == _this.router.currentRoute.params.endpoint_id - 2100000) {
                var html = "<div class=\"spinner spinner-sm\"></div> Running - ".concat(job.originalzerotouchstate_label, " - ID#: ").concat(job.zerotouchjobid, " Status: ").concat(job.zerotouchjobstatus_label, "<br>");
                jobstatushtml_local = jobstatushtml_local + html;
              }
            });
          } else {
            jobstatushtml_local = '';
          }
          if (!self.isDestroyed) {
            self.set('jobstatushtml', (0, _template.htmlSafe)(jobstatushtml_local));
          }
        })
      });
    },
    matchEndpointWithCurrentRoute: function matchEndpointWithCurrentRoute() {
      var match = false;
      if (this.router.currentRouteName == 'restricted.subscriptions.subscription.handset') {
        if (this.router.currentRoute.params.endpoint_id - 2100000 == this.get('model.endpoint.endpointid')) {
          match = true;
        }
      }
      return match;
    },
    refreshZtpStatus: function refreshZtpStatus() {
      var _this2 = this;
      var self = this;
      if (!self.isDestroyed) {
        var remove_fields = ['endpointaccount_data', 'endpointexpansion_data', 'configdefinitiongroup_data', 'endpointmodel', 'endpointdialplan', 'adminpassword', 'macaddress', 'label', 'debuglevel', 'customconfig', 'handsetlogoopt', 'logourl', 'logotext', 'customdialplan', 'customdigitmaptimeout'];
        var remove_fields_str = '';
        remove_fields.forEach(function (field) {
          remove_fields_str = remove_fields_str + "remove_fields[]=".concat(field, "&");
        });
        return (0, _fetch.default)("".concat(_environment.default.APP.API_HOST, "/endpoint/").concat(self.get('model.endpoint.endpointid'), "/?").concat(remove_fields_str), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          mode: 'cors',
          credentials: 'include'
        }).then(function (response) {
          return response.json();
        }).then(function (results) {
          if (!self.isDestroyed) {
            self.set('ztp_provisionconfiglabel', results.provisionconfig_label);
            self.set('ztp_zerotouchstatelabel', results.zerotouchstate_label);
            self.set('ztp_generationtimestamp', results.generationtimestamp ? _this2.simtex.formatDateWithTZ(new Date(results.generationtimestamp)) : null);
            self.set('ztp_zerotouchmodifieddate', results.zerotouchmodifieddate ? _this2.simtex.formatDateWithTZ(new Date(results.zerotouchmodifieddate)) : null);
            self.set('ztp_zerotouchdevicelastaccess', results.zerotouchdevicelastaccess ? _this2.simtex.formatDateWithTZ(new Date(results.zerotouchdevicelastaccess)) : null);
          }
        });
      }
    }
  });
  _exports.default = _default;
});