define("simtex-myaccount/templates/restricted/callrouting", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  <nav class="sidenav">
      <section class="sidenav-content">
          <section class="nav-group">
              <label>{{this.simtex.owner_description}}</label>
              <ul class="nav-list">
                  <li>
                      <LinkTo @route='restricted.callrouting.callforwarding' @model={{this.simtex.get_claims_account}} class="nav-link">Call Forwarding</LinkTo>
                  </li>
                  <li>
                      <span class="nav-text" style="padding-left: 0.5rem;">Call Routing</span>
                      <ul class="nav-list" style="padding: 0;">
                          <li>
                              <LinkTo @route='restricted.callrouting.ringgroups' @model={{this.simtex.get_claims_account}} class="nav-link">Ring Groups</LinkTo>
                          </li>
                          <!--<li><a class="nav-link">Address Book</a></li>
                          <li><a class="nav-link">Call Control Menus</a></li>
                          <li><a class="nav-link">Interactive Voice Response (IVR)</a></li>-->
                      </ul>
                  </li>
                  <!--<li><a class="nav-link">Directories</a></li>-->
              </ul>
          </section>
      </section>
  </nav>
  */
  {
    "id": "ScHQj6f2",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[10,\"nav\"],[14,0,\"sidenav\"],[12],[1,\"\\n    \"],[10,\"section\"],[14,0,\"sidenav-content\"],[12],[1,\"\\n        \"],[10,\"section\"],[14,0,\"nav-group\"],[12],[1,\"\\n            \"],[10,\"label\"],[12],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"nav-list\"],[12],[1,\"\\n                \"],[10,\"li\"],[12],[1,\"\\n                    \"],[8,[39,2],[[24,0,\"nav-link\"]],[[\"@route\",\"@model\"],[\"restricted.callrouting.callforwarding\",[30,0,[\"simtex\",\"get_claims_account\"]]]],[[\"default\"],[[[[1,\"Call Forwarding\"]],[]]]]],[1,\"\\n                \"],[13],[1,\"\\n                \"],[10,\"li\"],[12],[1,\"\\n                    \"],[10,1],[14,0,\"nav-text\"],[14,5,\"padding-left: 0.5rem;\"],[12],[1,\"Call Routing\"],[13],[1,\"\\n                    \"],[10,\"ul\"],[14,0,\"nav-list\"],[14,5,\"padding: 0;\"],[12],[1,\"\\n                        \"],[10,\"li\"],[12],[1,\"\\n                            \"],[8,[39,2],[[24,0,\"nav-link\"]],[[\"@route\",\"@model\"],[\"restricted.callrouting.ringgroups\",[30,0,[\"simtex\",\"get_claims_account\"]]]],[[\"default\"],[[[[1,\"Ring Groups\"]],[]]]]],[1,\"\\n                        \"],[13],[1,\"\\n                        \"],[3,\"<li><a class=\\\"nav-link\\\">Address Book</a></li>\\n                        <li><a class=\\\"nav-link\\\">Call Control Menus</a></li>\\n                        <li><a class=\\\"nav-link\\\">Interactive Voice Response (IVR)</a></li>\"],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n                \"],[3,\"<li><a class=\\\"nav-link\\\">Directories</a></li>\"],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"link-to\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/callrouting.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});