define("simtex-myaccount/templates/components/numbers/sites/did-site-buckets", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!slush_bucket_context defined in /components/numbers/sites/did-site-buckets}}
  {{slush-buckets
      model=this.model
      slush_bucket_context=this.slush_bucket_context}}
  */
  {
    "id": "SW23rLGT",
    "block": "[[[1,[28,[35,0],null,[[\"model\",\"slush_bucket_context\"],[[30,0,[\"model\"]],[30,0,[\"slush_bucket_context\"]]]]]]],[],false,[\"slush-buckets\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/sites/did-site-buckets.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});