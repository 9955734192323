define("simtex-myaccount/components/subscriptions/subscription/handset-keys-dialog", ["exports", "@ember/component", "@ember/object", "simtex-myaccount/helpers/string-is-empty"], function (_exports, _component, _object, _stringIsEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    key: null,
    lineKeyTypes: null,
    keyInvalid: (0, _object.computed)('key.endpointlinekeytype', 'key.label', 'key.dialstring', 'key.linkedaccount', function () {
      var invalid = false;
      switch (this.key.endpointlinekeytype) {
        case this.simtex.LINEKEYTYPE.SPEED_DIAL:
        case this.simtex.LINEKEYTYPE.SPECIAL_BLF:
          invalid = (0, _stringIsEmpty.stringIsEmpty)(this.key.label || '') || (0, _stringIsEmpty.stringIsEmpty)(this.key.dialstring || '');
          break;
        case this.simtex.LINEKEYTYPE.BLF:
          invalid = this.key.linkedaccount === null;
          break;
      }
      return invalid;
    }),
    lineKeyType: (0, _object.computed)('key.endpointlinekeytype', function () {
      var _this = this;
      if (this.key && this.lineKeyTypes) {
        var keyType = this.lineKeyTypes.find(function (k) {
          return k.endpointlinekeytypeid === _this.key.endpointlinekeytype;
        });
        return keyType ? keyType.name : '';
      }
    }),
    actions: {
      toggleEditKey: function toggleEditKey(key) {
        key.toggleProperty('editingKey');
      },
      revertEditKey: function revertEditKey(key) {
        key.rollbackAttributes();
        //key.rollback();
        key.toggleProperty('editingKey');
      },
      validateInput: function validateInput(obj, element, currentVal, event) {
        var val = obj.get(element);
        switch (element) {
          case 'dialstring':
            var regex = /[^0-9#*]/gi;
            obj.set(element, (val || '').replace(regex, ''));
            break;
        }
      }
    }
  });
  _exports.default = _default;
});