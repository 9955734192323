define("simtex-myaccount/components/numbers/dids/did-clidstamping-buckets", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    slush_bucket_context: {
      // Reference data
      // Model name for primary bucket
      ref_model_primary: 'did',
      ref_model_primary_backend: 'phoneid',
      ref_model_primary_type: 'number',
      ref_model_primary_remove_fields: ['carrier', 'carriername', 'country', 'didhistory', 'jsondata', 'lastupdated', 'endpoint', 'faxrouting', 'historynotes', 'ipndsite', 'location', 'notes', 'url'],
      ref_model_primary_ordering: 'did',
      ref_model_primary_optionval: 'did',
      // 'id',
      ref_model_primary_optionlabel: '${did}',
      ref_model_primary_optionlabeldict: {
        'did': 'did'
      },
      ref_model_primary_filter_dict: null,
      // Model name for allocation buckets
      ref_model_allocation: 'did',
      ref_model_allocation_remove_fields: ['didhistoryjson', 'jsondata', 'ipndsite', 'faxrouting'],
      ref_model_allocation_ordering: 'did',
      // Property on the allocation model linking it with the primary model
      ref_model_allocation_primaryrefid: 'clidstampphone_did',
      // account.id
      ref_model_allocation_primaryrefobj: 'clidstampphone',
      ref_model_allocation_optionval: 'did',
      // Used in Allocation bucket to describe what the object is allocated to.
      // If null, defaults to primary object label.
      bucket_selected_primary_descriptionref: 'bucket_selected_primary',
      allow_shared_allocations_per_primary: false,
      hide_allocated_in_unallocated_for_superuser: false,
      // If true, shows a modal prompt with OK/Cancel and body text described by modal_prompt_deallocate
      // show_modal_prompt_deallocate: true,

      strings: {
        primary_bucket_title: 'Target Caller ID',
        allocated_bucket_title: 'Source Caller ID',
        unallocated_bucket_title: 'DIDs not Over-Stamped',
        allocate_button_text: 'Add Stamping',
        deallocate_button_text: 'Remove Stamping',
        // modal_prompt_deallocate: this.simtex.STRINGS.REMOVE_ENDPOINTACCT_WARNING,
        prompt_primary_noresults: 'No DIDs are available',
        prompt_allocated_empty_noprimary: 'Select a target DID to view its over-stamped DIDs',
        prompt_allocated_empty_noresults: 'No DIDs currently over-stamped to this DID',
        prompt_unallocated_empty: 'No over-stamped DIDs',
        saved_allocations_success: 'Saved DID over-stamping',
        saved_allocations_failure: 'Error saving DID over-stamping'
      }
    }
  });
  _exports.default = _default;
});