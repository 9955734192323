define("simtex-myaccount/models/transport", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    account_id: _emberData.default.attr(),
    codecpreferences: _emberData.default.attr(),
    iaxserver: _emberData.default.attr(),
    staticip: _emberData.default.attr(),
    staticport: _emberData.default.attr(),
    ivr: _emberData.default.attr('boolean'),
    senddid: _emberData.default.attr('boolean'),
    maxcontacts: _emberData.default.attr(),
    directmedia: _emberData.default.attr('boolean'),
    natenable: _emberData.default.attr('boolean'),
    iceenable: _emberData.default.attr('boolean'),
    qosenable: _emberData.default.attr('boolean'),
    qualifybypass: _emberData.default.attr('boolean'),
    sendrpid: _emberData.default.attr('boolean'),
    ringtime: _emberData.default.attr(),
    nightmode: _emberData.default.attr('boolean')
  });
  _exports.default = _default;
});