define("simtex-myaccount/templates/restricted/handsets/mapping", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  {{page-title 'Devices Subscription Mapping (' this.simtex.owner_description ')'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top:0">Subscription Mapping for Handsets and Devices for {{this.simtex.owner_description}}</h2>
              {{handsets/handset-subscription-buckets
                      model=this.model
                      select_primary=this.select_primary
                      allow_shared_allocations_per_primary=true
              }}{{! allow_shared_allocations_per_primary true: Allow accounts to be shared between endpoints }}
          </div>
      </div>
  </div>
  */
  {
    "id": "fspK7rJE",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[1,[28,[35,2],[\"Devices Subscription Mapping (\",[30,0,[\"simtex\",\"owner_description\"]],\")\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top:0\"],[12],[1,\"Subscription Mapping for Handsets and Devices for \"],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n            \"],[1,[28,[35,3],null,[[\"model\",\"select_primary\",\"allow_shared_allocations_per_primary\"],[[30,0,[\"model\"]],[30,0,[\"select_primary\"]],true]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"page-title\",\"handsets/handset-subscription-buckets\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/handsets/mapping.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});