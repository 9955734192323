define("simtex-myaccount/templates/components/light-table-empty-results", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.hasResults}}
      {{#if this.isIdle}}
      <div style="margin: 1rem;" class="col-md-6 text-center">
          {{#unless this.canLoadMore}}
              {{get-coalesce this.noResultsMessage 'No results found'}}
          {{else}}
              Please provide a search term and hit ENTER
          {{/unless}}
      </div>
      {{/if}}
  {{/unless}}
  */
  {
    "id": "27+ASC/l",
    "block": "[[[41,[51,[30,0,[\"hasResults\"]]],[[[41,[30,0,[\"isIdle\"]],[[[1,\"    \"],[10,0],[14,5,\"margin: 1rem;\"],[14,0,\"col-md-6 text-center\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"canLoadMore\"]]],[[[1,\"            \"],[1,[28,[35,2],[[30,0,[\"noResultsMessage\"]],\"No results found\"],null]],[1,\"\\n\"]],[]],[[[1,\"            Please provide a search term and hit ENTER\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"unless\",\"if\",\"get-coalesce\"]]",
    "moduleName": "simtex-myaccount/templates/components/light-table-empty-results.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});