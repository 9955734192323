define("simtex-myaccount/templates/components/numbers/dids/did-translation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="card simtex-card">
      <div class="card-header">
          DID Translation
      </div>
      <div class="card-block">
          <div class="card-text">
              {{@model.did}} is currently translated into
              <Numbers::Dids::DidLink @owner={{@model.owner}} @linked_did={{@model.translatedphone_did}}
                  @display_did={{@model.translatedphone_did}} />
          </div>
      </div>
      <div class="card-footer">
          <div style="display: inline">
              <div style="display: inline-block">
                  <Numbers::Dids::DidhistoryActions @modalMode='Remove Translation' @model={{@model}}/>
              </div>
          </div>
      </div>
  </div>
  */
  {
    "id": "KM21fHYa",
    "block": "[[[10,0],[14,0,\"card simtex-card\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n        DID Translation\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"card-block\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"card-text\"],[12],[1,\"\\n            \"],[1,[30,1,[\"did\"]]],[1,\" is currently translated into\\n            \"],[8,[39,0],null,[[\"@owner\",\"@linked_did\",\"@display_did\"],[[30,1,[\"owner\"]],[30,1,[\"translatedphone_did\"]],[30,1,[\"translatedphone_did\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"card-footer\"],[12],[1,\"\\n        \"],[10,0],[14,5,\"display: inline\"],[12],[1,\"\\n            \"],[10,0],[14,5,\"display: inline-block\"],[12],[1,\"\\n                \"],[8,[39,1],null,[[\"@modalMode\",\"@model\"],[\"Remove Translation\",[30,1]]],null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@model\"],false,[\"numbers/dids/did-link\",\"numbers/dids/didhistory-actions\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/dids/did-translation.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});