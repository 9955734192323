define("simtex-myaccount/templates/components/handsets/handset-table-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!Only show first matching EndpointAccount}}
  {{!#each this.row.endpointaccount_data as |endpointaccount|}}
  {{#let this.row.endpointaccount_data.firstObject as |endpointaccount|}}
  {{#let (concat
                 endpointaccount.endpoint.endpointmodel.formatted_brand_model
                 ( if endpointaccount.endpoint.label (concat ' (' endpointaccount.endpoint.label ')') '' )
         ) as |endpointtitle|}}
  {{#if endpointaccount.id}}
      {{!@query={{hash refresh=true}}
      <LinkTo @route="restricted.subscriptions.subscription.handset"
              @models={{array endpointaccount.account.owner endpointaccount.account.id endpointaccount.endpoint._endpointid}}
              @title="Manage {{endpointtitle}}"
              class="btn btn-link btn-simtex-table-action"><FaIcon @icon='cogs' /> Manage</LinkTo>
  
      {{#unless this.simtex.is_enduser}}
          <LinkTo @route="restricted.handsets.mapping"
              @models={{array endpointaccount.account.owner}}
              @query={{hash select_primary=endpointaccount.endpoint._endpointid}} class="btn btn-link btn-simtex-table-action"
              @title="Mappings for {{endpointtitle}}"><FaIcon @icon='wrench' /> Mapping</LinkTo>
      {{/unless}}
  {{/if}}
  
  {{#unless this.simtex.is_enduser}}
      {{subscriptions/subscription/handset-zerotouch-dialog endpoint=this.row modalMode='Remove' viewMode='handset-list'}}
  {{/unless}}
  
  {{/let}}{{/let}}
  {{!/each}}
  */
  {
    "id": "pPF+RUMK",
    "block": "[[[44,[[30,0,[\"row\",\"endpointaccount_data\",\"firstObject\"]]],[[[44,[[28,[37,1],[[30,1,[\"endpoint\",\"endpointmodel\",\"formatted_brand_model\"]],[52,[30,1,[\"endpoint\",\"label\"]],[28,[37,1],[\" (\",[30,1,[\"endpoint\",\"label\"]],\")\"],null],\"\"]],null]],[[[41,[30,1,[\"id\"]],[[[1,\"    \"],[8,[39,3],[[24,0,\"btn btn-link btn-simtex-table-action\"]],[[\"@route\",\"@models\",\"@title\"],[\"restricted.subscriptions.subscription.handset\",[28,[37,4],[[30,1,[\"account\",\"owner\"]],[30,1,[\"account\",\"id\"]],[30,1,[\"endpoint\",\"_endpointid\"]]],null],[29,[\"Manage \",[30,2]]]]],[[\"default\"],[[[[8,[39,5],null,[[\"@icon\"],[\"cogs\"]],null],[1,\" Manage\"]],[]]]]],[1,\"\\n\\n\"],[41,[51,[30,0,[\"simtex\",\"is_enduser\"]]],[[[1,\"        \"],[8,[39,3],[[24,0,\"btn btn-link btn-simtex-table-action\"]],[[\"@route\",\"@models\",\"@query\",\"@title\"],[\"restricted.handsets.mapping\",[28,[37,4],[[30,1,[\"account\",\"owner\"]]],null],[28,[37,7],null,[[\"select_primary\"],[[30,1,[\"endpoint\",\"_endpointid\"]]]]],[29,[\"Mappings for \",[30,2]]]]],[[\"default\"],[[[[8,[39,5],null,[[\"@icon\"],[\"wrench\"]],null],[1,\" Mapping\"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[41,[51,[30,0,[\"simtex\",\"is_enduser\"]]],[[[1,\"    \"],[1,[28,[35,8],null,[[\"endpoint\",\"modalMode\",\"viewMode\"],[[30,0,[\"row\"]],\"Remove\",\"handset-list\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[2]]]],[1]]],[1,\"\\n\"]],[\"endpointaccount\",\"endpointtitle\"],false,[\"let\",\"concat\",\"if\",\"link-to\",\"array\",\"fa-icon\",\"unless\",\"hash\",\"subscriptions/subscription/handset-zerotouch-dialog\"]]",
    "moduleName": "simtex-myaccount/templates/components/handsets/handset-table-actions.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});