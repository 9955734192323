define("simtex-myaccount/components/subscriptions/subscription/handset-keys-base", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/array", "@ember/object", "ember-data", "@ember/service"], function (_exports, _component, _modelSaverCommon, _array, _object, _emberData, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _component.default.extend(_modelSaverCommon.default, (_obj = {
    object_name: 'Handset Key Configuration',
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    current_page: 1,
    total_pages: (0, _object.computed)(function () {
      return this.model.get('endpoint.endpointmodel.qtylinekeypages');
    }),
    line_keys: (0, _object.computed)(function () {
      return this.model.get('baselinekey_data');
    }),
    left_line_key_count: (0, _object.computed)(function () {
      return this.model.get('endpoint.endpointmodel.qtyleftlinekeys');
    }),
    right_line_key_count: (0, _object.computed)(function () {
      return this.model.get('endpoint.endpointmodel.qtyrightlinekeys');
    }),
    get_page: (0, _object.computed)('current_page', function () {
      var page = this.get('current_page');
      var maxPage = this.get('total_pages');
      if (page < 1) this.set('current_page', 1);
      if (page > maxPage) this.set('current_page', maxPage);
      return this.get('current_page');
    }),
    left_keys: (0, _object.computed)('baselinekey_data.@each', 'current_page', function () {
      var leftKeyPages = (0, _array.A)();
      var step = this.left_line_key_count + this.right_line_key_count;
      for (var i = 0, j = this.line_keys.length; i < j && step > 0; i += step) {
        var tmpKeys = this.line_keys.slice(i, i + this.left_line_key_count);
        leftKeyPages.pushObject(tmpKeys);
      }
      if (this.get_page <= leftKeyPages.length) return leftKeyPages[this.get_page - 1];else return (0, _array.A)();
    }),
    right_keys: (0, _object.computed)('baselinekey_data.@each', 'current_page', function () {
      var rightKeyPages = (0, _array.A)();
      var step = this.left_line_key_count + this.right_line_key_count;
      if (this.right_line_key_count > 0) {
        for (var i = this.left_line_key_count, j = this.line_keys.length; i < j && step > 0; i += step) {
          var tmpKeys = this.line_keys.slice(i, i + this.right_line_key_count);
          rightKeyPages.pushObject(tmpKeys);
        }
      }
      if (this.get_page <= rightKeyPages.length) return rightKeyPages[this.get_page - 1];else return (0, _array.A)();
    }),
    get_endpointlinekeytypechoices: (0, _object.computed)(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.store.findAll('endpointlinekeytypechoices').then(function (result) {
          return result.toArray();
        })
      });
    }),
    actions: {
      setKeyPage: function setKeyPage(dir) {
        switch (dir) {
          case 'next':
            this.incrementProperty('current_page');
            break;
          case 'previous':
            this.decrementProperty('current_page');
            break;
        }
      }
    },
    mappingHandset: function mappingHandset(endpoint, event) {
      if (endpoint && this.simtex.is_admin) {
        var route = this.router.urlFor('restricted.handsets.mapping', endpoint.get('owner'), {
          queryParams: {
            select_primary: endpoint.get('_endpointid')
          }
        });
        if (event.button === 0) this.router.transitionTo(route);else window.open(route);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "mappingHandset", [_object.action], Object.getOwnPropertyDescriptor(_obj, "mappingHandset"), _obj)), _obj));
  _exports.default = _default;
});