define("simtex-myaccount/components/light-table-cell", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object"], function (_exports, _component, _modelSaverCommon, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Object',
    model_rowcontent: (0, _object.computed)('row', 'column', {
      get: function get() {
        return this.row.content.get(this.column.valuePath);
      },
      set: function set(key, value) {
        this.row.content.set(this.column.valuePath, value);
        this.set('object_name', this.column.label);
        return value;
      }
    }),
    model_valueeditable: (0, _object.computed)('column', {
      get: function get() {
        return this.column.valueEditable || false;
      }
    }),
    model_placeholder: (0, _object.computed)('column', {
      get: function get() {
        if (this.column.placeholder) {
          return this.row.content.get(this.column.placeholder) || '';
        } else {
          return '';
        }
      }
    })
  });
  _exports.default = _default;
});