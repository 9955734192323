define("simtex-myaccount/components/clr-wizard", ["exports", "@ember/component", "simtex-myaccount/mixins/clr-wizard-common"], function (_exports, _component, _clrWizardCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_clrWizardCommon.default, {
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});