define("simtex-myaccount/components/subscriptions/subscription-data", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object", "ember-data", "@ember/service"], function (_exports, _component, _modelSaverCommon, _object, _emberData, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Service Details',
    store: (0, _service.inject)(),
    get_accountstatuses: (0, _object.computed)(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.store.findAll('accountstatuschoices').then(function (result) {
          var statuses = [],
            result_array = result.toArray();
          for (var i = 0, len = result_array.length; i < len; i++) {
            statuses.push({
              label: result_array[i].description,
              value: result_array[i].accountstatusid
            });
          }
          return statuses;
        })
      });
    })
  });
  _exports.default = _default;
});