define("simtex-myaccount/components/subscriptions/callrecording-data", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object", "@ember/service", "ember"], function (_exports, _component, _modelSaverCommon, _object, _service, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Call Recording',
    get_prefixblacklist: null,
    prefixblacklist_text: '',
    disable_prefixwhitelist_field: (0, _object.computed)('model.prefixblockingmode', function () {
      return !this.model.get('prefixblockingmode');
    }),
    init: function init() {
      this._super();
      this.init_blacklist();
    },
    init_blacklist: function init_blacklist() {
      this.get_prefixblacklist = _ember.default.Object.create({
        "prefixes": _ember.default.A([])
      });
      if (this.model.get('callrecording_prefixblacklist')) {
        var blacklist = this.model.get('callrecording_prefixblacklist').split('|');
        for (var i = 0, len = blacklist.length; i < len; i++) {
          this.get_prefixblacklist.prefixes.pushObject(blacklist[i]);
        }
      }
    },
    actions: {
      updateCallrecordOutbound: function updateCallrecordOutbound(event) {
        this.model.set('callrecord_outbound', event.target.checked ? 1 : 0);
      },
      updateCallrecordInbound: function updateCallrecordInbound(event) {
        this.model.set('callrecord_inbound', event.target.checked ? 1 : 0);
      },
      addBlacklistPrefixLabel: function addBlacklistPrefixLabel(value) {
        if (value !== '' && value !== undefined) {
          if (!this.model.get('callrecording_prefixblacklist')) this.model.set('callrecording_prefixblacklist', '');
          var list = this.model.get('callrecording_prefixblacklist').split('|');
          if (list.indexOf(value) === -1) {
            this.get_prefixblacklist.prefixes.pushObject(value);
            this.set('prefixblacklist_text', '');
          }
          this.model.set('callrecording_prefixblacklist', this.get_prefixblacklist.prefixes.join('|'));
        }
      },
      removeBlacklistPrefixLabel: function removeBlacklistPrefixLabel(value) {
        this.get_prefixblacklist.prefixes.removeObject(value);
        this.model.set('callrecording_prefixblacklist', this.get_prefixblacklist.prefixes.join('|'));
      }
    }
  });
  _exports.default = _default;
});