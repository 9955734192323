define("simtex-myaccount/routes/admin", ["exports", "@ember/routing/route", "@ember/service", "simtex-myaccount/config/environment"], function (_exports, _route, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    router: (0, _service.inject)(),
    init: function init() {
      var redirect_uri = window.location.protocol + '//' + window.location.host + '/authproceed';
      window.location.replace(_environment.default.APP.API_HOST + "/auth_proceed?redirect_uri=" + redirect_uri);
    }
  });
  _exports.default = _default;
});