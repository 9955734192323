define("simtex-myaccount/components/subscriptions/subscription/handset-keys", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    keys: null,
    // Populated when handset-keys is invoked via handset-keys-expansion
    endpointaccount_data: null,
    // If handset-keys is invoked via EndpointAccount (Keys tab), model.index points at EndpointAccount.Index.
    // If invoked via EndpointExpansion (Expansion Modules > Edit tab), model.index points at EndpointExpansion.Index.
    // When setting EndpointAccountIndex in `keyTypeDidChange`, if coming in via an EndpointExpansion we return null
    //  and let the user select the PBX Extension they wish. Here, null translates to the 'prompt' option in the
    //  Extensions drop-down.
    getEndpointAccountIndex: function getEndpointAccountIndex() {
      return this.model.constructor.modelName === 'endpointaccount' ? this.model.get('index') : this.get('endpointaccount_data.index');
    },
    actions: {
      keyTypeDidChange: function keyTypeDidChange(key, element, model) {
        switch (key.endpointlinekeytype) {
          case this.simtex.LINEKEYTYPE.LINE:
            // If key changes to LINE, unset DialString and set EndpointAccountIndex to current EA.Index.
            key.set('dialstring', null);
            key.set('endpointaccountindex', this.getEndpointAccountIndex());
            var modelRef = this.model.get('endpointexpansiontype') || this.model.get('endpoint.endpointmodel');

            // Don't allow editing key for Polycom keys of type Line (both base units and expansions).
            // Polycom also does not support Labels for Line key types.
            if (modelRef.get('brand').toLowerCase() === 'polycom') {
              key.set('label', null);
              key.set('editingKey', false);
            } else {
              key.set('editingKey', true);
            }
            break;
          case this.simtex.LINEKEYTYPE.BLF:
            // If key changes to BLF, unset DialString and set
            // EndpointAccountIndex to current EA.Index.
            key.set('dialstring', null);
            key.set('endpointaccountindex', this.getEndpointAccountIndex());
            key.set('editingKey', true);
            break;
          case this.simtex.LINEKEYTYPE.SPEED_DIAL:
            // If key changes to SPEED DIAL, unset LinkedAccount.
            key.set('linkedaccount', null);

            // Set EndpointAccountIndex to 1 (hard-code for now).
            // > we don't have a mechanism to select the account used for speed dials.....
            // > at the moment the templates hard set it to 1
            key.set('endpointaccountindex', 1);
            key.set('editingKey', true);
            break;
          case this.simtex.LINEKEYTYPE.DISABLED:
            // If key changes to DISABLED, unset DialString, EndpointAccountIndex, Label, LinkedAccount
            key.set('dialstring', null);
            key.set('endpointaccountindex', null);
            key.set('label', null);
            key.set('linkedaccount', null);
            key.set('editingKey', false);
            break;
          case this.simtex.LINEKEYTYPE.SPECIAL_BLF:
            // If key changes to SPECIAL BLF, unset LinkedAccount and set
            // EndpointAccountIndex to current EA.Index
            key.set('linkedaccount', null);
            key.set('endpointaccountindex', this.getEndpointAccountIndex());
            key.set('editingKey', true);
            break;
        }
      }
    }
  });
  _exports.default = _default;
});