define("simtex-myaccount/services/schema", ["exports", "@ember/service", "ember-data", "fetch", "simtex-myaccount/config/environment"], function (_exports, _service, _emberData, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    ajax: (0, _service.inject)(),
    cache: {},
    cacheSchemas: ['ipndsite', 'did', "callcontrol"],
    enumCDRDialStatus: {
      ANSWERED: 'Answered',
      BUSY: 'Busy',
      CONGESTION: 'Congestion',
      FAILED: 'Failed',
      NO_ANSWER: 'No answer'
    },
    enumZeroTouchJobStatus: {
      SUCCEEDED: 'Succeeded',
      FAILED: 'Failed',
      PROCESSING: 'Processing',
      PENDING: 'Pending'
    },
    enumZeroTouchState: {
      IDLE: 'Idle',
      OK: 'OK',
      PENDING_ADD: 'Pending Add',
      PENDING_REMOVE: 'Pending Remove',
      PENDING_LOOKUP: 'Pending Lookup',
      MISSING: 'Missing',
      MISMATCHED_BINDING: 'Mismatched Binding',
      BOUND_ANOTHER_PROVIDER: 'Bound Another Provider',
      REMOVED: 'Removed',
      GENERAL_ERROR: 'General Error'
    },
    enumCDRDirection: {
      INBOUND: 'Inbound',
      OUTBOUND: 'Outbound'
    },
    init: function init() {
      this._super.apply(this, arguments);

      // Cache schemas for all models stored in cacheSchemas
      var self = this;
      this.get('cacheSchemas').forEach(function (modelName) {
        var request_init = {
          method: 'OPTIONS',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          mode: 'cors',
          credentials: 'include'
        };
        var option_url = _environment.default.APP.API_HOST + "/".concat(modelName, "/");
        self.cache[modelName] = _emberData.default.PromiseObject.create({
          promise: (0, _fetch.default)(option_url, request_init).then(function (response) {
            return response.json();
          }).then(function (result) {
            if (result && result.actions) {
              return result.actions['POST'] || result.actions['PUT'] || {};
            }
          }, function (error) {
            // debugger;
          })
        });
      });
    }
  });
  _exports.default = _default;
});