define("simtex-myaccount/templates/restricted/numbers/sites/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top:0">Site Allocations for {{this.simtex.owner_description}}</h2>
              {{numbers/sites/did-site-buckets
                  model=this.model}}
          </div>
      </div>
      <br />
  
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2>Sites for {{this.simtex.owner_description}}</h2>
              {{#unless this.simtex.is_enduser}}
                  {{numbers/sites/site-clr-wizard
                      model=this.model
                      wizardMode='new'}}
              {{/unless}}
              {{numbers/sites/site-table
                  ownerFilterTerm=this.simtex.owner_search_term}}
          </div>
      </div>
  </div>
  */
  {
    "id": "uXgQnkrj",
    "block": "[[[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top:0\"],[12],[1,\"Site Allocations for \"],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n            \"],[1,[28,[35,0],null,[[\"model\"],[[30,0,[\"model\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[12],[1,\"Sites for \"],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n\"],[41,[51,[30,0,[\"simtex\",\"is_enduser\"]]],[[[1,\"                \"],[1,[28,[35,2],null,[[\"model\",\"wizardMode\"],[[30,0,[\"model\"]],\"new\"]]]],[1,\"\\n\"]],[]],null],[1,\"            \"],[1,[28,[35,3],null,[[\"ownerFilterTerm\"],[[30,0,[\"simtex\",\"owner_search_term\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"numbers/sites/did-site-buckets\",\"unless\",\"numbers/sites/site-clr-wizard\",\"numbers/sites/site-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/numbers/sites/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});