define("simtex-myaccount/components/subscriptions/subscription/handset-keys-expansion", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/array", "@ember/object", "ember-data", "@ember/service"], function (_exports, _component, _modelSaverCommon, _array, _object, _emberData, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Expansion Key Configuration',
    store: (0, _service.inject)(),
    endpointexpansionid: null,
    endpointaccount_data: null,
    current_page: 1,
    total_pages: (0, _object.computed)('endpointexpansionid', 'endpointexpansiontypeid', function () {
      return this.model.get('endpointexpansiontype.pages');
    }),
    line_keys: (0, _object.computed)('endpointexpansionid', function () {
      return this.model.get('expansionlinekey_data');
    }),
    left_line_key_count: (0, _object.computed)('endpointexpansionid', function () {
      return this.model.get('endpointexpansiontype.leftbuttonsperpage');
    }),
    right_line_key_count: (0, _object.computed)('endpointexpansionid', function () {
      return this.model.get('endpointexpansiontype.rightbuttonsperpage');
    }),
    get_page: (0, _object.computed)('endpointexpansionid', 'current_page', function () {
      var page = this.get('current_page');
      var maxPage = this.get('total_pages');
      if (page < 1) this.set('current_page', 1);
      if (page > maxPage) this.set('current_page', maxPage);
      return this.get('current_page');
    }),
    left_keys: (0, _object.computed)('endpointexpansionid', 'expansionlinekey_data.@each', 'current_page', function () {
      var leftKeyPages = (0, _array.A)();
      var step = this.left_line_key_count + this.right_line_key_count;
      for (var i = 0, j = this.line_keys.length; i < j && step > 0; i += step) {
        var tmpKeys = this.line_keys.slice(i, i + this.left_line_key_count);
        leftKeyPages.pushObject(tmpKeys);
      }
      if (this.get_page <= leftKeyPages.length) return leftKeyPages[this.get_page - 1];else return (0, _array.A)();
    }),
    right_keys: (0, _object.computed)('endpointexpansionid', 'expansionlinekey_data.@each', 'current_page', function () {
      var rightKeyPages = (0, _array.A)();
      var step = this.left_line_key_count + this.right_line_key_count;
      if (this.right_line_key_count > 0) {
        for (var i = this.left_line_key_count, j = this.line_keys.length; i < j && step > 0; i += step) {
          var tmpKeys = this.line_keys.slice(i, i + this.right_line_key_count);
          rightKeyPages.pushObject(tmpKeys);
        }
      }
      if (this.get_page <= rightKeyPages.length) return rightKeyPages[this.get_page - 1];else return (0, _array.A)();
    }),
    get_endpointlinekeytypechoices: (0, _object.computed)('endpointexpansiontypeid', function () {
      var self = this;
      self.expansionCategory = this.model.get('endpointexpansiontype.endpointexpansiontypecategory.name');
      return _emberData.default.PromiseObject.create({
        promise: this.store.findAll('endpointlinekeytypechoices').then(function (result) {
          switch (self.expansionCategory) {
            case 'Handset':
              // Restrict selection of key types for Handsets
              return result.toArray().filter(function (type) {
                return [self.simtex.LINEKEYTYPE.LINE, self.simtex.LINEKEYTYPE.DISABLED].indexOf(type.endpointlinekeytypeid) >= 0;
              });
            default:
              return result.toArray();
          }
        })
      });
    }),
    actions: {
      setKeyPage: function setKeyPage(dir) {
        switch (dir) {
          case 'next':
            this.incrementProperty('current_page');
            break;
          case 'previous':
            this.decrementProperty('current_page');
            break;
        }
      }
    }
  });
  _exports.default = _default;
});