define("simtex-myaccount/models/endpointexpansion", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    endpointexpansionid: _emberData.default.attr(),
    endpointexpansiontype: _emberData.default.belongsTo('endpointexpansiontype', {
      async: false
    }),
    expansionlinekey_data: (0, _attributes.fragmentArray)('endpointlinekey', {
      async: false
    }),
    index: _emberData.default.attr(),
    endpoint: _emberData.default.belongsTo('endpoint', {
      async: true
    })
  });
  _exports.default = _default;
});