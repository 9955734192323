define("simtex-myaccount/components/numbers/sites/did-site-buckets", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    slush_bucket_context: {
      // Reference data
      // Model name for primary bucket
      ref_model_primary: 'ipndsite',
      ref_model_primary_remove_fields: [],
      ref_model_primary_optionval: 'id',
      ref_model_primary_optionlabel: '${label} - ${customername1}',
      ref_model_primary_optionlabeldict: {
        'label': 'label',
        'customername1': 'customername1'
      },
      // Model name for allocation buckets
      ref_model_allocation: 'did',
      // Property on the allocation model linking it with the primary model
      ref_model_allocation_primaryrefid: 'ipndsite.id',
      ref_model_allocation_primaryrefobj: 'ipndsite',
      ref_model_allocation_optionval: 'did',
      ref_model_allocation_optionlabel: '${did} - ${extension} - ${label}',
      ref_model_allocation_optionlabeldict: {
        'did': 'did',
        'extension': 'account.internalextension',
        'label': 'account.label'
      },
      hide_allocated_in_unallocated_for_superuser: false,
      // Used in Allocation bucket to describe what the object is allocated to.
      // If null, defaults to primary object label.
      bucket_selected_primary_descriptionref: null,
      strings: {
        primary_bucket_title: 'Sites',
        allocated_bucket_title: 'DIDs Mapping',
        unallocated_bucket_title: 'DIDs not mapped to a Site',
        allocate_button_text: 'Map DID',
        deallocate_button_text: 'Un-map DID',
        prompt_primary_noresults: 'No Sites have been created yet',
        prompt_allocated_empty_noprimary: 'Select a Site to view its mapped DIDs',
        prompt_allocated_empty_noresults: 'No DIDs currently mapped to this Site',
        prompt_unallocated_empty: 'No un-mapped DIDs',
        saved_allocations_success: 'Saved DID mapping',
        saved_allocations_failure: 'Error saving DID mapping'
      }
    }
  });
  _exports.default = _default;
});