define("simtex-myaccount/serializers/application", ["exports", "ember-django-adapter/serializers/drf", "@ember/utils"], function (_exports, _drf, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _drf.default.extend({
    extractMeta: function extractMeta(store, type, payload) {
      var meta = this._super(store, type, payload);
      if (!(0, _utils.isNone)(meta)) {
        // Add totalPages to metadata.
        var totalPages = 1;
        if (!(0, _utils.isNone)(meta.next)) {
          // Any page that is not the last page.
          totalPages = Math.ceil(meta.count / payload[type.modelName].length);
        } else if ((0, _utils.isNone)(meta.next) && !(0, _utils.isNone)(meta.previous)) {
          // The last page when there is more than one page.
          totalPages = meta.previous + 1;
        }
        meta['totalPages'] = totalPages;
        meta['actions'] = payload['actions'];
      }
      return meta;
    }
  });
  _exports.default = _default;
});