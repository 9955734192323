define("simtex-myaccount/templates/restricted/numbers/dids/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title 'Numbers (' this.simtex.owner_description ')'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top:0">Direct In Dial (DID) Mapping for {{this.simtex.owner_description}}</h2>
              {{numbers/dids/did-subscription-buckets
                      model=this.model
                      select_primary=this.select_primary}}
          </div>
      </div>
      <br />
  
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2>DIDs for {{this.simtex.owner_description}}</h2>
              {{numbers/dids/did-table
                      ownerFilterTerm=this.simtex.owner_search_term}}
          </div>
      </div>
  </div>
  */
  {
    "id": "qciAAbEP",
    "block": "[[[1,[28,[35,0],[\"Numbers (\",[30,0,[\"simtex\",\"owner_description\"]],\")\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top:0\"],[12],[1,\"Direct In Dial (DID) Mapping for \"],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n            \"],[1,[28,[35,1],null,[[\"model\",\"select_primary\"],[[30,0,[\"model\"]],[30,0,[\"select_primary\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[12],[1,\"DIDs for \"],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n            \"],[1,[28,[35,2],null,[[\"ownerFilterTerm\"],[[30,0,[\"simtex\",\"owner_search_term\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"numbers/dids/did-subscription-buckets\",\"numbers/dids/did-table\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/numbers/dids/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});