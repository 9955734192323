define("simtex-myaccount/components/login-form", ["exports", "@ember/component", "@ember/service", "simtex-myaccount/config/environment"], function (_exports, _component, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    simtex: (0, _service.inject)('simtex'),
    router: (0, _service.inject)(),
    username: null,
    password: null,
    rememberme: false,
    login_message: null,
    show_login_message: false,
    show_spinner: false,
    actions: {
      login: function login() {
        var _this = this;
        this.set('show_spinner', true);
        this.set('show_login_message', false);
        if (this.username.includes('@zettagrid.com')) {
          var redirect_uri = window.location.protocol + '//' + window.location.host + '/authproceed';
          window.location.replace(_environment.default.APP.API_HOST + "/auth_proceed?redirect_uri=" + redirect_uri);
        } else {
          this.simtex.check_promise = this.simtex.login(this.username, this.password).then(function (result) {
            if (result.status === "error") {
              _this.set('show_spinner', false);
              _this.set('login_message', result.message);
              _this.set('show_login_message', true);
            } else {
              _this.router.transitionTo('restricted.index');
            }
          });
        }
      }
    }
  });
  _exports.default = _default;
});