define("simtex-myaccount/components/subscriptions/subscription/handset-zerotouch-dialog", ["exports", "@ember/component", "@ember/object", "@ember/service", "simtex-myaccount/mixins/model-saver-common", "fetch", "simtex-myaccount/config/environment"], function (_exports, _component, _object, _service, _modelSaverCommon, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    viewMode: "summary",
    modalShowing: false,
    modalMode: null,
    resultMessage: null,
    modalTitle: (0, _object.computed)(function () {
      if (this.viewMode == 'handset-list') return 'Remove';
      if (this.viewMode == 'job-list') return 'Re-run';
      if (this.modalMode == 'Add') {
        return 'Add to Zero Touch';
      } else if (this.modalMode == 'Remove') {
        return 'Remove Device';
      } else if (this.modalMode == 'Refresh') {
        return 'Refresh Zero Touch';
      }
    }),
    modalClass: (0, _object.computed)(function () {
      var modal_mode = "";
      if (this.modalMode == 'Add') {
        modal_mode = 'btn btn-link';
      } else if (this.modalMode == 'Remove') {
        modal_mode = 'btn btn-link btn-link-danger';
      } else if (this.modalMode == 'Refresh') {
        modal_mode = 'btn btn-link';
      }
      if (this.viewMode == 'handset-list' || this.viewMode == 'job-list') {
        modal_mode = modal_mode + " btn-simtex-table-action";
      } else {
        modal_mode = modal_mode + " btn-sm";
      }
      return modal_mode;
    }),
    modalIcon: (0, _object.computed)(function () {
      if (this.viewMode == 'job-list') return 'sync';
      if (this.modalMode == 'Add') {
        return 'plus';
      } else if (this.modalMode == 'Remove') {
        return 'trash';
      } else if (this.modalMode == 'Refresh') {
        return 'sync';
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    refreshZeroTouch: function refreshZeroTouch() {
      this.activateZerotouchJob('refresh');
    },
    addToZeroTouch: function addToZeroTouch() {
      this.activateZerotouchJob('add');
    },
    removeZeroTouch: function removeZeroTouch() {
      this.activateZerotouchJob('remove');
    },
    activateZerotouchJob: function activateZerotouchJob(action) {
      var self = this;
      var request_init = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        body: JSON.stringify({
          action: action
        })
      };
      var zerotouchjob_url = "".concat(_environment.default.APP.API_HOST, "/endpoint/").concat(this.endpoint.get('endpointid'), "/activateztp/");
      return (0, _fetch.default)(zerotouchjob_url, request_init).then(function (response) {
        return response.json();
      }).then(function (result) {
        if (result.status === 'error') {
          self.toast.error("Failed to activate Zerotouch provision job.");
        } else {
          self.toast.success("Activated Zerotouch provision job successfully.");
          if (self.get('viewMode') == "handset-list" || self.get('viewMode') == "job-list") {
            setTimeout(function () {
              self.refreshTable();
            }, 8000);
          }
        }
      }).catch(function (adapterError) {
        self.toast.error(adapterError.message, "Failed to activate Zerotouch provision job.");
      });
    },
    removeDevice: function removeDevice() {
      var self = this;
      var endpoint = this.store.peekRecord('endpoint', self.endpoint.get('endpointid'));
      self.router.transitionTo('restricted.handsets.list', self.simtex.owner_search_term);
      self.toast.success("Removed Device");
      endpoint.destroyRecord().then(function (record) {
        self.store.unloadRecord(record);
        self.toast.success("Removed Device");
        if (self.get('viewMode') == "summary") {
          self.router.transitionTo('restricted.handsets.list', self.simtex.owner_search_term);
        } else if (self.get('viewMode') == "handset-list" || self.get('viewMode') == "job-list") {
          self.refreshTable();
        }
      }).catch(function (adapterError) {
        self.toast.error(adapterError.message, "Error removing device");
      });
    },
    refreshTable: function refreshTable() {
      if (this.get('simtex.refresh_table') === undefined) {
        this.set('simtex.refresh_table', 0);
      } else {
        this.incrementProperty('simtex.refresh_table');
      }
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('modalShowing');
      },
      completeModal: function completeModal() {
        if (this.get('viewMode') == "summary") {
          this.parentView.getZTJobStatusLoop(0);
        }
        this.toggleProperty('modalShowing');
        if (this.modalMode == 'Add') {
          this.addToZeroTouch();
        } else if (this.modalMode == 'Remove') {
          if (this.viewMode == 'job-list') {
            this.removeZeroTouch();
          } else {
            this.removeDevice();
          }
        } else if (this.modalMode == 'Refresh') {
          this.refreshZeroTouch();
        }
      }
    }
  });
  _exports.default = _default;
});