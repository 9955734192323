define("simtex-myaccount/templates/components/subscriptions/subscription/handset-expansion-table-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.row.content.endpointexpansiontype.isPending}}
      {{{this.simtex.STRINGS.SPINNER_SMALL}}}
  {{else}}
      {{#if (is-equal this.row.content.endpointexpansiontype.endpointexpansiontypecategory.name 'Handset')}}
          {{! Equivalent to - find any linekey records whose endpointlinekeytype = 1 (LINE)}}
          {{#let (read-array this.row.content.expansionlinekey_data 1 'endpointlinekeytype') as |linekey|}}
          {{#if linekey}}
              {{#if linekey.get_endpointaccountindex_acct.isPending}}
                  {{{this.simtex.STRINGS.SPINNER_SMALL}}}
              {{else}}
                  <LinkTo @route='restricted.subscriptions.subscription'
                          @models={{array linekey.get_endpointaccountindex_acct.owner
                             linekey.get_endpointaccountindex_acct.accountid}}
                             class="nav-link">
                      {{#if linekey.calculatedKeyLabel}}
                          {{linekey.calculatedKeyLabel}} /
                      {{/if}}
                      {{linekey.get_endpointaccountindex_acct.accountid}}
                  </LinkTo>
              {{/if}}
          {{else}}
              No line key set
          {{/if}}
          {{/let}}
      {{else}}
          N/A
      {{/if}}
  {{/if}}
  */
  {
    "id": "xFdPPMFW",
    "block": "[[[41,[30,0,[\"row\",\"content\",\"endpointexpansiontype\",\"isPending\"]],[[[1,\"    \"],[2,[30,0,[\"simtex\",\"STRINGS\",\"SPINNER_SMALL\"]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"row\",\"content\",\"endpointexpansiontype\",\"endpointexpansiontypecategory\",\"name\"]],\"Handset\"],null],[[[44,[[28,[37,3],[[30,0,[\"row\",\"content\",\"expansionlinekey_data\"]],1,\"endpointlinekeytype\"],null]],[[[41,[30,1],[[[41,[30,1,[\"get_endpointaccountindex_acct\",\"isPending\"]],[[[1,\"                \"],[2,[30,0,[\"simtex\",\"STRINGS\",\"SPINNER_SMALL\"]]],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[39,4],[[24,0,\"nav-link\"]],[[\"@route\",\"@models\"],[\"restricted.subscriptions.subscription\",[28,[37,5],[[30,1,[\"get_endpointaccountindex_acct\",\"owner\"]],[30,1,[\"get_endpointaccountindex_acct\",\"accountid\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"calculatedKeyLabel\"]],[[[1,\"                        \"],[1,[30,1,[\"calculatedKeyLabel\"]]],[1,\" /\\n\"]],[]],null],[1,\"                    \"],[1,[30,1,[\"get_endpointaccountindex_acct\",\"accountid\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[]]]],[]],[[[1,\"            No line key set\\n\"]],[]]]],[1]]]],[]],[[[1,\"        N/A\\n\"]],[]]]],[]]]],[\"linekey\"],false,[\"if\",\"is-equal\",\"let\",\"read-array\",\"link-to\",\"array\"]]",
    "moduleName": "simtex-myaccount/templates/components/subscriptions/subscription/handset-expansion-table-info.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});