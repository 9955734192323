define("simtex-myaccount/models/endpointmodel", ["exports", "ember-data", "@ember/object"], function (_exports, _emberData, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    endpointmodelid: _emberData.default.attr(),
    endpointmodeltype: _emberData.default.attr(),
    endpointexpansionallowed_data: _emberData.default.hasMany('endpointexpansionallowed'),
    brand: _emberData.default.attr('string'),
    model: _emberData.default.attr('string'),
    firmwareurl: _emberData.default.attr('string'),
    firmwareurl2: _emberData.default.attr('string'),
    compilerfilename: _emberData.default.attr('string'),
    qtyleftlinekeys: _emberData.default.attr(),
    qtyrightlinekeys: _emberData.default.attr(),
    qtylinekeypages: _emberData.default.attr(),
    qtyextensions: _emberData.default.attr(),
    regenerationdate: _emberData.default.attr('date'),
    isactive: _emberData.default.attr('boolean'),
    xmltemplates: _emberData.default.attr('boolean'),
    logourl: _emberData.default.attr('string'),
    logotext: _emberData.default.attr('string'),
    defaultdebuglevel: _emberData.default.attr(),
    maxdebuglevel: _emberData.default.attr(),
    maxcharactersline: _emberData.default.attr(),
    maxcharactersexpansion: _emberData.default.attr(),
    maxcharactersstation: _emberData.default.attr(),
    uideviceimage: _emberData.default.attr('string'),
    zerotouchtype: _emberData.default.attr(),
    formatted_firmwareurl: (0, _object.computed)('firmwareurl', function () {
      var filename = '';
      if (this.firmwareurl) filename = this.firmwareurl.split('/').pop();
      return filename.replace('.rom', '');
    }),
    formatted_brand_model: (0, _object.computed)('brand', 'model', function () {
      return [(this.get('brand') || '').trim(), (this.get('model') || '').toUpperCase().trim()].join(' ').trim();
    }),
    formatted_brand_model_lower_hyphenated: (0, _object.computed)('formatted_brand_model', function () {
      return (this.get('formatted_brand_model') || '').toLowerCase().replace(' ', '-');
    }),
    summary_image_path: (0, _object.computed)('brand', 'model', function () {
      return [(this.get('brand') || '').trim(), (this.get('model') || '').trim(), (this.get('model') || '').trim()].join('/').toLowerCase() + '-left.jpg';
    }),
    endpointexpansion_choices: (0, _object.computed)('endpointexpansionallowed_data', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('endpointexpansionallowed_data').then(function (result) {
          var expansions = [],
            result_array = result.toArray();
          for (var i = 0, len = result_array.length; i < len; i++) {
            expansions.push(result_array[i].endpointexpansiontype);
          }
          return expansions;
        })
      });
    })
  });
  _exports.default = _default;
});