define("simtex-myaccount/templates/components/subscriptions/subscription-table-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <button class="btn btn-link btn-simtex-table-action" title="View Details"
      {{action this.tableActions.viewSubscription this.row bubbles=false}}>
      <FaIcon @icon='cogs' /> Details
  </button>
  */
  {
    "id": "RnjGeF/q",
    "block": "[[[11,\"button\"],[24,0,\"btn btn-link btn-simtex-table-action\"],[24,\"title\",\"View Details\"],[4,[38,0],[[30,0],[30,0,[\"tableActions\",\"viewSubscription\"]],[30,0,[\"row\"]]],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\"],[\"cogs\"]],null],[1,\" Details\\n\"],[13]],[],false,[\"action\",\"fa-icon\"]]",
    "moduleName": "simtex-myaccount/templates/components/subscriptions/subscription-table-actions.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});