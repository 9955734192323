define("simtex-myaccount/models/broadcastgroup", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    broadcastgroupid: _emberData.default.attr(),
    account: _emberData.default.belongsTo('account'),
    name: _emberData.default.attr('string'),
    owner: _emberData.default.attr(),
    subgroupindex: _emberData.default.attr(),
    delaytime: _emberData.default.attr(),
    maxsubgroupindex: _emberData.default.attr(),
    broadcastmember_data: _emberData.default.hasMany('broadcastmember')
  });
  _exports.default = _default;
});