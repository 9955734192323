define("simtex-myaccount/components/callrouting/ringgroup-table", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/object"], function (_exports, _component, _tableCommon, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, {
    modelName: 'broadcastgroup',
    sort: 'account',
    filterList: ['account__owner', 'account'],
    disableLink: true,
    account__owner: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('account__owner', this.owner);
      if (this.primary_account) {
        this.set('account', this.primary_account);
      }
      this.send('setPage', 1);
    },
    columns: (0, _object.computed)(function () {
      return [{
        width: '50px',
        sortable: false,
        cellComponent: 'light-table-expansion-action'
      }, {
        label: 'Subscription',
        width: '250px',
        sortable: true,
        valuePath: 'account',
        cellComponent: 'accounts/account-light-table-cell'
      }, {
        label: 'Label',
        sortable: true,
        valuePath: 'name',
        format: function format(valueArray) {
          var members = this.row.get('broadcastmember_data');
          return "".concat(valueArray, " - ").concat(members.length, " member(s)");
        }
      }, {
        label: 'Subgroup',
        width: '100px',
        valuePath: 'subgroupindex',
        sortable: true
      }, {
        label: 'Delay',
        valuePath: 'delaytime',
        width: '100px',
        sortable: true,
        format: function format(valueArray) {
          return "".concat(valueArray, " seconds");
        }
      }, {
        label: 'Actions',
        sortable: false,
        cellComponent: 'callrouting/ringgroup-table-action'
      }];
    })
  });
  _exports.default = _default;
});