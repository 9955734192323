define("simtex-myaccount/routes/restricted/accounts/account", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    beforeModel: function beforeModel() {
      this.simtex.set_user_nav(false);
    },
    actions: {}
  });
  _exports.default = _default;
});