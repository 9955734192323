define("simtex-myaccount/controllers/restricted/subscriptions/subscription/handset", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-data"], function (_exports, _controller, _object, _service, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    tabIndex: 0,
    simtex: (0, _service.inject)('simtex'),
    // this is to make sure we only load tabs when the fetch is fullfilled. Otherwise the tabs are all in the wrong orders.
    show_tabs: (0, _object.computed)(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('model.endpoint.endpointmodel.endpointexpansion_choices').then(function (results) {
          if (results) {
            return true;
          }
        })
      });
    }),
    tabIndex_arr: (0, _object.computed)(function () {
      var _this = this;
      return _emberData.default.PromiseObject.create({
        promise: this.get('model.endpoint.endpointmodel.endpointexpansion_choices').then(function (expansion_results) {
          var idx_arr = [];
          idx_arr['summary'] = 0;
          if (_this.get('model.endpoint.endpointmodel.qtyleftlinekeys') > 0 || _this.get('model.endpoint.endpointmodel.qtyrightlinekeys') > 0) {
            idx_arr['keys'] = idx_arr['summary'] + 1;
          } else {
            idx_arr['keys'] = idx_arr['summary'];
          }
          if (expansion_results.length > 0) {
            idx_arr['expansions'] = idx_arr['keys'] + 1;
          } else {
            idx_arr['expansions'] = idx_arr['keys'];
          }
          idx_arr['advanced'] = idx_arr['expansions'] + 1;
          if (_this.simtex.is_admin) {
            idx_arr['customparameters'] = idx_arr['advanced'] + 1;
          } else {
            idx_arr['customparameters'] = idx_arr['advanced'];
          }
          _this.set('show_tabs', true);
          return idx_arr;
        })
      });
    }),
    display_tabs: (0, _object.computed)(function () {
      var _this2 = this;
      return _emberData.default.PromiseObject.create({
        promise: this.get('model.endpoint.endpointmodel.endpointexpansion_choices').then(function (results) {
          if (results.length > 0) {
            _this2.set('show_tabs', true);
          }
        })
      });
    })
  });
  _exports.default = _default;
});