define("simtex-myaccount/routes/passwordreset", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var current_url = new URL(window.location.href);
      var prtoken = current_url.searchParams.get('prtoken');
      var contact = current_url.searchParams.get('contact');
      if (prtoken) this.controllerFor('passwordreset').set('prtoken', prtoken);
      if (contact) this.controllerFor('passwordreset').set('contact', contact);
    }
  });
  _exports.default = _default;
});