define("simtex-myaccount/models/endpointlinekey", ["exports", "ember-data", "@ember/object", "@ember/service", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _object, _service, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fragment.default.extend({
    store: (0, _service.inject)(),
    simtex: (0, _service.inject)('simtex'),
    endpointlinekeyid: _emberData.default.attr(),
    endpoint: _emberData.default.attr(),
    endpointlinekeytype: _emberData.default.attr(),
    linekeyid: _emberData.default.attr(),
    endpointaccountindex: _emberData.default.attr(),
    label: _emberData.default.attr('string'),
    dialstring: _emberData.default.attr('string'),
    endpointexpansion: _emberData.default.attr(),
    linkedaccount: _emberData.default.attr(),
    // endpointaccountindex_acct: DS.belongsTo('account'),
    endpointaccountindex_acct: _emberData.default.attr(),
    linkedAccountKeyLabel: null,
    editingKey: false,
    get_endpointaccountindex_acct: (0, _object.computed)('endpointaccountindex', function () {
      var _this = this;
      return _emberData.default.PromiseObject.create({
        promise: this.get('get_availableendpointaccounts').then(function (result) {
          return result.find(function (a) {
            return a.index === _this.get('endpointaccountindex');
          });
        })
      });
    }),
    calculatedKeyLabel: (0, _object.computed)('label', 'linkedaccount', 'endpointexpansion', 'endpointlinekeytype', 'endpointaccountindex', 'get_endpointaccountindex_acct.label', 'linkedAccountKeyLabel', function () {
      // If linkedaccount changes, reset our label to null (and inherit from Account)
      if (this.changedAttributes().length >= 1 && this.changedAttributes().includes('linkedaccount')) {
        this.set('label', null);
      }
      switch (this.endpointlinekeytype) {
        case this.simtex.LINEKEYTYPE.LINE:
          return this.label || this.get_endpointaccountindex_acct.get('label') || '(empty label)';
        case this.simtex.LINEKEYTYPE.BLF:
          return this.label || this.linkedAccountKeyLabel || '(empty label)';
        default:
          return '';
      }
    }),
    get_availableendpointaccounts: (0, _object.computed)('endpointexpansion', 'endpointaccountindex', 'endpointlinekeytype', 'linkedaccount', 'label', function () {
      var self = this;
      var endpoint = this.store.peekRecord('endpoint', this.endpoint);
      if (endpoint) {
        var promiseArray = [],
          ea_data = endpoint.get('endpointaccount_data').toArray();
        for (var i = 0; i < ea_data.length; i++) {
          promiseArray.push(_emberData.default.PromiseObject.create({
            promise: ea_data[i].get('account')
          }));
        }
        return _emberData.default.PromiseObject.create({
          promise: Promise.all(promiseArray).then(function (accounts) {
            var ret = [];
            ea_data = ea_data.sortBy('account.internalextension');
            for (var _i = 0; _i < ea_data.length; _i++) {
              var accountKeyLabel = ea_data[_i].get('account.label') || '(empty label)',
                internalExtension = ea_data[_i].get('account.internalextension') || '(empty)',
                label = accountKeyLabel,
                index = ea_data[_i].get('index'),
                eaAccountId = ea_data[_i].get('account.accountid'),
                eaAccountOwner = ea_data[_i].get('account.owner');
              if (eaAccountId === accounts[_i].accountid) {
                // Don't use self.calculatedKeyLabel here, we already have up to date
                // endpointaccountindex_acct information.
                label = self.label || accountKeyLabel;
                self.set('linkedAccountKeyLabel', accountKeyLabel);
              }
              ret.push({
                index: index,
                accountid: eaAccountId,
                owner: eaAccountOwner,
                label: 'Ext ' + internalExtension + ': ' + label,
                accountKeyLabel: accountKeyLabel
              });
            }
            return ret;
          })
        });
      }
    }),
    get_availableaccounts_labeloverrides: (0, _object.computed)('calculatedKeyLabel', 'endpointaccountindex', 'linkedaccount', 'label', function () {
      var self = this;
      return _emberData.default.PromiseObject.create({
        promise: this.simtex.get_availableaccounts_cloud_pbx.then(function (result) {
          var ret = [],
            acc_data = result.toArray();
          for (var i = 0; i < acc_data.length; i++) {
            // Show custom key label if defined
            var accountKeyLabel = acc_data[i].label || '(empty label)',
              internalExtension = acc_data[i].internalextension || '(empty)',
              label = accountKeyLabel,
              accountid = acc_data[i].accountid,
              owner = acc_data[i].owner;
            if (accountid === self.linkedaccount) {
              self.set('linkedAccountKeyLabel', accountKeyLabel);
              label = self.calculatedKeyLabel;
            }
            ret.push({
              accountid: accountid,
              owner: owner,
              label: 'Ext ' + internalExtension + ': ' + label,
              accountKeyLabel: accountKeyLabel || '(empty label)'
            });
          }
          return ret;
        })
      });
    })
  });
  _exports.default = _default;
});