define("simtex-myaccount/components/input-text-focused", ["exports", "@ember/component/text-field"], function (_exports, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _textField.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var element = this.get('element');
      if (element) element.focus();
    }
  });
  _exports.default = _default;
});