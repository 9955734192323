define("simtex-myaccount/components/subscriptions/numberpresentation-data", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object", "ember-data", "@ember/service"], function (_exports, _component, _modelSaverCommon, _object, _emberData, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Number Presentation',
    store: (0, _service.inject)(),
    numberpresentation_list: null,
    disable_callerid_field: (0, _object.computed)('model.calleridoverride', function () {
      return !this.model.get('calleridoverride');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.get_numberpresentation_list();
    },
    get_numberpresentation_list: function get_numberpresentation_list() {
      var _this = this;
      return _emberData.default.PromiseObject.create({
        promise: this.store.query('numberpresentation', {
          account__owner: this.simtex.owner_search_term,
          has_phoneid: true,
          page_size: 500
        }).then(function (results) {
          var numberpresentation_list = [];
          results.forEach(function (numberpresentation) {
            var phoneid = numberpresentation.get('phoneid');
            var did = numberpresentation.get('did');
            var label = numberpresentation.get('did_label');
            if (numberpresentation_list.filter(function (item) {
              return item.value == phoneid;
            }).length == 0) {
              numberpresentation_list.push({
                display_name: "".concat(did, " - ").concat(label ? label : 'No Label'),
                value: phoneid
              });
            }
          });
          _this.set('numberpresentation_list', numberpresentation_list);
        })
      });
    }
  });
  _exports.default = _default;
});