define("simtex-myaccount/components/callhistory/callhistory-table", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/object", "@ember/string", "@ember/service", "moment", "@ember/template"], function (_exports, _component, _tableCommon, _object, _string, _service, _moment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _component.default.extend(_tableCommon.default, (_obj = {
    helpers: (0, _service.inject)(),
    schema: (0, _service.inject)(),
    modelName: 'cdrdata',
    currentScrollOffset: 0,
    sort: '-start',
    page_size: 100,
    caller: 'subscription',
    filter_dialstatus: null,
    filter_dialstatus_list: null,
    filter_direction: null,
    filter_direction_list: null,
    datetimetable_format: 'D/M/YY HH:mm:ss',
    init: function init() {
      this._super.apply(this, arguments);
      this.send('setPage', 1);
      var diastatus_list = [this.schema.enumCDRDialStatus.ANSWERED, this.schema.enumCDRDialStatus.BUSY, this.schema.enumCDRDialStatus.CONGESTION, this.schema.enumCDRDialStatus.FAILED, this.schema.enumCDRDialStatus.NO_ANSWER];
      this.set('filter_dialstatus_list', diastatus_list.map(function (enumItem) {
        return {
          label: enumItem.valueOf(),
          value: enumItem.valueOf()
        };
      }));
      this.set('filter_dialstatus', this.filter_dialstatus_list.map(function (item) {
        return item.value;
      }));
      var direction_list = [this.schema.enumCDRDirection.INBOUND, this.schema.enumCDRDirection.OUTBOUND];
      this.set('filter_direction_list', direction_list.map(function (enumItem) {
        return {
          label: enumItem.valueOf(),
          value: enumItem.valueOf()
        };
      }));
      this.set('filter_direction', this.filter_direction_list.map(function (item) {
        return item.value;
      }));
    },
    filterList: ['owner', 'account', 'start_gte', 'start_lt', 'clid', 'dnid', 'src', 'dst', 'dialstatus', 'direction'],
    columns: (0, _object.computed)(function () {
      var self = this;
      var cols = [{
        width: '50px',
        sortable: false,
        cellComponent: 'callhistory/callhistory-expanded-action'
      }, {
        label: 'Account',
        valuePath: 'owner',
        sortable: false
      }, {
        label: 'Subscription',
        sortable: false,
        valuePath: 'account',
        cellComponent: 'accounts/account-light-table-cell'
      }, {
        label: 'Call Start',
        valuePath: 'start',
        sortable: true,
        // format: Tue 4/8/20 15:48:45
        format: function format(value) {
          return value ? (0, _moment.default)(value).format(self.datetimetable_format) : '(empty)';
        }
      }, {
        label: 'Call Answered',
        valuePath: 'answer',
        sortable: false,
        format: function format(value) {
          return value ? (0, _moment.default)(value).format(self.datetimetable_format) : '(empty)';
        }
      }, {
        label: 'Call End',
        valuePath: 'end',
        sortable: false,
        format: function format(value) {
          return value ? (0, _moment.default)(value).format(self.datetimetable_format) : '(empty)';
        }
      }, {
        label: 'Dial Status',
        valuePath: 'dialstatus',
        sortable: false,
        format: function format(value) {
          var status_class = "";
          if (value == "ANSWERED") {
            status_class = "label-success";
          } else {
            status_class = "label-danger";
          }
          var status = value ? (0, _string.capitalize)(value.toLocaleLowerCase()) : '';
          return (0, _template.htmlSafe)("<span class='label " + status_class + "'>" + status + "</span>");
        }
      }, {
        label: 'Direction',
        valuePath: 'direction',
        sortable: false
      }, {
        label: 'CallerID',
        valuePath: 'clid',
        sortable: false
      }, {
        label: 'Source',
        valuePath: 'src',
        sortable: false
      }, {
        label: 'Destination',
        valuePath: 'dst',
        sortable: true
      }, {
        label: 'B-Party',
        valuePath: 'dstchannel',
        sortable: false,
        format: function format(value) {
          if (value.length > 0) {
            if (RegExp(/Local\/.*/).test(value)) return '*';
            var formatted_value_arr = value.match(/.*\/(.*)-.*/);
            if (formatted_value_arr.length > 1) {
              if (RegExp(/^(214)[0-9]*$/).test(formatted_value_arr[1]) || RegExp(/^(7)[0-9]*$/).test(formatted_value_arr[1])) return formatted_value_arr[1];
            }
            return '*';
          }
          return '';
        }
      }, {
        label: 'Duration (sec)',
        valuePath: 'duration',
        sortable: false
      }, {
        label: 'Bill Duration (sec)',
        valuePath: 'billsec',
        sortable: false
      }];
      switch (this.caller) {
        // Remove Account and Subscription columns if viewing from subscription page
        case 'subscription':
          cols = cols.filter(function (c) {
            return !['Account', 'Subscription'].includes(c.label);
          });
          break;
        // Remove Account column if viewing from top User navbar
        case 'account':
          cols = cols.filter(function (c) {
            return !['Account'].includes(c.label);
          });
          break;
      }
      return cols;
    }),
    applyFilters: function applyFilters() {
      this.set('dialstatus', this.get('filter_dialstatus').join());
      this.set('direction', this.get('filter_direction').join());
      this.send('refresh');
    }
  }, (_applyDecoratedDescriptor(_obj, "applyFilters", [_object.action], Object.getOwnPropertyDescriptor(_obj, "applyFilters"), _obj)), _obj));
  _exports.default = _default;
});