define("simtex-myaccount/templates/components/numbers/dids/did-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a class="btn btn-link" href="#" {{action this.linkToDid @owner @linked_did}}>
      {{@display_did}}
  </a>
  */
  {
    "id": "r6ZBIjsi",
    "block": "[[[11,3],[24,0,\"btn btn-link\"],[24,6,\"#\"],[4,[38,0],[[30,0],[30,0,[\"linkToDid\"]],[30,1],[30,2]],null],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n\"],[13]],[\"@owner\",\"@linked_did\",\"@display_did\"],false,[\"action\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/dids/did-link.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});