define("simtex-myaccount/templates/components/subscriptions/subscription/handset-expansion-table-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style="display: inline-block;">
      <button class="btn btn-link btn-simtex-table-action"
              title="Edit Expansion"
              {{action this.tableActions.toggleEditExpansion this.row.content}}><FaIcon @icon='edit' /> Edit</button>
  </div>&nbsp;
  <div style="display: inline-block;">
      <button class="btn btn-link btn-link-danger btn-simtex-table-action"
              title="Remove Expansion"
              disabled={{disableRemove}}
              {{action this.tableActions.toggleRemoveExpansion this.row.content}}><FaIcon @icon='trash-alt' /> Remove</button>
  </div>
  */
  {
    "id": "S7cHpyBD",
    "block": "[[[10,0],[14,5,\"display: inline-block;\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link btn-simtex-table-action\"],[24,\"title\",\"Edit Expansion\"],[4,[38,0],[[30,0],[30,0,[\"tableActions\",\"toggleEditExpansion\"]],[30,0,[\"row\",\"content\"]]],null],[12],[8,[39,1],null,[[\"@icon\"],[\"edit\"]],null],[1,\" Edit\"],[13],[1,\"\\n\"],[13],[1,\" \\n\"],[10,0],[14,5,\"display: inline-block;\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link btn-link-danger btn-simtex-table-action\"],[24,\"title\",\"Remove Expansion\"],[16,\"disabled\",[36,2]],[4,[38,0],[[30,0],[30,0,[\"tableActions\",\"toggleRemoveExpansion\"]],[30,0,[\"row\",\"content\"]]],null],[12],[8,[39,1],null,[[\"@icon\"],[\"trash-alt\"]],null],[1,\" Remove\"],[13],[1,\"\\n\"],[13]],[],false,[\"action\",\"fa-icon\",\"disableRemove\"]]",
    "moduleName": "simtex-myaccount/templates/components/subscriptions/subscription/handset-expansion-table-actions.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});