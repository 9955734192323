define("simtex-myaccount/serializers/cdrdata", ["exports", "simtex-myaccount/serializers/drf", "ember-data"], function (_exports, _drf, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    // primaryKey: 'id', // Implicit if PK id exists
    attrs: {
      cdrdata_history: {
        embedded: 'always'
      }
    },
    extractPageNumber: function extractPageNumber(url) {
      var match = /.*?[\?&]cursor=([A-Za-z0-9%]+).*?/.exec(url);
      if (match) {
        return decodeURIComponent(match[1]);
      }
      return null;
    }
  });
  _exports.default = _default;
});