define("simtex-myaccount/helpers/resolve-enum", ["exports", "@ember/component/helper", "@ember/service"], function (_exports, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _helper.default.extend({
    simtex: (0, _service.inject)('simtex'),
    compute: function compute(enumData) {
      return this.simtex.resolveEnum(enumData);
    }
  });
  _exports.default = _default;
});