define("simtex-myaccount/serializers/countrychoices", ["exports", "simtex-myaccount/serializers/drf", "ember-data"], function (_exports, _drf, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _drf.default.extend({
    primaryKey: "country"
  });
  _exports.default = _default;
});