define("simtex-myaccount/templates/components/numbers/sites/site-light-table-cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (or this.row.isPending this.row.content.ipndsite.isPending)}}
      <div class="spinner spinner-sm">Loading...</div>
  {{else}}
      {{#if this.row.ipndsite.id}}
          {{this.row.ipndsite.label}}<br />
          <small>({{resolve-enum "ipndsite" "listcode" this.row.ipndsite.listcode}})</small>
      {{else}}
          (empty)
      {{/if}}
  {{/if}}
  */
  {
    "id": "8dGKHTtg",
    "block": "[[[41,[28,[37,1],[[30,0,[\"row\",\"isPending\"]],[30,0,[\"row\",\"content\",\"ipndsite\",\"isPending\"]]],null],[[[1,\"    \"],[10,0],[14,0,\"spinner spinner-sm\"],[12],[1,\"Loading...\"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"row\",\"ipndsite\",\"id\"]],[[[1,\"        \"],[1,[30,0,[\"row\",\"ipndsite\",\"label\"]]],[10,\"br\"],[12],[13],[1,\"\\n        \"],[10,\"small\"],[12],[1,\"(\"],[1,[28,[35,2],[\"ipndsite\",\"listcode\",[30,0,[\"row\",\"ipndsite\",\"listcode\"]]],null]],[1,\")\"],[13],[1,\"\\n\"]],[]],[[[1,\"        (empty)\\n\"]],[]]]],[]]]],[],false,[\"if\",\"or\",\"resolve-enum\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/sites/site-light-table-cell.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});