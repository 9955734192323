define("simtex-myaccount/models/cdrdata", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // id: DS.attr(), // Implicit if PK id exists
    source: _emberData.default.attr('string'),
    original_id: _emberData.default.attr(),
    // account: DS.belongsTo('account'),
    account: _emberData.default.attr(),
    owner: _emberData.default.attr(),
    start: _emberData.default.attr('date'),
    answer: _emberData.default.attr('date'),
    end: _emberData.default.attr('date'),
    clid: _emberData.default.attr('string'),
    dnid: _emberData.default.attr('string'),
    src: _emberData.default.attr('string'),
    dst: _emberData.default.attr('string'),
    dstchannel: _emberData.default.attr('string'),
    duration: _emberData.default.attr(),
    billsec: _emberData.default.attr(),
    dialstatus: _emberData.default.attr('string'),
    direction: _emberData.default.attr('string'),
    cdrdata_history: _emberData.default.hasMany('cdrdata')
  });
  _exports.default = _default;
});