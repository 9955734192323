define("simtex-myaccount/controllers/passwordreset", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    passwordresettoken: null,
    contact: 'email'
  });
  _exports.default = _default;
});