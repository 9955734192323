define("simtex-myaccount/routes/restricted/numbers/sites", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    simtex: (0, _service.inject)('simtex'),
    beforeModel: function beforeModel() {
      this.simtex.set_user_nav(true);
    },
    model: function model(params) {
      this.simtex.set_user_nav_fields(params.owner);
      return this._super(params);
    }
  });
  _exports.default = _default;
});