define("simtex-myaccount/components/password-reset-form", ["exports", "@ember/component", "@ember/service", "@ember/template", "@ember/object"], function (_exports, _component, _service, _template, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    simtex: (0, _service.inject)('simtex'),
    router: (0, _service.inject)(),
    new_password: null,
    confirm_password: null,
    password_reset_message: null,
    show_error_message: false,
    show_success_message: false,
    show_spinner: false,
    sms_resetcode: null,
    disable_resetbtn: true,
    resend_countdown: (0, _object.computed)(function () {
      this.startCountingDown();
    }),
    startCountingDown: function startCountingDown() {
      var self = this;
      self.set('disable_resetbtn', true);
      var interval = 90000;
      var countdown = setInterval(function () {
        if (interval > 0) {
          if (!self.isDestroyed) {
            self.set('resend_countdown', interval / 1000);
          }
          interval = interval - 1000;
        } else {
          clearInterval(countdown);
          if (!self.isDestroyed) {
            self.set('disable_resetbtn', false);
            self.set('resend_countdown', 0);
          }
        }
      }, 1000);
    },
    actions: {
      resetpassword: function resetpassword() {
        var _this = this;
        this.set('show_spinner', true);
        this.set('show_password_request_message', false);
        this.simtex.passwordreset(this.new_password, this.confirm_password, this.prtoken, this.sms_resetcode).then(function (result) {
          _this.set('show_spinner', false);
          _this.set('password_reset_message', (0, _template.htmlSafe)(result.message));
          if (result.status == 'error') {
            _this.set('show_error_message', true);
            _this.set('show_success_message', false);
          } else {
            _this.set('show_success_message', true);
            _this.set('show_error_message', false);
          }
        });
      },
      resendrequest: function resendrequest() {
        var _this2 = this;
        var self = this;
        self.simtex.resendresetcode(self.prtoken).then(function (result) {
          _this2.set('show_spinner', false);
          _this2.set('password_reset_message', (0, _template.htmlSafe)(result.message));
          if (result.status == 'error') {
            _this2.set('show_error_message', true);
            _this2.set('show_success_message', false);
          } else {
            _this2.set('show_success_message', true);
            _this2.set('show_error_message', false);
            var continue_url = result.continueurl;
            if (continue_url !== '') window.location.replace(continue_url);
          }
        });
        self.startCountingDown();
      }
    }
  });
  _exports.default = _default;
});