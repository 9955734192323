define("simtex-myaccount/models/endpoint", ["exports", "ember-data", "@ember/object", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _object, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    endpointid: _emberData.default.attr(),
    _endpointid: (0, _object.computed)('endpointid', function () {
      return this.get('endpointid') + 2100000;
    }),
    endpointmodel: _emberData.default.belongsTo('endpointmodel', {
      async: false
    }),
    endpointdialplan: (0, _attributes.fragment)('endpointdialplan', {
      async: false
    }),
    provisionconfig: _emberData.default.attr(),
    provisionconfig_label: _emberData.default.attr('string'),
    owner: _emberData.default.attr('string'),
    macaddress: _emberData.default.attr('string'),
    adminpassword: _emberData.default.attr('string', {
      defaultValue: 'simtexadmin'
    }),
    label: _emberData.default.attr('string'),
    debuglevel: _emberData.default.attr(),
    customconfig: _emberData.default.attr('string'),
    handsetlogoopt: _emberData.default.attr(),
    logourl: _emberData.default.attr('string'),
    logotext: _emberData.default.attr('string'),
    customdialplan: _emberData.default.attr('string'),
    customdigitmaptimeout: _emberData.default.attr('string'),
    linekeyrepeatmigrated: _emberData.default.attr(),
    assign_account: _emberData.default.attr(),
    endpointmodel_id: _emberData.default.attr(),
    endpointdialplan_id: _emberData.default.attr(),
    endpointaccount_data: _emberData.default.hasMany('endpointaccount', {
      async: false
    }),
    configdefinitiongroup_data: (0, _attributes.fragmentArray)('configdefinitiongroup', {
      async: false
    }),
    endpointexpansion_data: _emberData.default.hasMany('endpointexpansion', {
      async: true
    }),
    formatted_macaddress: (0, _object.computed)('macaddress', function () {
      var mac = (this.macaddress || '').toUpperCase();
      // return (mac.match(/.{1,2}/g) || []).join(':');
      return mac;
    }),
    first_endpointaccount_label: (0, _object.computed)('endpointaccount_data', function () {
      var arr_ea = this.get('endpointaccount_data').toArray();
      return arr_ea.length > 0 ? arr_ea.firstObject.get('account_label') : '';
    })
  });
  _exports.default = _default;
});