define("simtex-myaccount/controllers/restricted/numbers/dids/index", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    queryParams: {
      select_primary: 'account'
    },
    select_primary: null
  });
  _exports.default = _default;
});