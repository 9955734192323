define("simtex-myaccount/templates/components/numbers/dids/did-clidstamping", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="card simtex-card">
      <div class="card-header">
          Caller ID Over-stamping
      </div>
      <div class="card-block">
          <div class="card-text">
              Calls from {{@model.did}} are being over-stamped to
              <Numbers::Dids::DidLink @owner={{@model.owner}} @linked_did={{@model.clidstampphone_did}}
                  @display_did={{@model.clidstampphone_did}} />
          </div>
      </div>
      <div class="card-footer">
          <div style="display: inline">
              <div style="display: inline-block">
                  <Numbers::Dids::DidhistoryActions @modalMode='Remove Over-Stamping' @model={{@model}}/>
              </div>
          </div>
      </div>
  </div>
  */
  {
    "id": "e/aN8fQR",
    "block": "[[[10,0],[14,0,\"card simtex-card\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n        Caller ID Over-stamping\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"card-block\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"card-text\"],[12],[1,\"\\n            Calls from \"],[1,[30,1,[\"did\"]]],[1,\" are being over-stamped to\\n            \"],[8,[39,0],null,[[\"@owner\",\"@linked_did\",\"@display_did\"],[[30,1,[\"owner\"]],[30,1,[\"clidstampphone_did\"]],[30,1,[\"clidstampphone_did\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"card-footer\"],[12],[1,\"\\n        \"],[10,0],[14,5,\"display: inline\"],[12],[1,\"\\n            \"],[10,0],[14,5,\"display: inline-block\"],[12],[1,\"\\n                \"],[8,[39,1],null,[[\"@modalMode\",\"@model\"],[\"Remove Over-Stamping\",[30,1]]],null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@model\"],false,[\"numbers/dids/did-link\",\"numbers/dids/didhistory-actions\"]]",
    "moduleName": "simtex-myaccount/templates/components/numbers/dids/did-clidstamping.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});