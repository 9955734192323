define("simtex-myaccount/helpers/validate-transition", ["exports", "ember-steps/helpers/validate-transition"], function (_exports, _validateTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _validateTransition.default;
    }
  });
  Object.defineProperty(_exports, "validateTransition", {
    enumerable: true,
    get: function get() {
      return _validateTransition.validateTransition;
    }
  });
});