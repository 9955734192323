define("simtex-myaccount/routes/restricted/numbers/dids/did", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    store: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    beforeModel: function beforeModel() {
      this.simtex.set_user_nav(true);
    },
    model: function model(params) {
      return this.store.findRecord('did', params.did);
    },
    afterModel: function afterModel(model, transition) {
      if (this.simtex.is_enduser) {
        this.toast.error(this.simtex.getSimtexString('LIST_UNAUTHORIZED_VIEW_DIDS'));
        this.transitionTo('restricted.numbers.dids', allowed_owner);
      }
    }
  });
  _exports.default = _default;
});