define("simtex-myaccount/components/subscriptions/routing-data", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "@ember/object", "@ember/service", "ember-data"], function (_exports, _component, _modelSaverCommon, _object, _service, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Routing',
    store: (0, _service.inject)(),
    didroute_choices_arr: (0, _object.computed)(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.store.findAll('didroute').then(function (result) {
          var choices_arr = [];
          // .filter(r => r.label !== null)
          // put nulls last
          result = result.toArray().sort(function (a, b) {
            if (a.label === b.label) return 0;else if (a.label === null) return 1;else if (b.label === null) return -1;else return a.label.localeCompare(b.label);
          });
          result.forEach(function (route) {
            choices_arr.push({
              label: route.label || "** ".concat(route.maptype),
              value: route.didrouteid
            });
          });
          return choices_arr;
        })
      });
    })
  });
  _exports.default = _default;
});