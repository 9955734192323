define("simtex-myaccount/templates/components/callrouting/callforwarding-table", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#light-table this.table as |t|}}
      {{t.head
          onColumnClick=(action 'onColumnClick')
          iconComponent='light-table-icon'
          iconSortable='sort'
          iconAscending='sort-up'
          iconDescending='sort-down'
      }}
  
      {{#t.body
          class='table'
          canSelect=false
      as |body|
      }}
          {{#if this.isLoading}}
              {{#body.loader}}
                  <div style="margin-top:1rem;" class="col-md-6">
                      <div class="progress loop"><progress></progress></div>
                  </div>
              {{/body.loader}}
          {{/if}}
  
          {{light-table-empty-results
              isIdle=this.isIdle
              hasResults=this.hasResults
              canLoadMore=this.canLoadMore}}
      {{/t.body}}
  
      {{light-table-pagination
          meta=this.meta
          page=this.page
          pagination_is_field=this.pagination_is_field
          pageActions=(hash
              setPage=(action 'setPage')
              refresh=(action 'refresh')
          )
      }}
  {{/light-table}}
  */
  {
    "id": "YdryiKoL",
    "block": "[[[6,[39,0],[[30,0,[\"table\"]]],null,[[\"default\"],[[[[1,\"    \"],[1,[28,[30,1,[\"head\"]],null,[[\"onColumnClick\",\"iconComponent\",\"iconSortable\",\"iconAscending\",\"iconDescending\"],[[28,[37,1],[[30,0],\"onColumnClick\"],null],\"light-table-icon\",\"sort\",\"sort-up\",\"sort-down\"]]]],[1,\"\\n\\n\"],[6,[30,1,[\"body\"]],null,[[\"class\",\"canSelect\"],[\"table\",false]],[[\"default\"],[[[[41,[30,0,[\"isLoading\"]],[[[6,[30,2,[\"loader\"]],null,null,[[\"default\"],[[[[1,\"                \"],[10,0],[14,5,\"margin-top:1rem;\"],[14,0,\"col-md-6\"],[12],[1,\"\\n                    \"],[10,0],[14,0,\"progress loop\"],[12],[10,\"progress\"],[12],[13],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"isIdle\",\"hasResults\",\"canLoadMore\"],[[30,0,[\"isIdle\"]],[30,0,[\"hasResults\"]],[30,0,[\"canLoadMore\"]]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"meta\",\"page\",\"pagination_is_field\",\"pageActions\"],[[30,0,[\"meta\"]],[30,0,[\"page\"]],[30,0,[\"pagination_is_field\"]],[28,[37,5],null,[[\"setPage\",\"refresh\"],[[28,[37,1],[[30,0],\"setPage\"],null],[28,[37,1],[[30,0],\"refresh\"],null]]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"t\",\"body\"],false,[\"light-table\",\"action\",\"if\",\"light-table-empty-results\",\"light-table-pagination\",\"hash\"]]",
    "moduleName": "simtex-myaccount/templates/components/callrouting/callforwarding-table.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});