define("simtex-myaccount/templates/components/subscriptions/musiconhold-data", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="card">
      <div class="card-header">
          Music On Hold
      </div>
      <div class="card-block">
          <div class="card-text">
              <form class="clr-form clr-form-horizontal" onsubmit="return false">
                  <div class="clr-form-control">
                      <label class="clr-control-label" for='musiconhold_list'>
                          Audio Selection
                      </label>
                      {{#if (or this.simtex.is_admin this.simtex.is_superuser)}}
                      <div class="clr-input-wrapper">
                          {{element-select
                              content=this.musiconhold_list
                              selection=this.args.model.musiconholdslotuuid
                              class="clr-select-wrapper"
                              optionLabelPath="display_name"
                              optionValuePath="value"
                              id="musiconhold_list"}}
                      </div>
                      {{else}}
                      <div>
                          {{get-coalesce this.args.model.musiconholdslotuuid 'Default'}}
                      </div>
                      {{/if}}
                  </div>
              </form>
          </div>
      </div>
  
      {{card-data-footer
          model=this.args.model
          object_name=this.object_name
      }}
  </div>
  */
  {
    "id": "3ErNNeXa",
    "block": "[[[10,0],[14,0,\"card\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n        Music On Hold\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"card-block\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"card-text\"],[12],[1,\"\\n            \"],[10,\"form\"],[14,0,\"clr-form clr-form-horizontal\"],[14,\"onsubmit\",\"return false\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"clr-form-control\"],[12],[1,\"\\n                    \"],[10,\"label\"],[14,0,\"clr-control-label\"],[14,\"for\",\"musiconhold_list\"],[12],[1,\"\\n                        Audio Selection\\n                    \"],[13],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"simtex\",\"is_admin\"]],[30,0,[\"simtex\",\"is_superuser\"]]],null],[[[1,\"                    \"],[10,0],[14,0,\"clr-input-wrapper\"],[12],[1,\"\\n                        \"],[1,[28,[35,2],null,[[\"content\",\"selection\",\"class\",\"optionLabelPath\",\"optionValuePath\",\"id\"],[[30,0,[\"musiconhold_list\"]],[30,0,[\"args\",\"model\",\"musiconholdslotuuid\"]],\"clr-select-wrapper\",\"display_name\",\"value\",\"musiconhold_list\"]]]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]],[[[1,\"                    \"],[10,0],[12],[1,\"\\n                        \"],[1,[28,[35,3],[[30,0,[\"args\",\"model\",\"musiconholdslotuuid\"]],\"Default\"],null]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]]],[1,\"                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,4],null,[[\"model\",\"object_name\"],[[30,0,[\"args\",\"model\"]],[30,0,[\"object_name\"]]]]]],[1,\"\\n\"],[13]],[],false,[\"if\",\"or\",\"element-select\",\"get-coalesce\",\"card-data-footer\"]]",
    "moduleName": "simtex-myaccount/templates/components/subscriptions/musiconhold-data.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});