define("simtex-myaccount/components/subscriptions/subscription/callforwarding-data", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common"], function (_exports, _component, _modelSaverCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    object_name: 'Transport Configuration',
    range_slider_formatter: {
      to: function to(value) {
        return Math.round(value);
      },
      from: function from(value) {
        return Number(value);
      }
    },
    actions: {
      setRingTime: function setRingTime(newValue) {
        if (newValue !== undefined) {
          this.set('accountModel.transport_data.ringtime', newValue);
        }
      }
    }
  });
  _exports.default = _default;
});