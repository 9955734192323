define("simtex-myaccount/templates/components/callhistory/callhistory-expanded-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="clr-row">
      <table class="table table-compact" style="background: aliceblue;">
          <thead>
              <tr>
                <th>Call Start</th>
                <th>Call Answered</th>
                <th>Dial Status</th>
                <th>Source</th>
                <th>Destination</th>
                <th>Duration</th>
                <th>Bill Duration</th>
              </tr>
          </thead>
          <tbody>
              {{#each this.row.cdrdata_history as |call|}}
                  <tr>
                      <td>{{format-date call.start 'D/M/YY HH:mm:ss'}}</td>
                      <td>{{format-date call.answer 'D/M/YY HH:mm:ss'}}</td>
                      <td>{{call.dialstatus}}</td>
                      <td>{{call.src}}</td>
                      <td>{{call.dst}}</td>
                      <td>{{call.duration}}</td>
                      <td>{{call.billsec}}</td>
                  </tr>
              {{/each}}
          </tbody>
      </table>
  </div>
  */
  {
    "id": "alQ21FOu",
    "block": "[[[10,0],[14,0,\"clr-row\"],[12],[1,\"\\n    \"],[10,\"table\"],[14,0,\"table table-compact\"],[14,5,\"background: aliceblue;\"],[12],[1,\"\\n        \"],[10,\"thead\"],[12],[1,\"\\n            \"],[10,\"tr\"],[12],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Call Start\"],[13],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Call Answered\"],[13],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Dial Status\"],[13],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Source\"],[13],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Destination\"],[13],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Duration\"],[13],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Bill Duration\"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"row\",\"cdrdata_history\"]]],null]],null],null,[[[1,\"                \"],[10,\"tr\"],[12],[1,\"\\n                    \"],[10,\"td\"],[12],[1,[28,[35,2],[[30,1,[\"start\"]],\"D/M/YY HH:mm:ss\"],null]],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[1,[28,[35,2],[[30,1,[\"answer\"]],\"D/M/YY HH:mm:ss\"],null]],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[1,[30,1,[\"dialstatus\"]]],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[1,[30,1,[\"src\"]]],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[1,[30,1,[\"dst\"]]],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[1,[30,1,[\"duration\"]]],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[1,[30,1,[\"billsec\"]]],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"call\"],false,[\"each\",\"-track-array\",\"format-date\"]]",
    "moduleName": "simtex-myaccount/templates/components/callhistory/callhistory-expanded-row.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});