define("simtex-myaccount/routes/restricted/subscriptions/subscription", ["exports", "@ember/routing/route", "@ember/service", "ember-data", "simtex-myaccount/config/environment"], function (_exports, _route, _service, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    router: (0, _service.inject)(),
    simtex: (0, _service.inject)(),
    // Inject the simtex service
    beforeModel: function beforeModel() {
      this.simtex.set_user_nav(true);
    },
    setupController: function setupController(controller, model) {
      var _this = this;
      this._super(controller, model);
      if (this.simtex.is_admin || this.simtex.is_reseller) {
        this.simtex.set('viewing_owner', model.owner || 'USN empty');
        if (model.owner) {
          this.simtex.set('viewing_account', _emberData.default.PromiseObject.create({
            promise: this.store.findRecord('owner', model.owner).then(function (result) {
              return result.smile_data;
            }).then(function (smile_data) {
              return _this.simtex.getSmileProperty(smile_data.Properties.Object.String, 'name');
            })
          }));
        } else {
          this.simtex.set('viewing_account', {
            'content': 'No Smile Acct found'
          });
        }
      }
      var claimsAccount = this.simtex.get_claims_account;
      var accountId = this.get('controller.model.accountid');
      var owner = this.get('controller.model.owner');
      var baseDomain = '.simtex.com.au'; // Ensures the cookie is accessible to both subdomains

      // Encode values before setting cookies
      document.cookie = "claims_account=".concat(encodeURIComponent(claimsAccount), "; path=/; domain=").concat(baseDomain, "; SameSite=None; Secure;");
      document.cookie = "account_id=".concat(encodeURIComponent(accountId), "; path=/; domain=").concat(baseDomain, "; SameSite=None; Secure;");
    },
    model: function model(params) {
      var _this2 = this;
      var account_id = params.account_id;
      if (account_id === 'nosub') {
        return this.store.findRecord('owner', params.owner, {
          reload: true
        });
      } else {
        // Deal with nasty ember-data bug, solution from here:
        // https://stackoverflow.com/questions/63362616/ember-data-creates-duplicate-record-on-model-save-ember-cli-v3-19
        // Caused by handset (endpoint) / subscription (account) mapping additions.
        this.store.peekAll('endpointaccount').filterBy('isNew', true).forEach(function (item) {
          return _this2.store.deleteRecord(item);
        });
        return this.store.findRecord('account', account_id).then(function (acct) {
          return acct;
        });
      }
    },
    actions: {
      save_nested: function save_nested(nested_obj) {
        return nested_obj.then(function (result) {
          return result.save();
        });
      },
      handleTODClick: function handleTODClick() {
        var accountId = this.get('controller.model.accountid');
        var owner = this.get('controller.model.owner');
        window.location.href = this.simtex.is_admin ? "".concat(_environment.default.APP.NEW_PORTAL_HOST, "/subscriptions/").concat(owner, "/").concat(accountId, "/ivr") : "".concat(_environment.default.APP.NEW_PORTAL_HOST, "/subscriptions/").concat(owner, "/").concat(accountId, "/ivr");
      },
      handleTODClick2: function handleTODClick2() {
        var accountId = this.get('controller.model.accountid');
        var owner = this.get('controller.model.owner');
        window.location.href = this.simtex.is_admin ? "".concat(_environment.default.APP.NEW_PORTAL_HOST, "/subscriptions/").concat(owner, "/").concat(accountId, "/domain-verification") : "".concat(_environment.default.APP.NEW_PORTAL_HOST, "/subscriptions/").concat(owner, "/").concat(accountId, "/domain-verification");
      }
    }
  });
  _exports.default = _default;
});