define("simtex-myaccount/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  {{this.head-layout}}
  {{page-title 'Simtex' separator=": " front=true}}
  {{#unless this.simtex.heartbeat}}
      {{check-heartbeat}}
  {{/unless}}
  
  {{#if this.simtex.maintenance}}
      {{maintenance}}
  {{/if}}
  */
  {
    "id": "03tWWveO",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[1,[30,0,[\"head-layout\"]]],[1,\"\\n\"],[1,[28,[35,2],[\"Simtex\"],[[\"separator\",\"front\"],[\": \",true]]]],[1,\"\\n\"],[41,[51,[30,0,[\"simtex\",\"heartbeat\"]]],[[[1,\"    \"],[1,[34,4]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"simtex\",\"maintenance\"]],[[[1,\"    \"],[1,[34,6]],[1,\"\\n\"]],[]],null]],[],false,[\"component\",\"-outlet\",\"page-title\",\"unless\",\"check-heartbeat\",\"if\",\"maintenance\"]]",
    "moduleName": "simtex-myaccount/templates/application.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});