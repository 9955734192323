define("simtex-myaccount/templates/restricted/numbers", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  <nav class="sidenav">
      <section class="sidenav-content">
          <section class="nav-group">
              <label>Numbers</label>
              <ul class="nav-list">
                  <li>
                      <LinkTo @route='restricted.numbers.dids' @model={{this.simtex.get_claims_account}} class="nav-link">DID Mapping</LinkTo>
                  </li>
                  {{#if this.simtex.is_admin}}
                      <li>
                          {{numbers/dids/did-assignment-dialog modalMode='Assign' modalTitle='Assign New' modalIcon='assign-user' owner=this.simtex.get_claims_account viewMode='menu'}}
                      </li>
                  {{/if}}
                  {{#if this.simtex.get_availableaccounts_teams_direct_route}}
                          <li>
                              <LinkTo @route='restricted.numbers.didclidstamping' @model={{this.simtex.get_claims_account}} class="nav-link">Caller ID Over-Stamping</LinkTo>
                          </li>
                  {{/if}}
                  {{#if this.simtex.is_admin}}
                  <li>
                      <LinkTo @route='restricted.numbers.sites' @model={{this.simtex.get_claims_account}} class="nav-link">Sites (dev)</LinkTo>
                  </li>
                  {{/if}}
              </ul>
          </section>
      </section>
  </nav>
  */
  {
    "id": "WiE9Gt1a",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[10,\"nav\"],[14,0,\"sidenav\"],[12],[1,\"\\n    \"],[10,\"section\"],[14,0,\"sidenav-content\"],[12],[1,\"\\n        \"],[10,\"section\"],[14,0,\"nav-group\"],[12],[1,\"\\n            \"],[10,\"label\"],[12],[1,\"Numbers\"],[13],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"nav-list\"],[12],[1,\"\\n                \"],[10,\"li\"],[12],[1,\"\\n                    \"],[8,[39,2],[[24,0,\"nav-link\"]],[[\"@route\",\"@model\"],[\"restricted.numbers.dids\",[30,0,[\"simtex\",\"get_claims_account\"]]]],[[\"default\"],[[[[1,\"DID Mapping\"]],[]]]]],[1,\"\\n                \"],[13],[1,\"\\n\"],[41,[30,0,[\"simtex\",\"is_admin\"]],[[[1,\"                    \"],[10,\"li\"],[12],[1,\"\\n                        \"],[1,[28,[35,4],null,[[\"modalMode\",\"modalTitle\",\"modalIcon\",\"owner\",\"viewMode\"],[\"Assign\",\"Assign New\",\"assign-user\",[30,0,[\"simtex\",\"get_claims_account\"]],\"menu\"]]]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"simtex\",\"get_availableaccounts_teams_direct_route\"]],[[[1,\"                        \"],[10,\"li\"],[12],[1,\"\\n                            \"],[8,[39,2],[[24,0,\"nav-link\"]],[[\"@route\",\"@model\"],[\"restricted.numbers.didclidstamping\",[30,0,[\"simtex\",\"get_claims_account\"]]]],[[\"default\"],[[[[1,\"Caller ID Over-Stamping\"]],[]]]]],[1,\"\\n                        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"simtex\",\"is_admin\"]],[[[1,\"                \"],[10,\"li\"],[12],[1,\"\\n                    \"],[8,[39,2],[[24,0,\"nav-link\"]],[[\"@route\",\"@model\"],[\"restricted.numbers.sites\",[30,0,[\"simtex\",\"get_claims_account\"]]]],[[\"default\"],[[[[1,\"Sites (dev)\"]],[]]]]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"link-to\",\"if\",\"numbers/dids/did-assignment-dialog\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/numbers.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});