define("simtex-myaccount/models/dialrestriction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    account_id: _emberData.default.attr(),
    iddallow: _emberData.default.attr('boolean'),
    iddbanned: _emberData.default.attr('boolean'),
    iddclientapproved: _emberData.default.attr('boolean'),
    iddclientemailsent: _emberData.default.attr('boolean'),
    prefixblockingmode: _emberData.default.attr('boolean'),
    dialrestrictiontier: _emberData.default.attr(),
    dialrestrictiontier_label: _emberData.default.attr('string'),
    prefixblacklist: _emberData.default.attr('string'),
    prefixwhitelist: _emberData.default.attr('string')
  });
  _exports.default = _default;
});