define("simtex-myaccount/templates/restricted/callrouting/ringgroups", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{page-title 'Ring Groups (' this.simtex.owner_description ')'}}
  <div class="content-area">
      <div class="row">
          <div class="col-lg-12 clr-col-lg-10 clr-col-xl-8">
              <h2 style="margin-top: 0;">Ring Groups for {{this.simtex.owner_description}}</h2>
              {{callrouting/ringgroup-dialog
                  owner=this.simtex.owner_search_term modalMode='Add'}}
  
              {{callrouting/ringgroup-table
                      owner=this.simtex.owner_search_term}}
          </div>
      </div>
  
      <br />
  
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2 style="margin-top:0">Member Allocations for Ring Groups</h2>
              {{callrouting/ringgroup-member-buckets
                      owner=this.simtex.owner_search_term
                      select_primary=select_primary}}
          </div>
      </div>
  </div>
  */
  {
    "id": "8AdM5AyH",
    "block": "[[[1,[28,[35,0],[\"Ring Groups (\",[30,0,[\"simtex\",\"owner_description\"]],\")\"],null]],[1,\"\\n\"],[10,0],[14,0,\"content-area\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 clr-col-lg-10 clr-col-xl-8\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top: 0;\"],[12],[1,\"Ring Groups for \"],[1,[30,0,[\"simtex\",\"owner_description\"]]],[13],[1,\"\\n            \"],[1,[28,[35,1],null,[[\"owner\",\"modalMode\"],[[30,0,[\"simtex\",\"owner_search_term\"]],\"Add\"]]]],[1,\"\\n\\n            \"],[1,[28,[35,2],null,[[\"owner\"],[[30,0,[\"simtex\",\"owner_search_term\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"col-lg-12 col-md-12 col-sm-12 col-xs-12\"],[12],[1,\"\\n            \"],[10,\"h2\"],[14,5,\"margin-top:0\"],[12],[1,\"Member Allocations for Ring Groups\"],[13],[1,\"\\n            \"],[1,[28,[35,3],null,[[\"owner\",\"select_primary\"],[[30,0,[\"simtex\",\"owner_search_term\"]],[33,4]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"callrouting/ringgroup-dialog\",\"callrouting/ringgroup-table\",\"callrouting/ringgroup-member-buckets\",\"select_primary\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/callrouting/ringgroups.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});