define("simtex-myaccount/templates/restricted/system", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  <nav class="sidenav">
      <section class="sidenav-content">
          <section class="nav-group">
              <label>System</label>
              <ul class="nav-list">
                  <li>
                      <LinkTo @route='restricted.system.zerotouchjob' @model={{this.simtex.get_claims_account}} class="nav-link">ZeroTouch Job List</LinkTo>
                  </li>
  <!--                <li>-->
  <!--                    {{!#link-to 'restricted.numbers.sites' this.simtex.get_claims_account class="nav-link"}}Sites{{!/link-to}}-->
  <!--                </li>-->
  <!--                <li><a class="nav-link">Porting</a></li>-->
              </ul>
          </section>
      </section>
  </nav>
  */
  {
    "id": "VfiWH0hU",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[10,\"nav\"],[14,0,\"sidenav\"],[12],[1,\"\\n    \"],[10,\"section\"],[14,0,\"sidenav-content\"],[12],[1,\"\\n        \"],[10,\"section\"],[14,0,\"nav-group\"],[12],[1,\"\\n            \"],[10,\"label\"],[12],[1,\"System\"],[13],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"nav-list\"],[12],[1,\"\\n                \"],[10,\"li\"],[12],[1,\"\\n                    \"],[8,[39,2],[[24,0,\"nav-link\"]],[[\"@route\",\"@model\"],[\"restricted.system.zerotouchjob\",[30,0,[\"simtex\",\"get_claims_account\"]]]],[[\"default\"],[[[[1,\"ZeroTouch Job List\"]],[]]]]],[1,\"\\n                \"],[13],[1,\"\\n\"],[3,\"                <li>\"],[1,\"\\n\"],[3,\"                    {{!#link-to 'restricted.numbers.sites' this.simtex.get_claims_account class=\\\"nav-link\\\"}}Sites{{!/link-to}}\"],[1,\"\\n\"],[3,\"                </li>\"],[1,\"\\n\"],[3,\"                <li><a class=\\\"nav-link\\\">Porting</a></li>\"],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"link-to\"]]",
    "moduleName": "simtex-myaccount/templates/restricted/system.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});