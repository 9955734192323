define("simtex-myaccount/serializers/ipndsite", ["exports", "simtex-myaccount/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    primaryKey: "ipndsiteid"

    // extractMeta: function (store, type, payload) {
    //     let meta = this._super(store, type, payload);
    //     if (!isNone(meta)) {
    //         // Add totalPages to metadata.
    //         let totalPages = 1;
    //         if (!isNone(meta.next)) {
    //             // Any page that is not the last page.
    //             totalPages = Math.ceil(meta.count / payload[type.modelName].length);
    //         } else if (isNone(meta.next) && !isNone(meta.previous)) {
    //             // The last page when there is more than one page.
    //             totalPages = meta.previous + 1;
    //         }
    //         meta['totalPages'] = totalPages;
    //     }
    //
    //     meta['actions'] = payload.actions;
    //
    //     return meta;
    // }
  });
  _exports.default = _default;
});