define("simtex-myaccount/components/callrouting/callforwarding-table", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/object"], function (_exports, _component, _tableCommon, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, {
    modelName: 'callforwardreport',
    sort: 'account',
    init: function init() {
      this._super.apply(this, arguments);
      this.send('setPage', 1);
    },
    filterList: ['number__isempty', 'number__exclude'],
    number__isempty: false,
    number__exclude: 'disabled',
    columns: (0, _object.computed)(function () {
      return [{
        label: 'Actions',
        sortable: false,
        cellComponent: 'callrouting/callforwarding-table-action'
      }, {
        label: 'Subscription(s)',
        sortable: true,
        valuePath: 'account'
      }, {
        label: 'Extension',
        valuePath: 'internalextension',
        sortable: true
      }, {
        label: 'Forward Type',
        valuePath: 'divtypelabel',
        sortable: true
      }, {
        label: 'Data',
        valuePath: 'number',
        sortable: true
      }];
    })
  });
  _exports.default = _default;
});