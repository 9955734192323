define("simtex-myaccount/controllers/passwordrequest", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    queryParams: ['u', 'c'],
    u: null,
    // username
    c: null // caller
  });
  _exports.default = _default;
});